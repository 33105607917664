import { FieldProps, getIn } from 'formik';
import { TextField, TextFieldProps as MuiTextFieldProps } from '@material-ui/core';
import { string } from 'yup';

export interface TextFieldProps extends FieldProps, Omit<MuiTextFieldProps, 'name' | 'value' | 'error'> {
  name: string;
}

export const EmailField = ({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  helperText,
  ...props
}: TextFieldProps) => {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  const parsedProps = {
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name);
      },
    ...field,
    ...props,
  };

  return (
    <TextField
      label="E-postadress"
      fullWidth
      autoComplete="off"
      type="email"
      variant="filled"
      {...parsedProps}
    />
  );
};

export const emailSchema = string()
  .required('Du måste ange E-postadress')
  .test('emailAddress', 'Inte en giltig E-postadress', (value) => {
    if (value === undefined) return true;
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm.test(
      value
    );
  });
