import { PageTitle } from 'app/components/PageTitle';
import { PageComponent } from 'utils/ComponentUtils';
import { observer } from 'mobx-react';
import Helmet from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import ApplicationView from '../financing/application-view/ApplicationView';
import { FinancingStore, getFinancingStore } from '../financing/FinancingStore';
import FindFuturePayment from './FindFuturePayment';
import { FuturePaymentStore, getFuturePaymentStore } from './FuturePaymentStore';

class FuturePaymentPage extends PageComponent {
  financingStore: FinancingStore = getFinancingStore();
  futurePaymentStore: FuturePaymentStore = getFuturePaymentStore()
    .withAppStore(this.props.appStore)
    .withFinancingStore(this.financingStore);

  render() {
    return (
      <>
        <Helmet>
          <title>Uppskjuten betalning</title>
        </Helmet>
        <PageTitle>Uppskjuten betalning</PageTitle>
        <Routes>
          <Route
            path="/"
            element={
              <FindFuturePayment
                futurePaymentStore={this.futurePaymentStore}
                financingStore={this.financingStore}
              />
            }
          />
          <Route
            path="/application/:applicationId"
            element={<ApplicationView futurePayment financingStore={this.financingStore} />}
          />
        </Routes>
      </>
    );
  }
}

export default observer(FuturePaymentPage);
