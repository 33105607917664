import { Application, ApplicationUpdateResult, FuturePayment } from 'app/domain';
import { ApplicationContract } from 'app/domain/ApplicationContract';
import { FinancingClient } from '../FinancingClient';

// get application

export function GetApplication(client: FinancingClient, applicationId: string) {
  const query = {
    chainId: client.chainId,
    storeId: client.storeId,
  };

  return client.get<Application>(`/applications/${applicationId}`, query);
}

// get application contract

export function GetApplicationContract(client: FinancingClient, applicationId: string) {
  const query = {
    chainId: client.chainId,
    v: '1',
  };

  return client.get<ApplicationContract>(`/applications/${applicationId}/contract`, query);
}

// create application

export interface CreateApplicationRequest {
  chainId: string;
  phone: string;
  emailAddress: string;
  purchaseAmount?: number;
  employmentType: string;
  campaignCode: string;
  socialSecurityNumber: string;
  inquiryId: string;
}

export function CreateApplication(client: FinancingClient, req: CreateApplicationRequest) {
  const data = {
    ...req,
    storeId: client.storeId,
  };

  return client.post<Application>(`/applications`, data);
}

// initiate signature

export interface InitiateSignatureRequest {
  applicationId: string;
  chainId: string;
  sendSmsWithLink: boolean;
  sendEmailWithLink: boolean;
  manualSign?: boolean;
}

export interface InitiateSignatureResponse {
  transactionId: string;
  signingUrl: string;
}

export function InitiateSignature(client: FinancingClient, req: InitiateSignatureRequest) {
  const data = {
    storeId: client.storeId,
    ...req,
  };

  return client.request<InitiateSignatureResponse>({
    url: `/applications/${req.applicationId}/initiate-signature`,
    method: 'POST',
    data,
  });
}

// poll signature status

export interface PollSignatureRequest {
  applicationId: string;
  chainId: string;
}

export interface PollSignatureResponse {
  signingId: string;
  signingStatus: string;
}

export function PollSignature(client: FinancingClient, req: PollSignatureRequest) {
  const data = {
    chainId: req.chainId,
    storeId: client.storeId,
  };

  const applicationId = req.applicationId;

  return client.get<PollSignatureResponse>(`/applications/${applicationId}/poll-signature`, data);
}

// update pending application

export interface UpdateApplicationRequest {
  applicationId: string;
  socialSecurityNumber: string;
  manuallySigned?: boolean;
  annualIncome?: number;
  creditLimit?: number;
}

export function UpdateApplication(client: FinancingClient, req: UpdateApplicationRequest) {
  const data = {
    chainId: client.chainId,
    ...req,
  };

  return client.put<ApplicationUpdateResult>(`/applications/update`, data);
}

export interface DeleteApplicationRequest {
  applicationId: string;
  socialSecurityNumber: string;
}

export function DeleteApplication(client: FinancingClient, req: DeleteApplicationRequest) {
  const data = {
    chainId: client.chainId,
    ...req,
  };

  return client.delete<string>(`/applications/delete`, data);
}

export interface SendPurchaseSmsRequest {
  purchaseId: string;
  amount?: number;
  chainId: string;
  applicationId: string;
}

export interface ResendPurchaseSmsOptions {
  timeout: number;
  error?: string;
  clearErrorTimeout?: NodeJS.Timeout;
  isLoading: boolean;
}

export function SendPurchaseSms(client: FinancingClient, req: SendPurchaseSmsRequest) {
  const query = {
    purchaseId: req.purchaseId,
    amount: req.amount,
    chainId: req.chainId,
  };

  return client.post(`applications/${req.applicationId}/send-purchase-sms/`, query);
}

export interface GetFuturePaymentsRequest {
  chainId: string;
  ssn?: string;
}

export function GetFuturePayments(client: FinancingClient, req: GetFuturePaymentsRequest) {
  const query = {
    chainId: req.chainId,
    ssn: req.ssn,
  };
  return client.get<FuturePayment[]>(`applications/future`, query);
}

export interface PostponeApplicationRequest {
  applicationId: string;
  chainId: string;
}

export function PostponeApplication(client: FinancingClient, req: PostponeApplicationRequest) {
  const data = {
    applicationId: req.applicationId,
    paymentStatus: 'Future',
    chainId: client.chainId,
  };

  return client.put(`applications/future`, data);
}

export function CancelApplication(client: FinancingClient, req: CancelApplicationRequest) {
  const data = {
    chainId: client.chainId,
    applicationId: req.applicationId,
    paymentStatus: 'No Purchase',
  };

  return client.put(`applications/future`, data);
}

export interface CancelApplicationRequest {
  applicationId: string;
}

//update additional info

export interface UpdateAdditionalInfoRequest {
  applicationId: string;
  housingCostPerMonth?: number;
  accommodationType: string;
  numberOfChildren: number;
}

export function UpdateAdditionalInfo(client: FinancingClient, req: UpdateAdditionalInfoRequest) {
  const data = {
    ...req,
    chainId: client.chainId,
    storeId: client.storeId,
  };

  return client.put(`/applications/update-additional-info`, data);
}
