import { Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';

import { FinancingStore } from '../FinancingStore';
import { SigningMethod } from 'app/domain/SigningMethod';
import SignContractButton from './parts/SignContractButton';
import ChangeLimitButton from './parts/ChangeLimitButton';
import ShredApplicationButton from './parts/ShredApplicationButton';

interface IProps {
  financingStore: FinancingStore;
}

class ApplicationApproved extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  state: { contractDeliveryMethod: SigningMethod } = {
    contractDeliveryMethod: SigningMethod.WEB,
  };

  render() {
    const { financingStore } = this.props;

    return (
      <Grid container>
        <Grid item md={12}>
          <br></br>
          <Typography variant="body1">
            För att slutföra ansökan behöver kunden godkänna genom att signera avtalet.
          </Typography>
          <br></br>
        </Grid>
        <Grid container direction="row">
          <Grid item md={4}>
            <ShredApplicationButton financingStore={financingStore} />
          </Grid>
          <Grid item md={4}>
            <ChangeLimitButton financingStore={financingStore} />
          </Grid>
          <Grid item md={8}>
            <SignContractButton financingStore={financingStore} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default observer(ApplicationApproved);
