import {
  Button,
  Card,
  CardContent,
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { AppStore } from 'app/AppStore';
import { PageSubTitle } from 'app/components/PageTitle';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ChangePasswordSchema, changePasswordSchema } from './ChangePasswordSchema';
import { ChangePasswordStore } from './ChangePasswordStore';
import { ErrorText } from 'ui';
const styles = () => createStyles({});

interface IProps extends WithStyles<typeof styles> {
  appStore: AppStore;
}

class ChangePassword extends React.Component<IProps> {
  changePasswordStore: ChangePasswordStore;

  constructor(props: IProps) {
    super(props);
    this.changePasswordStore = new ChangePasswordStore(props.appStore);
  }

  render() {
    const { submitChangePasswordForm, error } = this.changePasswordStore;

    return (
      <>
        <PageSubTitle>Byt lösenord</PageSubTitle>
        <Grid container>
          <Grid item md={6}>
            {error && <ErrorText>{error.text}</ErrorText>}
            <Formik<ChangePasswordSchema>
              validationSchema={changePasswordSchema}
              enableReinitialize={true}
              initialValues={{
                currentPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
              }}
              onSubmit={submitChangePasswordForm}
            >
              {({ submitForm }) => (
                <Form>
                  <Grid container direction="column">
                    <Grid item>
                      <Field
                        component={TextField}
                        label="Nuvarande lösenord"
                        name="currentPassword"
                        type="password"
                        fullWidth
                        variant="filled"
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        component={TextField}
                        label="Nytt lösenord"
                        name="newPassword"
                        type="password"
                        fullWidth
                        variant="filled"
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        component={TextField}
                        label="Upprepa nytt lösenord "
                        name="newPasswordConfirm"
                        type="password"
                        fullWidth
                        variant="filled"
                      />
                    </Grid>
                    <Grid item>
                      <Button fullWidth variant="contained" color="primary" onClick={submitForm}>
                        Byt lösenord
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid item md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6">Lösenordsregler</Typography>
                <List dense>
                  <ListItem>
                    <ListItemText>Ska innehålla 8-15 tecken</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>Ska innehålla minst 1 liten och 1 stor bokstav</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>Ska innehålla minst 1 siffra</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>Specialtecken är tillåtna, exempelvis {'@ % & = +'}</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>Får inte innehålla å, ä eller ö</ListItemText>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(observer(ChangePassword));
