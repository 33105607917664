import { Store } from 'app/domain';
import { StoreUser } from 'app/domain/StoreUser';
import { UserId, UserProfile } from 'app/domain/User';
import { FinancingClient } from '../FinancingClient';

export interface ResetUserPasswordRequest {
  userId: UserId;
}
export interface ResetUserPasswordResponse {
  userId: UserId;
}

export function ResetUserPassword(client: FinancingClient, req: ResetUserPasswordRequest) {
  const request = {
    chainId: client.chainId,
  };

  return client.put<ResetUserPasswordResponse>(`/admin/users/${req.userId}/reset-password`, request);
}

export interface ChangePasswordRequest {
  newPassword: string;
  currentPassword: string;
}

export function ChangePassword(client: FinancingClient, req: ChangePasswordRequest) {
  const request = {
    ...req,
    chainId: client.chainId,
  };

  return client.post('/users/password', request);
}

export interface UpdateUserRequest extends CreateUserRequest {
  userId: UserId;
}

export function UpdateUser(client: FinancingClient, req: UpdateUserRequest) {
  const request = {
    ...req,
    chainId: client.chainId,
    storeId: client.storeId,
  };

  return client.put<StoreUser>(`/admin/users/${req.userId}`, request);
}

export interface CreateUserRequest {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  sellerId: string;
  stores: Store[];
}

export function CreateUser(client: FinancingClient, req: CreateUserRequest) {
  const request = {
    ...req,
    chainId: client.chainId,
    storeId: client.storeId,
  };

  return client.post<{ userId: string; password: string }>('/admin/users', request);
}

export function ValidateEmail(client: FinancingClient, req: CreateUserRequest) {
  const request = {
    chainId: client.chainId,
    storeId: client.storeId,
    email: req.email,
  };

  return client.post<boolean>('/admin/users/validate-email', request);
}

export interface DeleteUserRequest {
  userId: UserId;
}

export function DeleteUser(client: FinancingClient, req: DeleteUserRequest) {
  const query = {
    chainId: client.chainId,
  };
  return client.delete<string>(`/admin/users/${req.userId}`, query);
}

export interface GetUserRequest {
  userId: UserId;
}

export function GetUser(client: FinancingClient, req: GetUserRequest) {
  const query = {
    chainId: client.chainId,
  };

  return client.get<StoreUser>(`/admin/users/${req.userId}`, query);
}

export interface FindUsersRequest {
  storeId: string;
}

export function FindUsers(client: FinancingClient, req: FindUsersRequest) {
  const query = {
    ...req,
    chainId: client.chainId,
  };

  return client.get<UserProfile[]>('/admin/users', query);
}
