export const TrainingVideos = {
  trainingVideos: [
    {
      title: 'Varför ska jag dela upp min betalning',
      url: 'https://www.youtube.com/embed/EaTRuG6lCvs',
    },
    {
      title: 'Flexibel betalning bra för dig och dina kunder',
      url: 'https://www.youtube.com/embed/UZoZn0e9lKM',
    },
  ],
};
