import { makeStyles, Theme, useTheme } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { primaryColor } from 'app/AppTheme';
import * as React from 'react';
import { LinkProps } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
/**
 * Wraps a Link from react-router-dom, but adds
 * additional styling to it.
 */
const useStyles = (theme: Theme) =>
  makeStyles({
    root: {
      ...theme.typography.h6,
      '&:hover': { color: primaryColor, textDecoration: 'none' },
      color: 'inherit',
      fontWeight: 400,
      fontFamily: '"SantanderHeadline-Regular"',
      lineHeight: '30px',
      transition: 'color 125ms 0s var(--ease-out)',
      textDecoration: 'none',
    },
    activeLink: {
      ...theme.typography.h6,
      '&:hover': { color: primaryColor, textDecoration: 'none' },
      color: primaryColor + '!important',
      fontWeight: 400,
      fontFamily: '"SantanderHeadline-Regular"',
      lineHeight: '30px',
      borderBottom: '2px solid' + primaryColor,
      paddingBottom: theme.spacing() + theme.spacing() / 2,
    },
  });

interface IProps extends LinkProps {
  active: string;
}

const MenuItem: React.SFC<IProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme)();
  const { children, to, active } = props;
  return (
    <Link
      component={RouterLink}
      className={
        !active.startsWith(typeof to === 'string' ? to : to.pathname || '')
          ? classes.root
          : classes.activeLink
      }
      to={to}
    >
      {children}
    </Link>
  );
};

export default MenuItem;
