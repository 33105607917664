import { Box, Grid, TextField, Button, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import * as React from 'react';

import DataTableTextLabels from 'utils/DataTableTextLabels';
import { getFindFuturePaymentStore } from './FindFuturePaymentStore';
import { FuturePaymentStore } from './FuturePaymentStore';
import { FinancingStore } from '../financing/FinancingStore';
import { MaskedSsnInput } from 'app/components/MaskedSsnInput';
import ErrorText from 'ui/ErrorText';

const columns = ['Beställningsdatum', 'Belopp', 'Kedja', 'OCR-/Kontonummer', 'Åtgärder'];

const adminColumns = ['Beställningsdatum', 'Personnummer', 'Belopp', 'Kedja', 'OCR-/Kontonummer', 'Åtgärder'];
const options: MUIDataTableOptions = {
  responsive: 'vertical' as any, // eslint-disable-line @typescript-eslint/no-explicit-any
  filter: false,
  download: false,
  textLabels: DataTableTextLabels,
  selectableRows: 'none',
  print: false,
};

class FindFuturePayment extends React.Component<{
  futurePaymentStore: FuturePaymentStore;
  financingStore?: FinancingStore;
}> {
  findFuturePaymentStore = getFindFuturePaymentStore().withFuturePaymentStore(
    this.props.futurePaymentStore,
    this.props.financingStore
  );

  componentDidMount() {
    const { location } = this.findFuturePaymentStore.appStore.router;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!(location.state && (location.state as any).keepState)) {
      this.findFuturePaymentStore.clearFuturePayments();
    }
    const { isOnAdminPage } = this.findFuturePaymentStore.appStore;
    if (isOnAdminPage) {
      this.findFuturePaymentStore.getAllFuturePayments();
    }
  }

  render() {
    const {
      ssn,
      setSsn,
      showError,
      validateSsn,
      ssnError,
      getTableFuturePayments,
      noResult,
      allFuturePayments,
      filteredFuturePayments,
      getFilteredFuturePayments,
    } = this.findFuturePaymentStore;

    const { financingStore, appStore } = this.findFuturePaymentStore;

    const { isOnAdminPage } = appStore;

    const error = financingStore && financingStore.error;

    const tableFuturePayments = getTableFuturePayments(
      isOnAdminPage ? allFuturePayments || [] : filteredFuturePayments || []
    );

    return (
      <>
        <Grid container>
          {!isOnAdminPage && (
            <Grid item md={6}>
              <Box>
                <TextField
                  value={ssn}
                  style={{ paddingBottom: '10px' }}
                  label="Personnummer (12-siffror)"
                  placeholder="ÅÅÅÅMMDD-XXXX"
                  variant="filled"
                  fullWidth
                  onChange={(e) => setSsn(e.target.value)}
                  error={showError}
                  helperText={!showError ? '' : ssnError}
                  onBlur={validateSsn}
                  InputProps={{
                    inputComponent: MaskedSsnInput,
                  }}
                />
              </Box>
              <Box py={2}>
                <Button fullWidth variant="contained" color="primary" onClick={getFilteredFuturePayments}>
                  Hämta
                </Button>
                {error && (
                  <>
                    <ErrorText>{error.text}</ErrorText>
                    <ErrorText>{error.value}</ErrorText>
                  </>
                )}
              </Box>
              <Typography>{noResult}</Typography>
            </Grid>
          )}
          {tableFuturePayments && (
            <Grid item md={12}>
              <MUIDataTable
                title={'Uppskjutna betalningar'}
                data={tableFuturePayments}
                columns={isOnAdminPage ? adminColumns : columns}
                options={{ ...options, onRowClick: () => null }}
              />
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}

export default observer(FindFuturePayment);
