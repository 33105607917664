import { observer } from 'mobx-react';
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FinancingStore } from '../../FinancingStore';

interface IProps {
  financingStore: FinancingStore;
}

class ApplicantDetails extends React.Component<IProps> {
  render() {
    const { financingStore } = this.props;

    const { customer } = financingStore.financing;

    return (
      <Grid container>
        <Grid item sm={12} md={4}>
          <Typography>
            <strong>{customer.name}</strong>
          </Typography>
          <Typography>{customer.socialSecurityNumber}</Typography>
          <Typography>{customer.address}</Typography>
          <Typography>
            {customer.zipCode} {customer.city}
          </Typography>
          <Typography gutterBottom>{customer.phone}</Typography>
        </Grid>
      </Grid>
    );
  }
}

export default observer(ApplicantDetails);
