import { Box, Grid, MenuItem, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import MUIDataTable, { Display, MUIDataTableOptions } from 'mui-datatables';
import * as React from 'react';

import { AppStore } from 'app/AppStore';
import { PageSubTitle } from 'app/components/PageTitle';
import DataTableTextLabels from 'utils/DataTableTextLabels';
import { getFindUserStore } from './FindUserStore';

const columns = [
  {
    name: 'Säljar ID',
    options: {
      setCellProps: () => ({ style: { width: '100px' } }),
    },
  },
  'Användarnamn',
  'Name',
  'Email',
  'Butiksroll',
  'Åtgärder',
  {
    name: 'userId',
    options: { display: 'false' as Display },
  },
];

const options: MUIDataTableOptions = {
  responsive: 'vertical' as any, // eslint-disable-line @typescript-eslint/no-explicit-any
  filter: false,
  download: false,
  textLabels: DataTableTextLabels,
  selectableRows: 'none',
  print: false,
};

class FindUser extends React.Component<{ appStore: AppStore }> {
  findUserStore = getFindUserStore().withAppStore(this.props.appStore);
  onRowClick = (rowData: string[]) => {
    const userId = rowData[6];
    this.props.appStore.router.push(`/admin/edit-user/${userId}`);
  };

  render() {
    const { storeId, setStoreId } = this.findUserStore;
    return (
      <>
        <PageSubTitle>Sök användare</PageSubTitle>
        <Grid container>
          <Grid item md={6}>
            <Box py={2}>
              <TextField
                select
                fullWidth
                variant="filled"
                label="Butik"
                value={storeId}
                onChange={(e) => setStoreId(e.target.value)}
              >
                {this.findUserStore.stores.map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          {this.findUserStore.tableUsers && (
            <Grid item md={12}>
              <MUIDataTable
                title={'Användare'}
                data={this.findUserStore.tableUsers}
                columns={columns}
                options={{ ...options, onRowClick: this.onRowClick }}
              />
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}

export default observer(FindUser);
