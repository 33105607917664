import { Field, Form, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Grid, MenuItem, Box, Typography, Input, Button } from '@material-ui/core';

import { accommodationTypes } from 'app/domain/AccommodationType';
import { AdditionalInfoSchema } from './AdditionalInfoSchema';
import ValidationTextField from 'ui/ValidationTextField';

const numberOfChildren = [
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
];

type AdditionalInfoFormFieldsProps = FormikProps<AdditionalInfoSchema>;

class AdditionalInfoFormFields extends React.Component<AdditionalInfoFormFieldsProps> {
  render() {
    const { values, isSubmitting, submitForm } = this.props;

    return (
      <Form>
        <Typography>
          <strong>Vi behöver komplettera med ytterligare information innan ansökan kan färdigställas.</strong>
        </Typography>
        <Box mt={2} />
        <Grid container>
          <Grid item sm={6} xs={12}>
            <Field
              component={TextField}
              select
              label="Välj boendetyp"
              name="accommodationTypeId"
              helperText={' '} // Fixes misalignment in grid on error
              variant="filled"
              disabled={isSubmitting}
              fullWidth
            >
              {accommodationTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item sm={6} xs={12}></Grid>
          <Grid item sm={6} xs={12}>
            <Typography variant="body2" color="textSecondary">
              Ange del av boendekostnaden per månad, exempelvis hyra, el, uppvärmning, vatten och avlopp,
              hemförsäkring, sophantering, etc. Tänk på att inte räkna med kostnader för ev. bolån, som ränta
              och amortering.
            </Typography>
          </Grid>
          <Grid item sm={6} xs={12}></Grid>
          <Grid item sm={6} xs={12}>
            <Field
              style={{ paddingBottom: '10px' }}
              label="Månadskostnaden"
              name="housingCostPerMonth"
              component={ValidationTextField}
              placeholder="Del av månadskostnaden"
              onKeyPress={(event: { key: string; preventDefault: () => void }) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              fullWidth
              variant="filled"
              disabled={isSubmitting}
              type="number"
              InputProps={{
                inputComponent: Input,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}></Grid>
          <Grid item sm={6} xs={12}>
            <Field
              component={TextField}
              label="Välj antal barn som bor hemma"
              select
              helperText={' '} // Fixes misalignment in grid on error
              placeholder="Välj antal barn som bor hemma..."
              name="numberOfChildren"
              fullWidth
              variant="filled"
              disabled={isSubmitting}
            >
              {numberOfChildren.map((type) => (
                <MenuItem key={type.value} value={type.label}>
                  {type.label}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        </Grid>
        <Box mt={2} />
        <Grid container justifyContent="space-between">
          <Grid item sm={4}></Grid>
          <Grid item sm={4}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={
                isSubmitting ||
                !values.accommodationTypeId ||
                (!values.housingCostPerMonth && values.housingCostPerMonth !== 0) ||
                !values.numberOfChildren
              }
              onClick={submitForm}
            >
              Ansök
            </Button>
          </Grid>
        </Grid>
      </Form>
    );
  }
}

export default observer(AdditionalInfoFormFields);
