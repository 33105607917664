import { AppStore } from 'app/AppStore';
import { makeAutoObservable } from 'mobx';
import { RefundPurchaseRequest } from 'app/api/requests';
import { ReturnStore } from './ReturnStore';

class CompleteReturnStore {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  appStore: AppStore = undefined!;
  ocr?: string;
  purchaseId?: string;
  transactionId?: string;
  purchaseAmount?: number;
  returnableAmount?: number;
  purchaseDate?: string;
  purchaseStoreName?: string;
  purchaseStoreId?: number;
  chainId?: string;
  ssn?: string;
  returnAmount?: number;
  returnAmountIsValid = true;
  returnAmountError?: string;
  returnPerformed = false;

  constructor() {
    makeAutoObservable(this);
  }

  withReturnStore(returnStore: ReturnStore) {
    this.appStore = returnStore.appStore;
    this.ocr = returnStore.purchase?.ocr;
    this.purchaseId = returnStore.purchase?.purchaseId;
    this.transactionId = returnStore.purchase?.transactionId;
    this.purchaseAmount = returnStore.purchase?.amount;
    this.returnableAmount = returnStore.purchase?.returnableAmount;
    this.purchaseDate = returnStore.purchase?.purchaseDate;
    this.purchaseStoreName = returnStore.purchase?.storeName;
    this.purchaseStoreId = returnStore.purchase?.storeId;
    this.ssn = returnStore.ssn;
    this.chainId = returnStore.chainId;
    this.returnAmount = undefined;
    this.returnPerformed = false;

    return this;
  }

  setReturnAmount = (amount: string) => {
    const purchaseAmountValue: number = parseFloat(amount);
    this.returnAmount = purchaseAmountValue;
  };

  validateReturnAmount = () => {
    const returnAmount = this.returnAmount;

    if (returnAmount && returnAmount <= 0) {
      this.returnAmountIsValid = false;
      this.returnAmountError = 'Beloppet måste vara större än 0';
    }
    //Temporary Fix for migrated accounts from TGS... Customers should be able to return more than the purchaseAmount....
    // else if (this.purchaseAmount && returnAmount > this.purchaseAmount) {
    //     this.returnAmountIsValid = false;
    //     this.returnAmountError = 'Belopp att återbetala kan inte överstiga det totala beloppet';
    // }
    else {
      this.returnAmountIsValid = true;
    }
  };

  returnPurchase = async () => {
    if (!this.returnAmount) {
      this.returnAmountIsValid = false;
      this.returnAmountError = 'Ogiltigt belopp';
      return;
    } else if (!this.returnAmountIsValid) {
      return;
    }

    const request: RefundPurchaseRequest = {
      purchaseId: this.purchaseId ?? '',
      amount: this.returnAmount,
      chainId: this.chainId ?? '',
    };

    const response = await this.appStore.api.refundPurchase(request);

    if (response.isSuccess) {
      this.returnPerformed = true;
    } else if (response.error?.code === 3001) {
      this.returnAmountIsValid = false;
      this.returnAmountError = 'Beloppet för returen kan inte vara större än köpesumman.';
    } else if (response.error?.code === 3000) {
      this.returnAmountIsValid = false;
      this.returnAmountError = 'Ogiltigt köp-id';
    } else {
      this.appStore.showError(response);
    }
  };
}

const completeReturnStore = new CompleteReturnStore();
function getCompleteReturnStore() {
  return completeReturnStore;
}

export { CompleteReturnStore, getCompleteReturnStore };
