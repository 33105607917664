export const addHyphenToSsn = (ssn: string) => {
  if (ssn.includes('-')) {
    return ssn;
  }

  let ssnDate;
  let ssnCode;

  if (ssn.length === 12) {
    ssnDate = ssn.substr(0, 8);
    ssnCode = ssn.substr(8, 11);
  }
  if (ssn.length === 10) {
    ssnDate = ssn.substr(0, 6);
    ssnCode = ssn.substr(6, 9);
  }

  return ssnDate + '-' + ssnCode;
};
