import { SchemaType } from 'utils/TypeUtils';
import { FormikHelpers as FormikActions } from 'formik';
import { number, object, string } from 'yup';

export type AdditionalInfoSchema = SchemaType<typeof additionalInfoSchema>;

export type AdditionalInfoActions = FormikActions<AdditionalInfoSchema>;

export const additionalInfoSchema = object().shape({
  accommodationTypeId: string().required('Du måste välja en boendetyp'),
  housingCostPerMonth: number()
    .min(0, 'Månadskostnaden kan ej vara negativt')
    .max(999999, 'Månadskostnaden måste vara mindre än 1000000')
    .integer()
    .required('Du måste ange månadskostnaden'),
  numberOfChildren: string().required('Du måste välja antal barn som bor hemma'),
});
