import { Box, Button, CircularProgress, DialogContentText } from '@material-ui/core';
import { AppStore } from 'app/AppStore';
import { runInAction, makeAutoObservable } from 'mobx';
import { differenceInYears } from 'date-fns';
import { format } from 'date-fns';
import { FuturePaymentStore } from './FuturePaymentStore';
import type { FuturePayment } from 'app/domain/Purchase';
import { GetFuturePaymentsRequest, CancelApplicationRequest, CancelPurchaseRequest } from 'app/api/requests';
import { FinancingStore } from '../financing/FinancingStore';
import { addHyphenToSsn } from 'utils/ssnUtils';
import type { Alert } from 'app/domain/Alert';
import { featureFlags } from 'utils/envUtils';

class FindFuturePaymentStore {
  futurePaymentStore?: FuturePaymentStore;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  appStore: AppStore = undefined!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  financingStore: FinancingStore = undefined!;
  chainId?: string;
  filteredFuturePayments?: FuturePayment[];
  allFuturePayments?: FuturePayment[];
  selectedPurchase?: FuturePayment;

  ssn?: string;
  ssnIsValid = true;

  ssnError?: string;
  noResult?: boolean;
  showError: boolean = this.ssnIsValid || (this.ssnError?.length || 0) > 0;

  constructor() {
    makeAutoObservable(this);
  }

  get chains() {
    return this.appStore.user?.chains;
  }

  clearFuturePayments = () => {
    this.allFuturePayments = undefined;
    this.filteredFuturePayments = undefined;
    this.selectedPurchase = undefined;
    this.ssn = '';
    this.ssnError = '';
  };

  resetError = () => {
    this.financingStore.error = undefined;
  };

  get bankIdAlert(): Alert {
    return {
      title: 'Identifiering med Mobilt BankID',
      hideAccept: true,
      content: (
        <>
          <DialogContentText>
            <div>För att fortsätta behöver kunden identifiera sig med Mobilt BankID.</div>
            <div>Be kunden öpppna BankID appen för att identifiera sig.</div>
          </DialogContentText>
          <Box paddingTop="20px" justifyContent="center" display="flex">
            <CircularProgress size="40px" />
          </Box>
        </>
      ),
    };
  }

  getTableFuturePayments = (futurePayments: FuturePayment[]) => {
    if (!futurePayments) {
      return [];
    }

    const { isOnAdminPage } = this.appStore;

    return futurePayments.map((purchase) => {
      const chain = this.chains?.find((c) => c.id === purchase.chainId);
      const tableFuturePayments = [
        format(purchase.createdDate, 'YYYY-MM-DD HH:mm'),
        purchase.purchaseAmount.toLocaleString('sv-SE') + ' kr',
        chain ? chain.name : '',
        purchase.ocrNumber,
        isOnAdminPage ? (
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => {
              this.markPurchaseAsRemoved(purchase);
            }}
          >
            Ta bort
          </Button>
        ) : (
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={async () => {
              this.appStore.showAlert(this.bankIdAlert);
              this.financingStore.financing.customer.socialSecurityNumber = this.ssn ?? '';
              await this.futurePaymentStore?.completePurchase(purchase);
              this.appStore.closeAlert(this.bankIdAlert);
            }}
          >
            Genomför köp
          </Button>
        ),
      ];
      if (isOnAdminPage) {
        tableFuturePayments.splice(1, 0, addHyphenToSsn(purchase.ssn));
      }
      return tableFuturePayments;
    });
  };

  withFuturePaymentStore(futurePaymentStore: FuturePaymentStore, financingStore?: FinancingStore) {
    this.futurePaymentStore = futurePaymentStore;
    this.appStore = futurePaymentStore.appStore;
    this.appStore.assignFinancingStore(this);
    this.chainId = futurePaymentStore.appStore.user?.chains[0].id;
    if (financingStore) {
      this.financingStore = financingStore;
    }

    return this;
  }

  setSsn = (ssn: string) => {
    this.ssn = ssn;
  };

  isValidSwedishPIN = (pin: string) => {
    if (pin === undefined) {
      return false;
    }

    const pinArray = pin
      .replace(/\D/g, '') // strip out all but digits
      .split('') // convert string to array
      .reverse() // reverse order for Luhn
      .slice(0, 10); // keep only 10 digits (i.e. 1977 becomes 77)

    // verify we got 10 digits, otherwise it is invalid
    if (pinArray.length !== 10) {
      return false;
    }

    const sum = pinArray
      // convert to number
      .map((n: string) => {
        return Number(n);
      })
      // perform arithmetic and return sum
      .reduce((previous, current, index) => {
        // multiply every other number with two
        if (index % 2) {
          current *= 2;
        }
        // if larger than 10 get sum of individual digits (also n-9)
        if (current > 9) {
          current -= 9;
        }
        // sum it up
        return previous + current;
      });

    // sum must be divisible by 10
    return 0 === sum % 10;
  };

  isEighten = (ssn: string) => {
    if (ssn === undefined || ssn.length !== 12) {
      return true;
    }

    const birthDate = ssn.substring(0, 8);
    const age = differenceInYears(new Date(), birthDate);
    return age >= 18;
  };

  validateSsn = () => {
    const ssn = this.ssn?.replace(/\D/g, '') ?? '';

    if (!this.isValidSwedishPIN(ssn)) {
      this.ssnIsValid = false;
      this.ssnError = 'Personnumret du angivet är inte giltigt';
    } else if (!ssn.startsWith('19') && !ssn.startsWith('20') && !ssn.startsWith('21')) {
      this.ssnIsValid = false;
      this.ssnError = 'Personnummer måste börja med 19 eller 20';
    } else if (ssn.length !== 12) {
      this.ssnIsValid = false;
      this.ssnError = 'Personnummer måste ha 12 siffror';
    } else if (!this.isEighten(ssn)) {
      this.ssnIsValid = false;
      this.ssnError = 'Personen måste ha fyllt 18';
    } else if (ssn === '') {
      this.ssnIsValid = false;
      this.ssnError = 'Du måste ange Personnummer';
    } else {
      this.ssnIsValid = true;
    }
  };

  getAllFuturePayments = async () => {
    this.allFuturePayments = undefined;
    if (!this.chainId) {
      return;
    }

    this.ssnError = '';

    const request: GetFuturePaymentsRequest = {
      chainId: this.appStore.currentChainId ?? '',
    };

    const response = await this.appStore.api.getFuturePayments(request);

    if (response.isSuccess) {
      response.value.sort((a: FuturePayment, b: FuturePayment) => {
        return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
      });

      runInAction(() => {
        this.allFuturePayments = response.value;
      });
    } else {
      if (response.statusCode === 404) {
        runInAction(() => {
          this.ssnError = 'Inga framtida betalningar hittades';
        });
      }
    }
  };

  getFilteredFuturePayments = async () => {
    this.resetError();
    this.filteredFuturePayments = undefined;
    if (!this.ssn || !this.ssnIsValid || !this.chainId) {
      return;
    }

    this.ssnError = '';

    const request: GetFuturePaymentsRequest = {
      chainId: this.appStore.currentChainId ?? '',
      ssn: this.ssn.replace(/\D/g, ''),
    };

    const response = await this.appStore.api.getFuturePayments(request);

    if (response.isSuccess) {
      response.value.sort((a: FuturePayment, b: FuturePayment) => {
        return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
      });

      runInAction(() => {
        this.filteredFuturePayments = response.value;
      });
    } else {
      if (response.statusCode === 404) {
        runInAction(() => {
          this.ssnError = 'Inga framtida betalningar hittades';
        });
      }
    }
  };

  markPurchaseAsRemoved = async (purchase: FuturePayment) => {
    let isSuccess = false;
    if (featureFlags?.PostCancelEndpointEnabled) {
      const request: CancelPurchaseRequest = {
        purchaseId: purchase.purchaseId,
        chainId: purchase.chainId,
      };

      const response = await this.appStore.api.cancelPurchase(request);
      isSuccess = response.isSuccess;
    } else {
      const request: CancelApplicationRequest = {
        applicationId: purchase.applicationId,
      };

      const response = await this.appStore.api.cancelApplicaton(request);
      isSuccess = response.isSuccess;
    }

    if (isSuccess) {
      this.getAllFuturePayments();
    }
  };
}

const findFuturePaymentStore = new FindFuturePaymentStore();
function getFindFuturePaymentStore() {
  return findFuturePaymentStore;
}

export { FindFuturePaymentStore, getFindFuturePaymentStore };
