import { Button, Grid } from '@material-ui/core';
import { AppStore } from 'app/AppStore';
import { PageSubTitle } from 'app/components/PageTitle';
import DataTableTextLabels from 'utils/DataTableTextLabels';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import * as React from 'react';
import { getReportStore, ReportStore } from './ReportStore';

interface IProps {
  appStore: AppStore;
}

const columns = ['Datum', 'Butiksnamn', 'Säljare', 'Kampanj', 'Belopp'];
const options: MUIDataTableOptions = {
  responsive: 'vertical' as any, // eslint-disable-line @typescript-eslint/no-explicit-any
  print: false,
  textLabels: DataTableTextLabels,
  selectableRows: 'none',
};

class ReportPage extends React.Component<IProps> {
  reportStore: ReportStore = getReportStore().withAppStore(this.props.appStore);
  componentDidMount() {
    this.reportStore.getStoreStatistics();
  }
  render() {
    const { storeStatistics, exportStoreStatistics } = this.reportStore;
    const statistics = storeStatistics.map((s) => [
      format(s.applicationSigned, 'YYYY-MM-DD HH:mm'),
      s.storeName,
      s.salesName,
      s.campaign,
      s.amount,
    ]);
    return (
      <Grid container>
        <Grid item md>
          <PageSubTitle>Rapport</PageSubTitle>
          <Grid container>
            <Grid item md={12}>
              <MUIDataTable title={'Ansökningar'} data={statistics} columns={columns} options={options} />
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={exportStoreStatistics}>
                Exportera excel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default observer(ReportPage);
