import { Typography } from '@material-ui/core';
import { PageTitle } from 'app/components/PageTitle';
import { PageComponent } from 'utils/ComponentUtils';
import { observer } from 'mobx-react';
import Helmet from 'react-helmet';
import s from './DisclaimerPage.module.scss';

class DisclaimerPage extends PageComponent {
  render() {
    return (
      <div className={s.wrapper}>
        <Helmet>
          <title>Online Service - Disclaimer</title>
        </Helmet>
        <PageTitle>Disclaimer</PageTitle>

        <Typography variant="h6">Grundkrav för att ansöka</Typography>

        <p>
          The services provided are only intended for the benefit of contractual partners to Santander
          Consumer Bank in Sweden. Requests or applications from other parties will not be processed or
          answered. Disputes should be settled by Swedish court. Swedish law should be applied on all
          contracts closed on-line via this site
        </p>
        <p>
          Denna tjänst får endast användas av behörig personal i butiker anslutna till Santander Consumer
          Banks kontrakterade partners. All obehörig användning av tjänsten är förbjuden.
        </p>
      </div>
    );
  }
}

export default observer(DisclaimerPage);
