export const EmploymentType = {
  PermanentJob: {
    id: 'Permanent',
    name: 'Tillsvidare-/fastanställd',
  },
  TemporaryEmployed: {
    id: 'Temporary',
    name: 'Tidsbegränsad anställning/vikariat',
    hasEndDate: true,
  },
  SelfEmployed: {
    id: 'Self Employed',
    name: 'Egenföretagare',
  },
  Pensioners: {
    id: 'Pensioners',
    name: 'Pensionär',
  },
  Student: {
    id: 'Student',
    name: 'Student',
  },
  Unemployed: {
    id: 'Unemployed',
    name: 'Arbetssökande',
  },
};

export interface EmploymentType {
  id: string;
  name: string;
  hasEndDate?: boolean;
}

export const employmentTypes: EmploymentType[] = [
  EmploymentType.PermanentJob,
  EmploymentType.TemporaryEmployed,
  EmploymentType.SelfEmployed,
  EmploymentType.Pensioners,
  EmploymentType.Unemployed,
  EmploymentType.Student,
];

export const employmentTypeById = (id: string) => employmentTypes.find((type) => type.id === id);
