import { observer } from 'mobx-react';
import React from 'react';
import { Typography } from '@material-ui/core';
import { DecisionCode } from 'app/domain';
import ReasonCode from 'app/domain/ReasonCode';
import { FinancingStore } from '../../FinancingStore';
import SalesScoringType from 'app/domain/SalesScoringType';
import { CURRENT_TENANT, TenantsEnum } from 'app/Tenant';

interface IProps {
  financingStore: FinancingStore;
  isSigned: boolean;
}

class DecisionText extends React.Component<IProps> {
  render() {
    const { financingStore, isSigned } = this.props;
    const { application } = financingStore.financing;

    const isBej =
      application.decisionCode === DecisionCode.Approved &&
      application.reasonCode === ReasonCode.ContactBank &&
      financingStore.signApplicationFailed;

    const isApproved = application.decisionCode === DecisionCode.Approved && !isBej;
    const isDenied = application.decisionCode === DecisionCode.Denied;
    const isPending = application.decisionCode === DecisionCode.Pending;
    const hasReasonCode = Boolean(application.reasonCode);
    const isProcessing = application.reasonCode === ReasonCode.Processing;
    const isContactBank = application.reasonCode === ReasonCode.ContactBank;

    const isAuthorization = application.salesScoringType === SalesScoringType.Authorization;
    const isLimitIncrease = application.salesScoringType === SalesScoringType.LimitIncrease;
    const isNewSalesScoringType = application.salesScoringType === SalesScoringType.New;

    const canIncreaseLimit = (application.maxCreditLimit || 0) > 0;

    return (
      <Typography variant="h6">
        {'Beslut: '}
        {isBej && <span style={{ color: 'orange' }}>Väntande</span>}

        {isApproved && !isSigned && isAuthorization && (
          <span style={{ color: 'green' }}>
            Kunden har ett konto med tillgänglig kredit som köpet ryms inom.
          </span>
        )}

        {isApproved && !isSigned && (isLimitIncrease || isNewSalesScoringType) && (
          <span style={{ color: 'green' }}>
            Kundens ansökan är beviljad.
            {canIncreaseLimit &&
              ` Kunden kan höja sin kredit till maximalt ${application.possibleCreditLimit} kr.`}
          </span>
        )}

        {isApproved && isSigned && <span style={{ color: 'green' }}>Godkänd och signerad</span>}
        {isDenied && <span style={{ color: 'red' }}>Nekades</span>}
        {isPending && (
          <>
            <span style={{ color: 'orange' }}>Väntande</span>
            {isContactBank && <Typography>Ansökan behöver kompletteras.</Typography>}
            {isProcessing && (
              <Typography>
                Ansökan är inte färdigbearbetad, vänta någon minut och återuppta därefter ansökan.
              </Typography>
            )}
            {!hasReasonCode && (
              <Typography>
                Kreditbedömningen kunde inte slutföras.
                <br />
                Vänligen kontakta Santander Consumer Bank Foretagsservice på telefon{' '}
                {CURRENT_TENANT === TenantsEnum.OS ? '08-559 513 23' : '08-525 03 200'}.
              </Typography>
            )}
          </>
        )}
      </Typography>
    );
  }
}

export default observer(DecisionText);
