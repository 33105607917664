import { observer } from 'mobx-react';
import React from 'react';
import List from 'app/components/List';
import { DecisionCode, GetBillingFeeText, PaymentMethod } from 'app/domain';
import ReasonCode from 'app/domain/ReasonCode';
import { FinancingStore } from '../../FinancingStore';
import SalesScoringType from 'app/domain/SalesScoringType';

interface IProps {
  financingStore: FinancingStore;
  isSigned: boolean;
}

class ApplicationDetails extends React.Component<IProps> {
  render() {
    const { financingStore, isSigned } = this.props;

    const { campaign, application } = financingStore.financing;

    const { paymentMethod } = financingStore.appStore.user;

    const isBej =
      application.decisionCode === DecisionCode.Approved &&
      application.reasonCode === ReasonCode.ContactBank &&
      financingStore.signApplicationFailed;

    const isApproved = application.decisionCode === DecisionCode.Approved && !isBej;
    const isPending = application.decisionCode === DecisionCode.Pending;
    const isNets = paymentMethod === PaymentMethod.Nets;
    const labelWidthValue = 250;

    const isLimitIncrease = application.salesScoringType === SalesScoringType.LimitIncrease;
    const isNewSalesScoringType = application.salesScoringType === SalesScoringType.New;

    const data = [
      {
        label: 'Vald kampanj',
        value: campaign && `${campaign.name}`,
        labelWidth: labelWidthValue,
      },
      {
        label: 'Sökt belopp',
        value: application.purchaseAmount + ' kr',
        labelWidth: labelWidthValue,
      },
      {
        label: 'Månadsavgift',
        value: campaign && campaign.cost && campaign.cost.monthlyCost + ' kr',
        labelWidth: labelWidthValue,
      },
      {
        label: 'Administrationsavgift',
        value: GetBillingFeeText(campaign) || 'saknas',
        labelWidth: labelWidthValue,
      },
    ];

    if (isNewSalesScoringType && isApproved)
      data.push({
        label: 'Ansökt kredit',
        value: application.availableLimit + ' kr',
        labelWidth: labelWidthValue,
      });

    if (isLimitIncrease && isApproved)
      data.push({
        label: 'Kredithöjning',
        value: application.approvedAmount === undefined ? '' : application.approvedAmount + ' kr',
        labelWidth: labelWidthValue,
      });

    if (isApproved && isSigned && isNets)
      data.push({
        label: 'Köp-id',
        value: application.purchaseId,
        labelWidth: labelWidthValue,
      });

    if (isApproved && isSigned && !isNets)
      data.push({
        label: 'OCR-nummer',
        value: application.ocr,
        labelWidth: labelWidthValue,
      });

    return <List title="Ansökan" entries={data.filter((e) => e.label !== 'Kreditgräns' || !isPending)} />;
  }
}

export default observer(ApplicationDetails);
