import { Button } from '@material-ui/core';
import { AppStore } from 'app/AppStore';
import { UserProfile } from 'app/domain';
import { makeAutoObservable } from 'mobx';

class FindUserStore {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  appStore: AppStore = undefined!;

  storeId?: string;

  users?: UserProfile[] = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get stores() {
    return this.appStore.user.stores;
  }

  get tableUsers() {
    if (!this.users) {
      return [];
    }

    const getStoreRole = (user: UserProfile) => {
      const userStore = user.stores.find((s) => s.id === this.storeId);

      if (!userStore) {
        return 'Värde saknas';
      } else if (userStore.isAdmin) {
        return 'Administratör';
      } else {
        return 'Användare';
      }
    };

    return this.users.map((user) => [
      user.sellerId,
      user.username,
      user.firstName + ' ' + user.lastName,
      user.email,
      getStoreRole(user),
      <Button key="1" size="small" color="primary" variant="contained">
        Ändra
      </Button>,
      user.userId,
    ]);
  }

  withAppStore(appStore: AppStore) {
    this.appStore = appStore;
    this.setStoreId(appStore.user.stores[0].id);
    return this;
  }

  setStoreId = async (storeId: string) => {
    this.storeId = storeId + '';

    const response = await this.appStore.api.findUsers({ storeId: this.storeId });

    if (!response.isSuccess) {
      this.appStore.showError(response);
      return;
    }

    this.users = response.value;
  };
}

const findUserStore = new FindUserStore();
function getFindUserStore() {
  return findUserStore;
}

export { FindUserStore, getFindUserStore };
