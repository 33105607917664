import { observer } from 'mobx-react';
import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { DecisionCode } from 'app/domain';
import ReasonCode from 'app/domain/ReasonCode';
import { ErrorText } from 'ui';
import ApplicantDetails from './application-view/parts/ApplicantDetails';
import { FinancingStore } from './FinancingStore';
import FinancingConditions from './FinancingConditions';
import { additionalInfoSchema, AdditionalInfoSchema } from './AdditionalInfoSchema';
import { Formik } from 'formik';
import { RouteComponentProps, withRouter } from 'app/components/withRouter';
import AdditionalInfoFormFields from './AdditionalInfoFormFields';

interface IProps extends RouteComponentProps {
  financingStore: FinancingStore;
}

class AdditionalInfoView extends React.Component<IProps> {
  componentDidMount() {
    const { financingStore } = this.props;
    const applicationId = this.props.params.applicationId;

    if (!financingStore.financing.application.applicationId) {
      financingStore.loadApplication(applicationId);
    }
  }

  render() {
    const { financingStore } = this.props;
    const { application } = financingStore.financing;

    if (!application.applicationId) {
      return <></>;
    }

    const isHighRiskCustomer =
      application.decisionCode === DecisionCode.Pending &&
      application.reasonCode === ReasonCode.AdditionalInfo;

    if (!isHighRiskCustomer) {
      return <></>;
    }

    return (
      <Grid container>
        <Grid item md={4} sm={12} xs>
          <FinancingConditions />
        </Grid>
        <Grid item md={8} sm={12} xs>
          {financingStore.error && (
            <Grid container justifyContent="center" style={{ padding: 15 }}>
              <ErrorText>{financingStore.error.text}</ErrorText>
              <ErrorText>{financingStore.error.value}</ErrorText>
            </Grid>
          )}
          <ApplicantDetails financingStore={financingStore} />

          <Box mt={4} />
          <Formik<AdditionalInfoSchema>
            validationSchema={additionalInfoSchema}
            initialValues={
              {
                accommodationTypeId: '',
                housingCostPerMonth: '' as unknown as number,
                numberOfChildren: '',
              } as AdditionalInfoSchema
            }
            onSubmit={financingStore.submitAdditionalInfoForm}
          >
            {(formikProps) => <AdditionalInfoFormFields {...formikProps} />}
          </Formik>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(observer(AdditionalInfoView));
