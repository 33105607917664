import { PageContext } from './PageContext';

type TitleType = { children: string };

const PageTitle = (props: TitleType) => {
  return (
    <PageContext.Consumer>
      {(actions) => {
        actions.setTitle(props.children);
        return <></>;
      }}
    </PageContext.Consumer>
  );
};

const PageSubTitle = (props: TitleType) => {
  return (
    <PageContext.Consumer>
      {(actions) => {
        actions.setSubtitle(props.children);
        return <></>;
      }}
    </PageContext.Consumer>
  );
};

export { PageTitle, PageSubTitle };
