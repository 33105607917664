import { Button, FormControlLabel, Grid, Tooltip, Typography } from '@material-ui/core';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Switch, TextField } from 'formik-material-ui';
import { observer } from 'mobx-react';
import * as React from 'react';

import { AppStore } from 'app/AppStore';
import { PageSubTitle } from 'app/components/PageTitle';
import { Store } from 'app/domain';
import DataTableTextLabels from 'utils/DataTableTextLabels';
import MUIDataTable, { Display, MUIDataTableOptions } from 'mui-datatables';
import { EditUserSchema, editUserSchema } from './EditUserSchema';
import { getEditUserStore } from './EditUserStore';
import { UserId } from 'app/domain/User';
import { RouteComponentProps, withRouter } from 'app/components/withRouter';
import { EmailField } from 'app/components/phone-field/EmailField';

interface IProps extends RouteComponentProps {
  appStore: AppStore;
}

const columns = ['Butik', 'Åtgärder'];
const options: MUIDataTableOptions = {
  responsive: 'vertical' as any, // eslint-disable-line @typescript-eslint/no-explicit-any
  print: false,
  textLabels: {
    ...DataTableTextLabels,
    body: {
      noMatch: 'Alla butiker valda',
      toolTip: 'Sortera',
    },
  },
  selectableRows: 'none',
  viewColumns: false,
  download: false,
  search: false,
  filter: false,
  pagination: false,
};

class EditUser extends React.Component<IProps> {
  editUserStore = getEditUserStore().withAppStore(this.props.appStore);

  initializeUser() {
    const { getUser, resetState } = this.editUserStore;
    const existingUser = this.props.params.id as UserId;

    resetState();
    if (existingUser) getUser(existingUser);
  }

  componentDidMount(): void {
    this.initializeUser();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const existingUser = this.props.params.id as UserId;
    if (prevProps.params.id === existingUser) return;

    this.initializeUser();
  }

  render() {
    const state = this.editUserStore;

    if (!state) {
      return <div />;
    }

    const {
      currentUserStoresWhereAdmin,
      editUser,
      resetUserPassword,
      deleteUser,
      appStore: { user },
      error,
    } = this.editUserStore;

    const existingUser = this.props.params.id as UserId;
    const isCreating = !existingUser;

    if (!isCreating && !editUser) {
      return <div />;
    }

    const onlyOneStore = currentUserStoresWhereAdmin.length === 1;
    const isCurrentUser = !isCreating && editUser?.userId === user.userId;

    return (
      <Grid container>
        <Grid item xs={12}>
          <PageSubTitle>{isCreating ? 'Ny användare' : 'Ändra användare'}</PageSubTitle>
          <Formik<EditUserSchema>
            validationSchema={editUserSchema}
            enableReinitialize
            initialValues={{
              username: editUser?.username ?? '',
              firstName: editUser?.firstName ?? '',
              lastName: editUser?.lastName ?? '',
              sellerId: editUser?.sellerId ?? '',
              email: editUser?.email ?? '',
              userId: existingUser,
              stores: existingUser
                ? [...(editUser?.stores.map((s) => ({ ...s })) ?? [])]
                : [...currentUserStoresWhereAdmin],
            }}
            onSubmit={this.editUserStore.submitEditUser}
          >
            {({ submitForm, values, isSubmitting, setFieldValue }) => (
              <Form>
                <Grid container>
                  <Grid item md={4}>
                    <Grid container direction="column">
                      <Grid item>
                        <Field
                          component={TextField}
                          label="Användarnamn"
                          name="username"
                          fullWidth
                          variant="filled"
                          type="username"
                          disabled={!isCreating}
                          InputProps={{
                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                              const value = e.target.value;
                              setFieldValue('username', value.trim());
                            },
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          component={TextField}
                          label="SäljarID (frivillig uppgift)"
                          name="sellerId"
                          fullWidth
                          variant="filled"
                        />
                      </Grid>
                      <Grid item>
                        <Field component={EmailField} name="email" />
                      </Grid>
                      <Grid item>
                        <Field
                          component={TextField}
                          label="Förnamn"
                          name="firstName"
                          fullWidth
                          variant="filled"
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          component={TextField}
                          label="Efternamn"
                          name="lastName"
                          fullWidth
                          variant="filled"
                        />
                      </Grid>

                      <Grid item>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={submitForm}
                          disabled={isSubmitting}
                        >
                          {isCreating ? 'Skapa ny användare' : 'Uppdatera användare'}
                        </Button>
                        {error && <Typography style={{ marginTop: '20px' }}>{error}</Typography>}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={8}>
                    <Grid container>
                      {editUser && (
                        <Grid item md={12}>
                          <Typography gutterBottom variant="h6">
                            Åtgärder
                          </Typography>

                          <Button variant="outlined" color="primary" onClick={resetUserPassword}>
                            Återställ lösenord
                          </Button>
                          {isCurrentUser ? (
                            <Tooltip title="Du kan inte ta bort dig själv">
                              <span>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={deleteUser}
                                  disabled={isCurrentUser}
                                >
                                  Ta bort användare
                                </Button>
                              </span>
                            </Tooltip>
                          ) : (
                            <Button variant="outlined" color="primary" onClick={deleteUser}>
                              Ta bort användare
                            </Button>
                          )}
                        </Grid>
                      )}
                      <Grid item md={12}>
                        <FieldArray name="stores">
                          {(arrayHelpers) => (
                            <>
                              <Grid item md={12}>
                                <Typography variant="h6">Valda Butiker</Typography>
                                <Typography variant="caption">
                                  Användaren får automatiskt rollen säljare.
                                </Typography>
                                <MUIDataTable
                                  title={''}
                                  data={values.stores.map((store, index) => [
                                    (store as Store).name,
                                    <FormControlLabel
                                      key="2"
                                      control={
                                        <Field
                                          type="checkbox"
                                          label="Administratör"
                                          name={`stores[${index}].isAdmin`}
                                          component={Switch}
                                          color="primary"
                                        />
                                      }
                                      label="Administratör"
                                    />,
                                    <Button
                                      key="3"
                                      variant="contained"
                                      size="small"
                                      fullWidth
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      Ta bort
                                    </Button>,
                                  ])}
                                  columns={[
                                    'Butik',
                                    'Administratör',
                                    {
                                      name: 'Åtgärder',
                                      options: { display: !onlyOneStore as unknown as Display },
                                    },
                                  ]}
                                  options={{ ...options }}
                                />
                              </Grid>

                              {!onlyOneStore && (
                                <Grid item md={12}>
                                  <Typography variant="h6">Tillgängliga Butiker</Typography>
                                  <MUIDataTable
                                    title={''}
                                    data={currentUserStoresWhereAdmin
                                      .filter(
                                        (store) =>
                                          !values.stores.some(
                                            (formStore) => (formStore as Store).id == store.id
                                          )
                                      )
                                      .map((store) => [
                                        store.name,
                                        <Button
                                          key="1"
                                          variant="contained"
                                          size="small"
                                          fullWidth
                                          onClick={() => arrayHelpers.push(store)}
                                          color="primary"
                                        >
                                          Lägg till
                                        </Button>,
                                      ])}
                                    columns={columns}
                                    options={options}
                                  />
                                </Grid>
                              )}
                            </>
                          )}
                        </FieldArray>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(observer(EditUser));
