export interface Banner {
  dateFrom: string;
  dateTo: string;
  message: string;
  key: BannerChannel;
}

export const GLOBAL_BANNER = 'GlobalBanner';

export type BannerChannel = string | typeof GLOBAL_BANNER;
