import { Store, StoreChain } from './Store';

export interface UserProfile {
  sellerId: string;
  userId: UserId;
  username: string;
  firstName: string;
  lastName: string;
  email: string;

  chains: StoreChain[];
  stores: Store[];
}

export interface User {
  id?: number;
  userId: UserId;
  sellerId: string;
  activeStoreId: string;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;

  chains: StoreChain[];
  stores: Store[];

  amr: string[];
  idp: string;
  sid: string;
  sub: string;
  paymentMethod: string;
}

export enum PaymentMethod {
  Nets = 'Nets',
  Ocr = 'Ocr',
}

export type UserId = string;
