import {
  Box,
  Button,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { BannerChannel, GLOBAL_BANNER } from 'app/domain';
import { CURRENT_TENANT } from 'app/Tenant';
import { type IAppStoreProps, PageComponent } from 'utils/ComponentUtils';
import { observer } from 'mobx-react';
import { getBannerManagementStore } from './BannerManagementStore';

export const BannerManagement = observer(
  class BannerManagement extends PageComponent<IAppStoreProps> {
    store = getBannerManagementStore().withAppStore(this.props.appStore);

    render() {
      const { isLoading, isPublished, message } = this.store;

      return (
        <>
          <Grid container>
            <Grid item md={8}>
              <Typography variant="h6">Notifiering</Typography>
              <Typography>
                Här kan du ange en text och publicera texten som en notifiering. Meddelandet kommer att vara
                synligt för alla användare och synas på alla sidor i Online Service.
              </Typography>
            </Grid>
          </Grid>
          <Box mb={2} />
          <Grid item md={6}>
            <Select
              style={{ width: 320 }}
              variant="outlined"
              value={this.store.currentChannel}
              onChange={(e) => this.store.changeChannel(e.target.value as BannerChannel)}
              disabled={isLoading}
            >
              <MenuItem value={GLOBAL_BANNER}>Alla applikationer (NOS and OS)</MenuItem>
              <MenuItem value={CURRENT_TENANT}>Endast denna applikation</MenuItem>
            </Select>
          </Grid>
          <Box mb={2} />
          <Grid item md={6}>
            <TextField
              value={this.store.message}
              onChange={(e) => this.store.changeMessage(e.target.value as string)}
              multiline
              fullWidth
              minRows={4}
              placeholder="Enter text..."
              variant="outlined"
              disabled={isLoading}
            />
            {isLoading && <LinearProgress />}
          </Grid>
          <Box mb={2} />
          <Grid item md={6}>
            <Grid container>
              <Grid item md={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={this.store.publish}
                  disabled={isLoading || Boolean(isPublished) || Boolean(!message)}
                >
                  PUBLICERA
                </Button>
              </Grid>
              <Grid item md={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={this.store.unpublish}
                  disabled={isLoading || Boolean(!isPublished)}
                >
                  AVPLUBLICERA
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    }
  }
);
