export enum TrackingAction {
  NewApplication = 'New Application',
  ResumeApplication = 'Resume Application',
  IdentificationStep1Continue = 'Continue Application',
  IdentificationStep1Cancel = 'Go Back To Campaigns',
  CancelApplication = 'Cancel Application',
  UpdateApplication = 'Update Application',
  DeleteApplication = 'Delete Application',
  CreateApplication = 'Send Application',
  ShredApplication = 'Shred Application',
  ChangeCreditLimit = 'Change Credit Limit',
  SignAgreement = 'Sign Agreement',
  GenerateAgreement = 'Generate Manual Agreement',
  ContinueSigningAgreement = 'Continue Signing Agreement',
  ContinueResumeApplication = 'Continue Resume Application',
  EndDeletingApplication = 'End Deleting Application',
  OpenAgreements = 'Open Agreements',
  PurchaseWithNets = 'Purchase With Nets',
  PurchaseWithOCR = 'Purchase With OCR',
  PostponePurchase = 'Postpone Purchase',
  UpdateAdditionalInfo = 'UpdateAdditionalInfo',
}

export enum TrackingCategory {
  FinancingApplication = 'Financing Application',
}
