import { Box, Grid, TextField, Button, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import * as React from 'react';
import { PageSubTitle } from 'app/components/PageTitle';
import DataTableTextLabels from 'utils/DataTableTextLabels';
import { getFindPurchaseStore } from './FindPurchaseStore';
import { ReturnStore } from './ReturnStore';
import { MaskedSsnInput } from 'app/components/MaskedSsnInput';

const columns = ['Transaktionsdatum', 'Belopp', 'Kedja', 'Kontonummer/OCR', 'Åtgärder'];
const options: MUIDataTableOptions = {
  responsive: 'vertical' as any, // eslint-disable-line @typescript-eslint/no-explicit-any
  filter: false,
  download: false,
  textLabels: DataTableTextLabels,
  selectableRows: 'none',
  print: false,
};

class Findpurchase extends React.Component<{ returnStore: ReturnStore }> {
  findPurchaseStore = getFindPurchaseStore().withReturnStore(this.props.returnStore);

  componentDidMount() {
    this.findPurchaseStore.clearPurchases();
  }

  render() {
    const { setSsn, ssnIsValid, validateSsn, ssnError, getPurchases, noResult } = this.findPurchaseStore;
    return (
      <>
        <PageSubTitle>Sök användare</PageSubTitle>
        <Grid container>
          <Grid item md={6}>
            <Box>
              <TextField
                InputProps={{
                  inputComponent: MaskedSsnInput,
                }}
                style={{ paddingBottom: '10px' }}
                label="Personnummer (12-siffror)"
                placeholder="ÅÅÅÅMMDD-XXXX"
                variant="filled"
                fullWidth
                onChange={(e) => setSsn(e.target.value)}
                error={!ssnIsValid}
                helperText={ssnIsValid ? '' : ssnError}
                onBlur={validateSsn}
              />
            </Box>
            <Box py={2}>
              <Button fullWidth variant="contained" color="primary" onClick={getPurchases}>
                Hämta
              </Button>
            </Box>
            <Typography>{noResult}</Typography>
          </Grid>
          {this.findPurchaseStore.tablePurchases && (
            <Grid item md={12}>
              <MUIDataTable
                title={'Köphistorik'}
                data={this.findPurchaseStore.tablePurchases}
                columns={columns}
                options={{ ...options, onRowClick: () => null }}
              />
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}

export default observer(Findpurchase);
