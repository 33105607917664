import { SchemaType } from 'utils/TypeUtils';
import { object, ref, string } from 'yup';

export type ChangePasswordSchema = SchemaType<typeof changePasswordSchema>;

export const changePasswordSchema = object({
  currentPassword: string().required('Du måste ange Nuvarande lösenord'),
  newPassword: string()
    .matches(/^[^åäöÅÄÖ]*$/, 'Lösenordet får inte innehålla å, ä eller ö')
    .matches(/(?=.*[a-z])(?=.*[A-Z])/, 'Lösenordet måste innehålla minst 1 liten och 1 stor bokstav')
    .matches(/(?=.*[0-9])/, 'Lösenordet måste innehålla minst 1 siffra')
    .min(8, 'Lösenordet måste innehålla minst 8 tecken')
    .max(15, 'Lösenordet kan innehålla max 15 tecken')
    .notOneOf([ref('currentPassword'), null], 'Lösenordet får inte vara samma som det nuvarande')
    .required('Du måste ange Nytt lösenord'),
  newPasswordConfirm: string()
    .oneOf([ref('newPassword'), null], 'Lösenorden matchar ej')
    .required('Du måste ange Upprepa nytt lösenord'),
});
