import { observer } from 'mobx-react';
import * as React from 'react';
import {
  Button,
  Accordion,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Box,
  LinearProgress,
} from '@material-ui/core';
import { FinancingStore } from '../../FinancingStore';
import { SignFlow } from 'app/domain/SignFlow';
import SemiSelfServiceForm from './SemiSelfServiceForm';
import { Form, Formik } from 'formik';
import { semiSelfServiceSchema, SemiSelfServiceSchema } from './SemiSelfServiceFormSchema';
import { getPhoneNumberWithPrefix } from 'utils/phoneValidation';

interface IProps {
  financingStore: FinancingStore;
}

class SemiSelfServiceDialog extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  showSuccessDialog() {
    const { financingStore } = this.props;

    return financingStore.appStore.showAlert({
      title: 'Klart!',
      hideCancel: true,
      content: (
        <div style={{ width: 400, height: 100 }}>
          <Typography>Ansökan skickades till kunden.</Typography>
        </div>
      ),
      acceptCallback: () => {
        financingStore.appStore.hideAlert({ title: 'Klart!' });
      },
    });
  }

  render() {
    const { financingStore } = this.props;
    const { customer } = financingStore.financing;

    const activeStyles = { background: 'rgba(210, 200, 221, 0.565)' };

    return (
      <Dialog maxWidth="md" open={financingStore.showSemiServiceFlowPopup}>
        <Formik<SemiSelfServiceSchema>
          validationSchema={semiSelfServiceSchema}
          validateOnMount
          validateOnChange
          initialValues={{
            phoneNumber: getPhoneNumberWithPrefix(financingStore.financing.customer.phone),
            emailAddress: financingStore.financing.customer.emailAddress,
            flowMethod: SignFlow.NOS,
          }}
          onSubmit={async (values) => {
            await financingStore.submitSelfService(values);
            if (values.flowMethod !== SignFlow.NOS) this.showSuccessDialog();
          }}
        >
          {(formikProps) => (
            <Form>
              <DialogTitle id="confirmation-dialog-title">Fortsätt med ansökan</DialogTitle>
              <DialogContent style={{ width: '900px' }}>
                <Grid container>
                  <Grid item sm={12} md={4}>
                    <Typography>
                      <strong>{customer.name}</strong>
                    </Typography>
                    <Typography>{customer.socialSecurityNumber}</Typography>
                    <Typography>{customer.address}</Typography>
                    <Typography>
                      {customer.zipCode} {customer.city}
                    </Typography>
                  </Grid>
                </Grid>
                <Box mb={3} />
                <Accordion
                  expanded={false}
                  onClick={() => formikProps.setFieldValue('flowMethod', SignFlow.NOS)}
                >
                  <AccordionSummary
                    style={formikProps.values.flowMethod === SignFlow.NOS ? activeStyles : {}}
                  >
                    <Typography>Fortsätt med ansökningen i Online Service</Typography>
                  </AccordionSummary>
                </Accordion>
                <Accordion expanded={formikProps.values.flowMethod != SignFlow.NOS}>
                  <AccordionSummary
                    style={formikProps.values.flowMethod != SignFlow.NOS ? activeStyles : {}}
                    onClick={() => formikProps.setFieldValue('flowMethod', SignFlow.PHONE)}
                  >
                    <Typography>Skicka ansökan till kunden</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SemiSelfServiceForm financingStore={financingStore} {...formikProps} />
                  </AccordionDetails>
                </Accordion>
                {formikProps.isSubmitting && <LinearProgress />}
              </DialogContent>
              <Box mb={3} />
              <DialogActions>
                <Button
                  disabled={formikProps.isSubmitting || !formikProps.isValid}
                  onClick={formikProps.submitForm}
                  color="primary"
                >
                  Fortsätt
                </Button>
                <Button
                  onClick={() => {
                    financingStore.showSemiServiceFlowPopup = false;
                  }}
                  color="primary"
                >
                  Avbryt
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }
}

export default observer(SemiSelfServiceDialog);
