export default {
  body: {
    noMatch: 'Inga resultat',
    toolTip: 'Sortera',
  },
  pagination: {
    next: 'Nästa sida',
    previous: 'Föregående sida',
    rowsPerPage: 'Rader per sida:',
    displayRows: 'av',
  },
  toolbar: {
    search: 'Sök',
    downloadCsv: 'Ladda ner som .CSV',
    print: 'Skriv ut',
    viewColumns: 'Visa/dölj kolumner',
    filterTable: 'Filtrera',
  },
  filter: {
    all: 'Alla',
    title: 'FILTER',
    reset: 'ÅTERSTÄLL',
  },
  viewColumns: {
    title: 'Visa/dölj kolumner',
    titleAria: 'Visa/dölj kolumner',
  },
  selectedRows: {
    text: 'rad(er) valda',
    delete: 'Ta bort',
    deleteAria: 'Ta bort valda rader',
  },
};
