import { FieldProps, getIn } from 'formik';
import { TextField, TextFieldProps as MuiTextFieldProps } from '@material-ui/core';
import { getPhoneNumberWithPrefix } from 'utils/phoneValidation';
import { string } from 'yup';
import { getPhonePrefix } from 'utils/phoneValidation';

export interface TextFieldProps extends FieldProps, Omit<MuiTextFieldProps, 'name' | 'value' | 'error'> {
  name: string;
}

export const PhoneField = ({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  helperText,
  ...props
}: TextFieldProps) => {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    const onlyDigitsWithPlusSign = new RegExp(/^\+?[0-9]*$/);

    if (!onlyDigitsWithPlusSign.test(value.replace(' ', ''))) return;

    let parsedValue = value;

    if (parsedValue.startsWith('00')) {
      parsedValue = '+' + parsedValue.slice(2);
    } else if (parsedValue.startsWith('0') && parsedValue.length > 1) {
      parsedValue = '+46' + parsedValue.slice(1);
    }

    event.target.value = getPhoneNumberWithPrefix(parsedValue);

    field.onChange(event);
  };

  const parsedProps = {
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name);
      },
    ...field,
    ...props,
  };

  return (
    <TextField
      label="Mobilnummer"
      autoComplete="off"
      fullWidth
      variant="filled"
      {...parsedProps}
      onChange={(e) => handlePhoneNumberChange(e)}
    />
  );
};

export const phoneSchema = string()
  .required('Du måste ange telefonnummer')
  .matches(/^\+?[0-9\s?]*$/, 'Mobilnummer får endast innehålla 0-9 och +')
  .test(
    'min',
    'Mobilnummer måste vara minst 8 siffror',
    (value) => value != null && value.replace(/\s|\+/g, '').length >= 8
  )
  .test(
    'max',
    'Mobilnummer får vara max 13 siffror',
    (value) => value != null && value.replace(/\s|\+/g, '').length <= 13
  )
  .test('hasPrefix', 'Landskod saknas', (value) => getPhonePrefix(value) != null);
