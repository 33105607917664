// type definitions

export interface Ok<T> {
  value: T;
  statusCode: number;
  isSuccess: true;
}

export interface Failure {
  error?: FinancingServiceError;
  statusCode?: number;
  isSuccess: false;
}

export type Result<T> = Ok<T> | Failure;

export type ResultPromise<T> = Promise<Result<T>>;

export interface FinancingServiceError {
  detail?: string;
  code: number;
  title: string;
}

// factory methods for ergonomics

export function Ok<T>(value: T, statusCode?: number): Ok<T> {
  return {
    value,
    statusCode: statusCode || 200,
    isSuccess: true,
  };
}

export function Failure(error?: FinancingServiceError, statusCode?: number): Failure {
  return {
    error,
    statusCode,
    isSuccess: false,
  };
}
