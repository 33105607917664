import { CampaignType } from './CampaignType';

export interface Campaign {
  accountGroup: string;
  fromDate: string;
  fromDateUtc: string;
  toDate: string;
  toDateUtc: string;
  baseInterestCode: string;
  calculatedInterestFreeDate: string;
  code: string;
  name: string;
  description: string;
  interest: number;
  annualInterest: number;
  startupFee: number;
  billingFee: number;
  minAmount: number;
  maxAmount: number;
  effectiveInterest1: number;
  effectiveInterest2: number;
  effectiveIntrestFromDate: string;
  effectiveIntrestFromDateUtc: string;
  masterCard: boolean;
  factSheetInformation: string;
  annualFee: number;
  months: number;
  interestIndicator: boolean;
  minimumPayment: number;
  monthlyFeeIndicator: boolean;
  campaignType: CampaignType;
  semId: string;
  ucType: string;
  minimumRepaymentPeriods: number;
  maximumRepaymentPeriods: number;
  billingFeeUnder: number;
  cost: CampaignCost;
}

export interface CampaignCost {
  calculatedMonths: number;
  monthlyCost: number;
  totalCost: number;
}

export function GetPaymentPlanText(campaign: Campaign) {
  if (campaign.campaignType === CampaignType.BNPL) {
    return [
      `Köp nu och betala senast i månad ${campaign.months}.`,
      `Det går att göra inbetalningar månad 2 - ${campaign.months - 1}.`,
    ];
  }

  if (campaign.campaignType === CampaignType.IF) {
    return [
      `Köp nu och delbetala räntefritt i ${campaign.months} månader.`,
      `Santander aviserar 1/${campaign.months} som lägsta månadsbelopp men det går bra att betala mer.`,
    ];
  }

  if (campaign.campaignType === CampaignType.PP) {
    return [
      'Kunden får en faktura varje månad från Santander Consumer Bank med det belopp som ska delbetalas.',
      'I beloppet ingår ränta och aviavgift.',
    ];
  }

  return [];
}

export function GetBillingFeeText(campaign?: Campaign) {
  if (campaign == null) {
    return undefined;
  }

  if (campaign.campaignType === CampaignType.BNPL || campaign.campaignType === CampaignType.IF) {
    return `${campaign.billingFee} kr under den räntefria perioden, därefter ${campaign.billingFeeUnder} kr.`;
  } else {
    return `${campaign.billingFee} kr`;
  }
}
