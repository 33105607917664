import { FinancingClient } from '../FinancingClient';

export interface SendSelfCheckoutLinkRequest {
  inquiryId: string;
  emailAddress?: string;
  phoneNumber?: string;
  chainId?: string;
}

export function SendSelfCheckoutLink(client: FinancingClient, req: SendSelfCheckoutLinkRequest) {
  const request = {
    ...req,
  };
  request.chainId = client.chainId;
  return client.post(`/customers/send-selfcheckout-link`, request);
}
