import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';

interface IProps {
  ytlink: string;
  title: string;
}

class Video extends React.Component<IProps> {
  public render() {
    const iframe =
      '<iframe width="529" height="360" src="' +
      this.props.ytlink +
      '?showinfo=0&rel=0&hl=sv" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>';

    return (
      <Grid item md={6}>
        <Typography variant="h6" style={{ margin: '15px 0 5px 0' }}>
          {this.props.title}
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: iframe ? iframe : '' }} />
      </Grid>
    );
  }
}

export default Video;
