import { Banner, BannerChannel } from 'app/domain';
import { FinancingClient } from '../FinancingClient';

export type CacheResponse<TData> = TData | '';

export interface UpdateBannerRequest {
  key: BannerChannel;
  dateFrom?: string;
  dateTo: string;
  message: string;
}

export function UpdateBanner(client: FinancingClient, req: UpdateBannerRequest) {
  const query = {
    ...req,
    chainId: client.chainId,
  };

  return client.post<unknown>(`/admin/cache/banners`, query);
}

export interface GetBannerRequest {
  key: BannerChannel;
}

export function GetBanner(client: FinancingClient, req: GetBannerRequest) {
  const query = {
    ...req,
    chainId: client.chainId,
  };

  return client.get<CacheResponse<Banner>>(`/admin/cache/banners`, query);
}

export interface DeleteBannerRequest {
  key: BannerChannel;
}

export function DeleteBanner(client: FinancingClient, req: DeleteBannerRequest) {
  const query = {
    ...req,
    chainId: client.chainId,
  };

  return client.delete<CacheResponse<Banner>>(`/admin/cache/banners`, query);
}

export interface UpdateManualSigningRequest {
  tenant: string;
  state: boolean;
}

export function UpdateManualSigning(client: FinancingClient, req: UpdateManualSigningRequest) {
  const query = {
    ...req,
    chainId: client.chainId,
  };

  return client.post<unknown>(`/admin/cache/manualcontracts`, query);
}

export interface GetManualSigningRequest {
  tenant: string;
}

export function GetManualSigning(client: FinancingClient, req: GetManualSigningRequest) {
  const query = {
    ...req,
    chainId: client.chainId,
  };

  return client.get<{ state: boolean }>(`/admin/cache/manualcontracts`, query);
}
