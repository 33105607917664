import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { AppStore } from './AppStore';
import { Alert } from './domain/Alert';

const styles = (theme: Theme) =>
  createStyles({
    stepper: {
      marginBottom: theme.spacing(1),
    },
    alertTitle: {
      color: '#ec0000',
    },
  });

interface IProps extends WithStyles<typeof styles> {
  appStore: AppStore;
  alert: Alert;
}

class AlertDialog extends React.Component<IProps> {
  onAccept = () => {
    const {
      appStore: { closeAlert },
      alert,
    } = this.props;
    alert.acceptCallback?.();
    !alert.keepOpenOnAccept && closeAlert(alert);
  };
  onAbort = () => {
    const {
      appStore: { closeAlert },
      alert,
    } = this.props;
    if (typeof alert.cancelCallback === 'function') {
      alert.cancelCallback();
    }
    closeAlert(alert);
  };

  public render() {
    const { classes } = this.props;
    const { alert } = this.props;
    return (
      !alert.hidden && (
        <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          {alert.redTitle ? (
            <DialogTitle id="alert-dialog-title" className={classes.alertTitle}>
              {alert.title}
            </DialogTitle>
          ) : (
            <DialogTitle id="alert-dialog-title">{alert.title}</DialogTitle>
          )}
          <DialogContent>{toJS(alert.content)}</DialogContent>
          <DialogActions>
            {!alert.hideAccept && (
              <Button onClick={this.onAccept} color="primary" autoFocus disabled={alert.acceptDisabled}>
                {alert.acceptText ? alert.acceptText : 'Ok'}
              </Button>
            )}
            {!alert.hideCancel && (
              <Button onClick={this.onAbort} color="primary" disabled={alert.cancelDisabled}>
                {alert.cancelText ? alert.cancelText : 'Avbryt'}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )
    );
  }
}

export default withStyles(styles)(observer(AlertDialog));
