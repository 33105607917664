import { AppStore } from 'app/AppStore';
import { observer } from 'mobx-react';
import { ManualSigningToggle } from './ManualSigningToggle';
import { BannerManagement } from './BannerManagement';
import { Box } from '@material-ui/core';
import { isAllowedBannersManagement, isAllowedManualSigning } from 'utils/envUtils';
interface SettingsProps {
  appStore: AppStore;
}

export const Settings = observer((props: SettingsProps) => {
  const { appStore } = props;

  const canChangeManualSigning = isAllowedManualSigning(appStore.user.userId);
  const canUpdateBanners = isAllowedBannersManagement(appStore.user.userId);

  return (
    <>
      {canChangeManualSigning && <ManualSigningToggle appStore={appStore} />}
      {canChangeManualSigning && canUpdateBanners && <Box my={3} />}
      {canUpdateBanners && <BannerManagement appStore={appStore} />}
    </>
  );
});
