import { Purchase } from 'app/domain';
import { FinancingClient } from '../FinancingClient';

export interface GetPurchasesRequest {
  ssn: string;
  chainId: string;
}

export function GetPurchases(client: FinancingClient, req: GetPurchasesRequest) {
  const query = {
    ssn: req.ssn,
    chainId: client.chainId,
  };

  return client.post<Purchase[]>(`/purchases/`, query);
}

export interface RefundPurchaseRequest {
  purchaseId: string;
  amount: number;
  chainId: string;
}

export function RefundPurchase(client: FinancingClient, req: RefundPurchaseRequest) {
  const query = {
    purchaseId: req.purchaseId,
    amount: req.amount,
    chainId: req.chainId,
  };
  return client.post(`/purchases/refundPurchaseId/`, query);
}

export interface PostPurchaseCLSRequest {
  purchaseId: string;
  amount?: number;
  chainId: string;
}

export function PostPurchaseCLS(client: FinancingClient, req: PostPurchaseCLSRequest) {
  const query = {
    purchaseId: req.purchaseId,
    amount: req.amount,
    chainId: req.chainId,
  };
  return client.post(`/purchases/purchase/`, query);
}

export interface DelayedPurchaseRequest {
  ssn: string;
  applicationId: string;
  purchaseId: string;
  amount: number;
  paymentSolution: string;
}

export function DelayedPurchase(client: FinancingClient, req: DelayedPurchaseRequest) {
  const query = {
    ssn: req.ssn.replace(/\D/g, ''),
    applicationId: req.applicationId,
    purchaseId: req.purchaseId,
    amount: req.amount,
    paymentSolution: req.paymentSolution,
    chainId: client.chainId,
  };

  return client.post(`purchases/delayedPurchase`, query);
}

export interface ReservePurchaseRequest {
  purchaseId: string;
  amount?: number;
  chainId: string;
}

export function ReservePurchase(client: FinancingClient, req: ReservePurchaseRequest) {
  const query = {
    purchaseId: req.purchaseId,
    amount: req.amount,
    chainId: req.chainId,
  };

  return client.post(`/purchases/authorize`, query);
}

export interface CancelPurchaseRequest {
  purchaseId: string;
  chainId: string;
}

export function CancelPurchase(client: FinancingClient, req: CancelPurchaseRequest) {
  const data = {
    purchaseId: req.purchaseId,
    chainId: req.chainId,
  };

  return client.post(`purchases/cancel`, data);
}
