export const Country = {
  AD: {
    code: 'AD',
    name: 'Andorra',
  },
  AE: {
    code: 'AE',
    name: 'Förenade arabemiraten',
  },
  AF: {
    code: 'AF',
    name: 'Afghanistan',
  },
  AG: {
    code: 'AG',
    name: 'Antigua och Barbuda',
  },
  AI: {
    code: 'AI',
    name: 'Anguilla',
  },
  AL: {
    code: 'AL',
    name: 'Albanien',
  },
  AM: {
    code: 'AM',
    name: 'Armenien',
  },
  AN: {
    code: 'AN',
    name: 'Nederlandse Antillen',
  },
  AO: {
    code: 'AO',
    name: 'Angola',
  },
  AQ: {
    code: 'AQ',
    name: 'Antarktis',
  },
  AR: {
    code: 'AR',
    name: 'Argentina',
  },
  AS: {
    code: 'AS',
    name: 'Amerikanska Samoa',
  },
  AT: {
    code: 'AT',
    name: 'Österrike',
  },
  AU: {
    code: 'AU',
    name: 'Australien',
  },
  AW: {
    code: 'AW',
    name: 'Aruba',
  },
  AX: {
    code: 'AX',
    name: 'Åland',
  },
  AZ: {
    code: 'AZ',
    name: 'Azerbajdzjan',
  },
  BA: {
    code: 'BA',
    name: 'Bosnien och Hercegovina',
  },
  BB: {
    code: 'BB',
    name: 'Barbados',
  },
  BD: {
    code: 'BD',
    name: 'Bangladesh',
  },
  BE: {
    code: 'BE',
    name: 'Belgien',
  },
  BF: {
    code: 'BF',
    name: 'Burkina Faso',
  },
  BG: {
    code: 'BG',
    name: 'Bulgarien',
  },
  BH: {
    code: 'BH',
    name: 'Bahrain',
  },
  BI: {
    code: 'BI',
    name: 'Burundi',
  },
  BJ: {
    code: 'BJ',
    name: 'Benin',
  },
  BL: {
    code: 'BL',
    name: 'Saint-Barthélemy',
  },
  BM: {
    code: 'BM',
    name: 'Bermuda',
  },
  BN: {
    code: 'BN',
    name: 'Brunei',
  },
  BO: {
    code: 'BO',
    name: 'Bolivia',
  },
  BQ: {
    code: 'BQ',
    name: 'Bonaire, Sint Eustatius and Saba',
  },
  BR: {
    code: 'BR',
    name: 'Brasilien',
  },
  BS: {
    code: 'BS',
    name: 'Bahamas',
  },
  BT: {
    code: 'BT',
    name: 'Bhutan',
  },
  BV: {
    code: 'BV',
    name: 'Bouvetön',
  },
  BW: {
    code: 'BW',
    name: 'Botswana',
  },
  BY: {
    code: 'BY',
    name: 'Belarus',
  },
  BZ: {
    code: 'BZ',
    name: 'Belize',
  },
  CA: {
    code: 'CA',
    name: 'Kanada',
  },
  CC: {
    code: 'CC',
    name: 'Kokosöarna',
  },
  CD: {
    code: 'CD',
    name: 'Kongo-Kinshasa',
  },
  CF: {
    code: 'CF',
    name: 'Centralafrikanska republiken',
  },
  CG: {
    code: 'CG',
    name: 'Kongo-Brazzaville',
  },
  CH: {
    code: 'CH',
    name: 'Schweiz',
  },
  CI: {
    code: 'CI',
    name: 'Elfenbenskusten',
  },
  CK: {
    code: 'CK',
    name: 'Cooköarna',
  },
  CL: {
    code: 'CL',
    name: 'Chile',
  },
  CM: {
    code: 'CM',
    name: 'Kamerun',
  },
  CN: {
    code: 'CN',
    name: 'Kina',
  },
  CO: {
    code: 'CO',
    name: 'Colombia',
  },
  CR: {
    code: 'CR',
    name: 'Costa Rica',
  },
  CU: {
    code: 'CU',
    name: 'Kuba',
  },
  CV: {
    code: 'CV',
    name: 'Kap Verde',
  },
  CW: {
    code: 'CW',
    name: 'Curaçao',
  },
  CX: {
    code: 'CX',
    name: 'Julön',
  },
  CY: {
    code: 'CY',
    name: 'Cypern',
  },
  CZ: {
    code: 'CZ',
    name: 'Tjeckien',
  },
  DE: {
    code: 'DE',
    name: 'Tyskland',
  },
  DJ: {
    code: 'DJ',
    name: 'Djibouti',
  },
  DK: {
    code: 'DK',
    name: 'Danmark',
  },
  DM: {
    code: 'DM',
    name: 'Dominica',
  },
  DO: {
    code: 'DO',
    name: 'Dominikanska republiken',
  },
  DZ: {
    code: 'DZ',
    name: 'Algeriet',
  },
  EC: {
    code: 'EC',
    name: 'Ecuador',
  },
  EE: {
    code: 'EE',
    name: 'Estland',
  },
  EG: {
    code: 'EG',
    name: 'Egypten',
  },
  EH: {
    code: 'EH',
    name: 'Västsahara',
  },
  ER: {
    code: 'ER',
    name: 'Eritrea',
  },
  ES: {
    code: 'ES',
    name: 'Spanien',
  },
  ET: {
    code: 'ET',
    name: 'Etiopien',
  },
  FI: {
    code: 'FI',
    name: 'Finland',
  },
  FJ: {
    code: 'FJ',
    name: 'Fiji',
  },
  FK: {
    code: 'FK',
    name: 'Falklandsöarna',
  },
  FM: {
    code: 'FM',
    name: 'Mikronesiska federationen',
  },
  FO: {
    code: 'FO',
    name: 'Färöarna',
  },
  FR: {
    code: 'FR',
    name: 'Frankrike',
  },
  GA: {
    code: 'GA',
    name: 'Gabon',
  },
  GD: {
    code: 'GD',
    name: 'Grenada',
  },
  GE: {
    code: 'GE',
    name: 'Georgien',
  },
  GF: {
    code: 'GF',
    name: 'Franska Guyana',
  },
  GG: {
    code: 'GG',
    name: 'Guernsey',
  },
  GH: {
    code: 'GH',
    name: 'Ghana',
  },
  GI: {
    code: 'GI',
    name: 'Gibraltar',
  },
  GL: {
    code: 'GL',
    name: 'Grönland',
  },
  GM: {
    code: 'GM',
    name: 'Gambia',
  },
  GN: {
    code: 'GN',
    name: 'Guinea',
  },
  GP: {
    code: 'GP',
    name: 'Guadeloupe',
  },
  GQ: {
    code: 'GQ',
    name: 'Ekvatorialguinea',
  },
  GR: {
    code: 'GR',
    name: 'Grekland',
  },
  GS: {
    code: 'GS',
    name: 'Sydgeorgien och Sydsandwichöarna',
  },
  GT: {
    code: 'GT',
    name: 'Guatemala',
  },
  GU: {
    code: 'GU',
    name: 'Guam',
  },
  GW: {
    code: 'GW',
    name: 'Guinea-Bissau',
  },
  GY: {
    code: 'GY',
    name: 'Guyana',
  },
  HK: {
    code: 'HK',
    name: 'Hongkong',
  },
  HM: {
    code: 'HM',
    name: 'Heard- och McDonaldöarna',
  },
  HN: {
    code: 'HN',
    name: 'Honduras',
  },
  HR: {
    code: 'HR',
    name: 'Kroatien',
  },
  HT: {
    code: 'HT',
    name: 'Haiti',
  },
  HU: {
    code: 'HU',
    name: 'Ungern',
  },
  ID: {
    code: 'ID',
    name: 'Indonesien',
  },
  IE: {
    code: 'IE',
    name: 'Irland',
  },
  IL: {
    code: 'IL',
    name: 'Israel',
  },
  IM: {
    code: 'IM',
    name: 'Isle of Man',
  },
  IN: {
    code: 'IN',
    name: 'Indien',
  },
  IO: {
    code: 'IO',
    name: 'Brittiska territoriet i Indiska oceanen',
  },
  IQ: {
    code: 'IQ',
    name: 'Irak',
  },
  IR: {
    code: 'IR',
    name: 'Iran',
  },
  IS: {
    code: 'IS',
    name: 'Island',
  },
  IT: {
    code: 'IT',
    name: 'Italien',
  },
  JE: {
    code: 'JE',
    name: 'Jersey',
  },
  JM: {
    code: 'JM',
    name: 'Jamaica',
  },
  JO: {
    code: 'JO',
    name: 'Jordanien',
  },
  JP: {
    code: 'JP',
    name: 'Japan',
  },
  KE: {
    code: 'KE',
    name: 'Kenya',
  },
  KG: {
    code: 'KG',
    name: 'Kirgizistan',
  },
  KH: {
    code: 'KH',
    name: 'Kambodja',
  },
  KI: {
    code: 'KI',
    name: 'Kiribati',
  },
  KM: {
    code: 'KM',
    name: 'Komorerna',
  },
  XK: {
    code: 'XK',
    name: 'Kosovo',
  },
  KN: {
    code: 'KN',
    name: 'Saint Kitts och Nevis',
  },
  KP: {
    code: 'KP',
    name: 'Nordkorea',
  },
  KR: {
    code: 'KR',
    name: 'Sydkorea',
  },
  KW: {
    code: 'KW',
    name: 'Kuwait',
  },
  KY: {
    code: 'KY',
    name: 'Caymanöarna',
  },
  KZ: {
    code: 'KZ',
    name: 'Kazakstan',
  },
  LA: {
    code: 'LA',
    name: 'Laos',
  },
  LB: {
    code: 'LB',
    name: 'Libanon',
  },
  LC: {
    code: 'LC',
    name: 'Saint Lucia',
  },
  LI: {
    code: 'LI',
    name: 'Liechtenstein',
  },
  LK: {
    code: 'LK',
    name: 'Sri Lanka',
  },
  LR: {
    code: 'LR',
    name: 'Liberia',
  },
  LS: {
    code: 'LS',
    name: 'Lesotho',
  },
  LT: {
    code: 'LT',
    name: 'Litauen',
  },
  LU: {
    code: 'LU',
    name: 'Luxemburg',
  },
  LV: {
    code: 'LV',
    name: 'Lettland',
  },
  LY: {
    code: 'LY',
    name: 'Libyen',
  },
  MA: {
    code: 'MA',
    name: 'Marocko',
  },
  MC: {
    code: 'MC',
    name: 'Monaco',
  },
  MD: {
    code: 'MD',
    name: 'Moldavien',
  },
  ME: {
    code: 'ME',
    name: 'Montenegro',
  },
  MF: {
    code: 'MF',
    name: 'Saint-Martin',
  },
  MG: {
    code: 'MG',
    name: 'Madagaskar',
  },
  MH: {
    code: 'MH',
    name: 'Marshallöarna',
  },
  MK: {
    code: 'MK',
    name: 'Nordmakedonien',
  },
  ML: {
    code: 'ML',
    name: 'Mali',
  },
  MM: {
    code: 'MM',
    name: 'Myanmar',
  },
  MN: {
    code: 'MN',
    name: 'Mongoliet',
  },
  MO: {
    code: 'MO',
    name: 'Macao',
  },
  MP: {
    code: 'MP',
    name: 'Nordmarianerna',
  },
  MQ: {
    code: 'MQ',
    name: 'Martinique',
  },
  MR: {
    code: 'MR',
    name: 'Mauretanien',
  },
  MS: {
    code: 'MS',
    name: 'Montserrat',
  },
  MT: {
    code: 'MT',
    name: 'Malta',
  },
  MU: {
    code: 'MU',
    name: 'Mauritius',
  },
  MV: {
    code: 'MV',
    name: 'Maldiverna',
  },
  MW: {
    code: 'MW',
    name: 'Malawi',
  },
  MX: {
    code: 'MX',
    name: 'Mexiko',
  },
  MY: {
    code: 'MY',
    name: 'Malaysia',
  },
  MZ: {
    code: 'MZ',
    name: 'Moçambique',
  },
  NA: {
    code: 'NA',
    name: 'Namibia',
  },
  NC: {
    code: 'NC',
    name: 'Nya Kaledonien',
  },
  NE: {
    code: 'NE',
    name: 'Niger',
  },
  NF: {
    code: 'NF',
    name: 'Norfolkön',
  },
  NG: {
    code: 'NG',
    name: 'Nigeria',
  },
  NI: {
    code: 'NI',
    name: 'Nicaragua',
  },
  NL: {
    code: 'NL',
    name: 'Nederländerna',
  },
  NO: {
    code: 'NO',
    name: 'Norge',
  },
  NP: {
    code: 'NP',
    name: 'Nepal',
  },
  NR: {
    code: 'NR',
    name: 'Nauru',
  },
  NU: {
    code: 'NU',
    name: 'Niue',
  },
  NZ: {
    code: 'NZ',
    name: 'Nya Zeeland',
  },
  OM: {
    code: 'OM',
    name: 'Oman',
  },
  PA: {
    code: 'PA',
    name: 'Panama',
  },
  PE: {
    code: 'PE',
    name: 'Peru',
  },
  PF: {
    code: 'PF',
    name: 'Franska Polynesien',
  },
  PG: {
    code: 'PG',
    name: 'Papua Nya Guinea',
  },
  PH: {
    code: 'PH',
    name: 'Filippinerna',
  },
  PK: {
    code: 'PK',
    name: 'Pakistan',
  },
  PL: {
    code: 'PL',
    name: 'Polen',
  },
  PM: {
    code: 'PM',
    name: 'Saint-Pierre och Miquelon',
  },
  PN: {
    code: 'PN',
    name: 'Pitcairnöarna',
  },
  PR: {
    code: 'PR',
    name: 'Puerto Rico',
  },
  PS: {
    code: 'PS',
    name: 'Palestina',
  },
  PT: {
    code: 'PT',
    name: 'Portugal',
  },
  PW: {
    code: 'PW',
    name: 'Palau',
  },
  PY: {
    code: 'PY',
    name: 'Paraguay',
  },
  QA: {
    code: 'QA',
    name: 'Qatar',
  },
  RE: {
    code: 'RE',
    name: 'Réunion',
  },
  RO: {
    code: 'RO',
    name: 'Rumänien',
  },
  RS: {
    code: 'RS',
    name: 'Serbien',
  },
  RU: {
    code: 'RU',
    name: 'Ryssland',
  },
  RW: {
    code: 'RW',
    name: 'Rwanda',
  },
  SA: {
    code: 'SA',
    name: 'Saudiarabien',
  },
  SB: {
    code: 'SB',
    name: 'Salomonöarna',
  },
  SC: {
    code: 'SC',
    name: 'Seychellerna',
  },
  SD: {
    code: 'SD',
    name: 'Sudan',
  },
  SE: {
    code: 'SE',
    name: 'Sverige',
  },
  SG: {
    code: 'SG',
    name: 'Singapore',
  },
  SH: {
    code: 'SH',
    name: 'Sankta Helena, Ascension och Tristan da Cunha',
  },
  SI: {
    code: 'SI',
    name: 'Slovenien',
  },
  SJ: {
    code: 'SJ',
    name: 'Svalbard och Jan Mayen',
  },
  SK: {
    code: 'SK',
    name: 'Slovakien',
  },
  SL: {
    code: 'SL',
    name: 'Sierra Leone',
  },
  SM: {
    code: 'SM',
    name: 'San Marino',
  },
  SN: {
    code: 'SN',
    name: 'Senegal',
  },
  SO: {
    code: 'SO',
    name: 'Somalia',
  },
  SR: {
    code: 'SR',
    name: 'Surinam',
  },
  SS: {
    code: 'SS',
    name: 'Sydsudan',
  },
  ST: {
    code: 'ST',
    name: 'São Tomé och Príncipe',
  },
  SV: {
    code: 'SV',
    name: 'El Salvador',
  },
  SX: {
    code: 'SX',
    name: 'Sint Maarten',
  },
  SY: {
    code: 'SY',
    name: 'Syrien',
  },
  SZ: {
    code: 'SZ',
    name: 'Swaziland',
  },
  TC: {
    code: 'TC',
    name: 'Turks- och Caicosöarna',
  },
  TD: {
    code: 'TD',
    name: 'Tchad',
  },
  TF: {
    code: 'TF',
    name: 'Franska sydterritorierna',
  },
  TG: {
    code: 'TG',
    name: 'Togo',
  },
  TH: {
    code: 'TH',
    name: 'Thailand',
  },
  TJ: {
    code: 'TJ',
    name: 'Tadzjikistan',
  },
  TK: {
    code: 'TK',
    name: 'Tokelau',
  },
  TL: {
    code: 'TL',
    name: 'Östtimor',
  },
  TM: {
    code: 'TM',
    name: 'Turkmenistan',
  },
  TN: {
    code: 'TN',
    name: 'Tunisien',
  },
  TO: {
    code: 'TO',
    name: 'Tonga',
  },
  TR: {
    code: 'TR',
    name: 'Turkiet',
  },
  TT: {
    code: 'TT',
    name: 'Trinidad och Tobago',
  },
  TV: {
    code: 'TV',
    name: 'Tuvalu',
  },
  TW: {
    code: 'TW',
    name: 'Taiwan',
  },
  TZ: {
    code: 'TZ',
    name: 'Tanzania',
  },
  UA: {
    code: 'UA',
    name: 'Ukraina',
  },
  UG: {
    code: 'UG',
    name: 'Uganda',
  },
  UK: {
    code: 'UK',
    name: 'Storbritannien',
  },
  UM: {
    code: 'UM',
    name: 'USA:s yttre öar',
  },
  US: {
    code: 'US',
    name: 'USA',
  },
  UY: {
    code: 'UY',
    name: 'Uruguay',
  },
  UZ: {
    code: 'UZ',
    name: 'Uzbekistan',
  },
  VA: {
    code: 'VA',
    name: 'Vatikanstaten',
  },
  VC: {
    code: 'VC',
    name: 'Saint Vincent och Grenadinerna',
  },
  VE: {
    code: 'VE',
    name: 'Venezuela',
  },
  VG: {
    code: 'VG',
    name: 'Brittiska Jungfruöarna',
  },
  VI: {
    code: 'VI',
    name: 'Amerikanska Jungfruöarna',
  },
  VN: {
    code: 'VN',
    name: 'Vietnam',
  },
  VU: {
    code: 'VU',
    name: 'Vanuatu',
  },
  WF: {
    code: 'WF',
    name: 'Wallis- och Futunaöarna',
  },
  WS: {
    code: 'WS',
    name: 'Samoa',
  },
  YE: {
    code: 'YE',
    name: 'Jemen',
  },
  YT: {
    code: 'YT',
    name: 'Mayotte',
  },
  ZA: {
    code: 'ZA',
    name: 'Sydafrika',
  },
  ZM: {
    code: 'ZM',
    name: 'Zambia',
  },
  ZW: {
    code: 'ZW',
    name: 'Zimbabwe',
  },
};

const Divider = {
  code: 'DIVIDER',
  name: '',
  isDivider: true,
};

export interface ICountryListItem {
  code: string;
  name: string;
  isDivider?: boolean;
}

export const countryList: ICountryListItem[] = [
  Country.SE,
  Country.NO,
  Country.DK,
  Country.FI,
  Divider,
  Country.AF,
  Country.AX,
  Country.AL,
  Country.DZ,
  Country.VI,
  Country.AS,
  Country.AD,
  Country.AO,
  Country.AI,
  Country.AQ,
  Country.AG,
  Country.AR,
  Country.AM,
  Country.AW,
  Country.AU,
  Country.AZ,
  Country.BS,
  Country.BH,
  Country.BD,
  Country.BB,
  Country.BY,
  Country.BE,
  Country.BZ,
  Country.BJ,
  Country.BM,
  Country.BT,
  Country.BO,
  Country.BQ,
  Country.BA,
  Country.BW,
  Country.BV,
  Country.BR,
  Country.VG,
  Country.IO,
  Country.BN,
  Country.BG,
  Country.BF,
  Country.BI,
  Country.KY,
  Country.CF,
  Country.CL,
  Country.CO,
  Country.CK,
  Country.CR,
  Country.CW,
  Country.CY,
  Country.DJ,
  Country.DM,
  Country.DO,
  Country.EC,
  Country.EG,
  Country.GQ,
  Country.SV,
  Country.CI,
  Country.ER,
  Country.EE,
  Country.ET,
  Country.FK,
  Country.FO,
  Country.FJ,
  Country.PH,
  Country.AE,
  Country.FR,
  Country.GF,
  Country.PF,
  Country.TF,
  Country.GA,
  Country.GM,
  Country.GE,
  Country.GH,
  Country.GI,
  Country.GR,
  Country.GD,
  Country.GL,
  Country.GP,
  Country.GU,
  Country.GT,
  Country.GG,
  Country.GN,
  Country.GW,
  Country.GY,
  Country.HT,
  Country.HM,
  Country.HN,
  Country.HK,
  Country.IN,
  Country.ID,
  Country.IQ,
  Country.IR,
  Country.IE,
  Country.IS,
  Country.IM,
  Country.IL,
  Country.IT,
  Country.JM,
  Country.JP,
  Country.YE,
  Country.JE,
  Country.JO,
  Country.CX,
  Country.KH,
  Country.CM,
  Country.CA,
  Country.CV,
  Country.KZ,
  Country.KE,
  Country.XK,
  Country.CN,
  Country.KG,
  Country.KI,
  Country.CC,
  Country.KM,
  Country.CG,
  Country.CD,
  Country.HR,
  Country.CU,
  Country.KW,
  Country.LA,
  Country.LS,
  Country.LV,
  Country.LB,
  Country.LR,
  Country.LY,
  Country.LI,
  Country.LT,
  Country.LU,
  Country.MO,
  Country.MG,
  Country.MW,
  Country.MY,
  Country.MV,
  Country.ML,
  Country.MT,
  Country.MA,
  Country.MH,
  Country.MQ,
  Country.MR,
  Country.MU,
  Country.YT,
  Country.MX,
  Country.FM,
  Country.MZ,
  Country.MD,
  Country.MC,
  Country.MN,
  Country.ME,
  Country.MS,
  Country.MM,
  Country.NA,
  Country.NR,
  Country.NL,
  Country.AN,
  Country.NP,
  Country.NI,
  Country.NE,
  Country.NG,
  Country.NU,
  Country.KP,
  Country.MK,
  Country.MP,
  Country.NF,
  Country.NC,
  Country.NZ,
  Country.OM,
  Country.AT,
  Country.TL,
  Country.PK,
  Country.PW,
  Country.PS,
  Country.PA,
  Country.PG,
  Country.PY,
  Country.PE,
  Country.PN,
  Country.PL,
  Country.PT,
  Country.PR,
  Country.QA,
  Country.RE,
  Country.RO,
  Country.RW,
  Country.RU,
  Country.KN,
  Country.LC,
  Country.VC,
  Country.BL,
  Country.MF,
  Country.PM,
  Country.SB,
  Country.WS,
  Country.SM,
  Country.SH,
  Country.ST,
  Country.SA,
  Country.CH,
  Country.SN,
  Country.RS,
  Country.SC,
  Country.SL,
  Country.SG,
  Country.SX,
  Country.SK,
  Country.SI,
  Country.SO,
  Country.ES,
  Country.LK,
  Country.UK,
  Country.SD,
  Country.SR,
  Country.SJ,
  Country.SZ,
  Country.ZA,
  Country.GS,
  Country.KR,
  Country.SS,
  Country.SY,
  Country.TJ,
  Country.TW,
  Country.TZ,
  Country.TD,
  Country.TH,
  Country.CZ,
  Country.TG,
  Country.TK,
  Country.TO,
  Country.TT,
  Country.TN,
  Country.TR,
  Country.TM,
  Country.TC,
  Country.TV,
  Country.DE,
  Country.UG,
  Country.UA,
  Country.HU,
  Country.UY,
  Country.US,
  Country.UM,
  Country.UZ,
  Country.VU,
  Country.EH,
  Country.VA,
  Country.VE,
  Country.VN,
  Country.WF,
  Country.ZM,
  Country.ZW,
];
