import * as React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import MUIDataTable, { Display, MUIDataTableOptions } from 'mui-datatables';
import { CampaignType } from 'app/domain/CampaignType';
import { FinancingStore } from './FinancingStore';
import { FormikProps } from 'formik';
import { ApplySchema } from './ApplySchema';

const CAMPAIGN_CODE_FIELD = 2;
const CAMPAIGN_TYPE_FIELD = 3;

const campaignColumns = [
  {
    name: 'Månader',
    options: {
      setCellProps: () => {
        return { style: { width: '40%' } };
      },
      customHeadRender: () => null,
    },
  },
  {
    name: 'Beskrivning',
    options: {
      setCellProps: () => {
        return { style: { width: '60%' } };
      },
      customHeadRender: () => null,
    },
  },
  {
    name: 'code',
    options: {
      display: 'false' as Display,
    },
  },
  {
    name: 'campaignType',
    options: { display: 'false' as Display },
  },
];

const options: MUIDataTableOptions = {
  responsive: 'vertical' as any, // eslint-disable-line @typescript-eslint/no-explicit-any
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  selectableRows: 'none',
  pagination: false,
  search: false,
};

type CampaignFormFieldsProps = FormikProps<ApplySchema> & {
  financingStore: FinancingStore;
  productInformation: React.ReactNode;
  campaigns: React.ReactText[][];
};

export const CampaignFormFields = (props: CampaignFormFieldsProps) => {
  const { financingStore, productInformation, campaigns, setFieldValue } = props;

  const postPonePaymentCampaigns: React.ReactText[][] = [];
  const partPaymentCampaigns: React.ReactText[][] = [];
  const fixedAmountCampaigns: React.ReactText[][] = [];

  campaigns.forEach((campaign) => {
    if (campaign[CAMPAIGN_TYPE_FIELD] === CampaignType.BNPL) {
      postPonePaymentCampaigns.push(campaign);
    } else if (campaign[CAMPAIGN_TYPE_FIELD] === CampaignType.FixedAmount) {
      fixedAmountCampaigns.push(campaign);
    } else {
      partPaymentCampaigns.push(campaign);
    }
  });

  const onRowClick = (rowData: string[]) => {
    const campaignCode = rowData[CAMPAIGN_CODE_FIELD];
    setFieldValue('campaignCode', campaignCode);
    financingStore.setCampaign(campaignCode);
  };

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography variant="h6">Om ditt köp</Typography>
        </Grid>
      </Grid>
      {postPonePaymentCampaigns && postPonePaymentCampaigns.length > 0 && (
        <MUIDataTable
          title={'Köp nu betala senare'}
          data={postPonePaymentCampaigns}
          columns={campaignColumns}
          options={{
            ...options,
            setRowProps: (row) => {
              const { campaign } = financingStore.financing;
              const style: React.CSSProperties = { cursor: 'pointer' };

              if (
                campaign &&
                row[CAMPAIGN_CODE_FIELD] === campaign.code &&
                row[CAMPAIGN_TYPE_FIELD] === CampaignType.BNPL
              ) {
                style.backgroundColor = '#d2c8dd90';
              }
              return {
                style,
              };
            },
            onRowClick: onRowClick,
          }}
        />
      )}
      <Box mt={2} />
      {partPaymentCampaigns && partPaymentCampaigns.length > 0 && (
        <MUIDataTable
          title={'Delbetalning. Betala lite per månad'}
          data={partPaymentCampaigns}
          columns={campaignColumns}
          options={{
            ...options,
            setRowProps: (row) => {
              const { campaign } = financingStore.financing;
              const style: React.CSSProperties = { cursor: 'pointer' };

              if (
                campaign &&
                row[CAMPAIGN_CODE_FIELD] === campaign.code &&
                row[CAMPAIGN_TYPE_FIELD] !== CampaignType.BNPL
              ) {
                style.backgroundColor = '#d2c8dd90';
              }
              return {
                style,
              };
            },
            onRowClick: onRowClick,
          }}
        />
      )}
      {fixedAmountCampaigns && fixedAmountCampaigns.length > 0 && (
        <>
          <Box mt={2} />
          <MUIDataTable
            title={'Delbetalning. Betala fast belopp per månad'}
            data={fixedAmountCampaigns}
            columns={campaignColumns}
            options={{
              ...options,
              setRowProps: (row) => {
                const { campaign } = financingStore.financing;
                const style: React.CSSProperties = { cursor: 'pointer' };

                if (
                  campaign &&
                  row[CAMPAIGN_CODE_FIELD] === campaign.code &&
                  row[CAMPAIGN_TYPE_FIELD] !== CampaignType.BNPL
                ) {
                  style.backgroundColor = '#d2c8dd90';
                }
                return {
                  style,
                };
              },
              onRowClick: onRowClick,
            }}
          />
        </>
      )}
      <Box mt={2} />
      {productInformation}
    </>
  );
};
