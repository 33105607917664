import ReactDOM from 'react-dom';
import './index.module.scss';
import { AppStore, getAppStore } from 'app/AppStore';
import App from '../app/App';
import { configure } from 'mobx';

configure({
  enforceActions: 'never',
});

const appStore: AppStore = getAppStore();

ReactDOM.render(<App appStore={appStore} />, document.querySelector('#app-container') as HTMLElement);
