import { PageTitle } from 'app/components/PageTitle';
import { PageComponent } from 'utils/ComponentUtils';
import { observer } from 'mobx-react';
import Helmet from 'react-helmet';
import FindPurchase from './FindPurchase';
import CompleteReturn from './CompleteReturn';
import { Route, Routes } from 'react-router-dom';
import { ReturnStore, getReturnStore } from './ReturnStore';

class ReturnPage extends PageComponent {
  returnStore: ReturnStore = getReturnStore().withAppStore(this.props.appStore);

  render() {
    return (
      <>
        <Helmet>
          <title>Återlämning</title>
        </Helmet>
        <PageTitle>Återlämning</PageTitle>
        <Routes>
          <Route path="/" element={<FindPurchase returnStore={this.returnStore} />} />
          <Route path="/complete" element={<CompleteReturn returnStore={this.returnStore} />} />
        </Routes>
      </>
    );
  }
}

export default observer(ReturnPage);
