import {
  Box,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { AppStore } from './AppStore';
import BankIdButton from './components/BankIdButton';
import { AlertContract } from './domain/Alert';
import { FinancingStore } from './features/financing/FinancingStore';

const styles = (theme: Theme) =>
  createStyles({
    stepper: {
      marginBottom: theme.spacing(1),
    },
    alertTitle: {
      color: '#ec0000',
    },
  });

interface IProps extends WithStyles<typeof styles> {
  appStore: AppStore;
  alert: AlertContract;
  financingStore: FinancingStore;
}

class AlertDialogContract extends React.Component<IProps> {
  onSignContract = () => {
    const {
      appStore: { closeAlert },
      alert,
    } = this.props;
    if (typeof alert.signContractCallback === 'function') {
      alert.signContractCallback();
    }
    closeAlert(alert);
  };

  public render() {
    const { classes } = this.props;
    const { alert } = this.props;
    return (
      !alert.hidden && (
        <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          {alert.redTitle ? (
            <DialogTitle id="alert-dialog-title" className={classes.alertTitle}>
              {alert.title}
            </DialogTitle>
          ) : (
            <DialogTitle id="alert-dialog-title">{alert.title}</DialogTitle>
          )}
          <DialogContent>
            <>
              <Grid style={{ height: 250, width: 520 }}>
                {!this.props.financingStore.showSigningButton ? (
                  <Grid>
                    <Typography>Kundens avtal håller på att skapas.</Typography>
                    <Box paddingTop="20px" justifyContent="center" display="flex">
                      <CircularProgress size="40px" />
                    </Box>
                  </Grid>
                ) : (
                  <Grid>
                    <Typography>
                      Kunden avtal är nu färdigt, öppna avtalet och be kunden signera med mobilt BankID.
                    </Typography>
                    <BankIdButton
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={async () => {
                        this.onSignContract();
                      }}
                      isSigning={false}
                    >
                      Öppna avtal
                    </BankIdButton>
                  </Grid>
                )}
              </Grid>
            </>
          </DialogContent>
        </Dialog>
      )
    );
  }
}
export default withStyles(styles)(observer(AlertDialogContract));
