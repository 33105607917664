import { Box, Button, Grid, Typography } from '@material-ui/core';
import { AppStore } from 'app/AppStore';
import { observer } from 'mobx-react';

interface ManualSigningToggleProps {
  appStore: AppStore;
}

export const ManualSigningToggle = observer((props: ManualSigningToggleProps) => {
  const { appStore } = props;

  const handleChange = async (state: boolean) => {
    await props.appStore.updateManualSigning(state);
  };

  return (
    <>
      <Grid container>
        <Grid item md={8}>
          <Typography variant="h6">Manuell Signering</Typography>
          <Typography>
            Om det är problem men den digitala signeringen så kan en manuell signeringsrutin aktiveras.
            Kontrakten kommer då behöva skrivas ut på butikens skrivare och undertecknas av kund i samband med
            att kunden legitimerar sig med giltig id-handling. Notera att om du aktiverar den manuella
            signeringen kommer det endast fungera att signera manuell och att rutinen aktiveras för samtliga
            butiker och kedjor.
          </Typography>
        </Grid>
      </Grid>
      <Box mb={2} />
      <Grid item md={6}>
        <Grid container>
          <Grid item md={6}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => handleChange(true)}
              disabled={appStore.isManualSigning === undefined || Boolean(appStore.isManualSigning)}
            >
              AKTIVERA MANUELL SIGNERING
            </Button>
          </Grid>
          <Grid item md={6}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => handleChange(false)}
              disabled={Boolean(!appStore.isManualSigning)}
            >
              AVAKTIVERA MANUELL SIGNERING
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});
