import { Customer, DecisionCode, EmploymentType } from 'app/domain';
import type { Application, Campaign, ApplicationUpdateResult } from 'app/domain';
import ReasonCode from 'app/domain/ReasonCode';
import { makeAutoObservable } from 'mobx';
import SalesScoringType from 'app/domain/SalesScoringType';

// a Financing instance reprents the
// financing process for a specific customer

export class Financing {
  customer: {
    name?: string;
    socialSecurityNumber: string;
    address?: string;
    city?: string;
    zipCode?: string;
    employmentType?: string;
    employer?: string;
    emailAddress?: string;
    phone?: string;
    inquiryId?: string;
  };

  employment: {
    type?: EmploymentType;
    fromDate?: string;
    toDate?: string;
  };

  application: {
    applicationId?: string;
    applyDate?: string;
    decisionCode?: DecisionCode;
    reasonCode?: ReasonCode;

    purchaseAmount?: number;
    cardNumber?: string;
    approvedAmount?: number;
    possibleCreditLimit?: number;
    initialFee?: string;
    interest?: string;
    interestFreePeriod?: string;
    paymentFreePeriod?: string;
    referenceNumber?: string;
    statementFee?: string;
    employmentType?: string;
    campaignCode?: string;
    purchaseId?: string;
    ocr?: string;

    accountCreationDate?: string;
    availableCredit?: number;
    totalCreditLimit?: number;
    salesScoringType?: SalesScoringType;
    preApprovedLimit?: number;
    maxCreditLimit?: number;
    maxPurchaseAmount?: number;
    emailAddress?: string;
    availableLimit?: number;
  };

  revolvement: {
    authorizationAmount: number;
    authorizationNumber: string;
    cardNumber: string;
    campaignNumber?: string;
  };

  purchaseAmount?: number = undefined;
  campaign?: Campaign = undefined;

  constructor() {
    makeAutoObservable(this);

    this.customer = { socialSecurityNumber: '' };
    this.employment = {};
    this.application = {};
    this.revolvement = {
      authorizationAmount: 0,
      authorizationNumber: '',
      cardNumber: '',
    };
  }

  setCampaign(campaign?: Campaign) {
    this.campaign = campaign;
  }

  updateWithCustomer(customer: Customer) {
    this.customer = {
      name: customer.name,
      address: customer.address,
      city: customer.city,
      zipCode: customer.zipCode,
      socialSecurityNumber: customer.socialSecurityNumber,
      phone: customer.phone,
      employmentType: customer.employmentType,
      employer: customer.employer,
      emailAddress: customer.email,
      inquiryId: customer.inquiryId,
    };
  }

  updateWithApplication(app: Application) {
    this.customer = {
      ...this.customer,
      name: app.name || this.customer.name,
      address: app.address || this.customer.address,
      city: app.city || this.customer.city,
      zipCode: app.zipCode || this.customer.zipCode,
      phone: app.phone || this.customer.phone,
      emailAddress: app.emailAddress || this.customer.emailAddress,
      socialSecurityNumber: app.socialSecurityNumber || this.customer.socialSecurityNumber,
    };

    this.application = {
      applicationId: app.applicationId,

      applyDate: app.applyDate || this.application.applyDate,
      decisionCode: app.decisionCode || this.application.decisionCode,
      reasonCode: app.reasonCode || this.application.reasonCode,

      purchaseAmount: app.purchaseAmount || this.application.purchaseAmount,
      cardNumber: app.cardNumber,
      approvedAmount: app.approvedAmount,
      possibleCreditLimit: app.possibleCreditLimit,
      initialFee: app.initialFee,
      interest: app.interest,
      interestFreePeriod: app.interestFreePeriod,
      paymentFreePeriod: app.paymentFreePeriod,
      referenceNumber: app.referenceNumber,
      statementFee: app.statementFee,
      employmentType: app.employmentType,
      campaignCode: app.campaignCode,
      purchaseId: app.purchaseId,
      ocr: app.ocr,
      accountCreationDate: app.accountCreationDate,
      availableCredit: app.availableCredit,
      salesScoringType: app.salesScoringType,
      totalCreditLimit: app.totalCreditLimit,
      preApprovedLimit: app.preApprovedLimit,
      maxCreditLimit: app.maxCreditLimit,
      maxPurchaseAmount: app.maxPurchaseAmount,
      availableLimit: app.availableLimit,
      emailAddress: app.emailAddress,
    };

    this.campaign = {
      ...this.campaign,
      ...app.campaign,
    };
  }

  applyApplicationUpdate(app: ApplicationUpdateResult) {
    this.application.approvedAmount = app.approvedAmount;
    this.application.availableLimit = app.availableLimit;
    this.application.cardNumber = app.cardNumber;
    this.application.initialFee = app.initialFee;
    this.application.interest = app.interest;
    this.application.interestFreePeriod = app.interestFreePeriod;
    this.application.paymentFreePeriod = app.paymentFreePeriod;
    this.application.maxCreditLimit = app.maxCreditLimit;
    this.application.maxPurchaseAmount = app.maxPurchaseAmount;
    this.application.possibleCreditLimit = app.possibleCreditLimit;
    this.application.referenceNumber = app.referenceNumber;
    this.application.statementFee = app.statementFee;

    this.application.decisionCode = app.decisionCode;
    this.application.reasonCode = app.reasonCode;
    this.application.purchaseAmount = app.purchaseAmount;
  }
}
