import { AppStore } from 'app/AppStore';
import { Navigate, Outlet } from 'react-router';

interface AuthorizeAdminProps {
  appStore: AppStore;
}

export const AuthorizeAdmin = (props: AuthorizeAdminProps) => {
  const { appStore } = props;

  return appStore.currentStore?.isAdmin ? <Outlet /> : <Navigate to="/financing" replace />;
};
