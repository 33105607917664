import { makeAutoObservable } from 'mobx';
import { AppStore } from 'app/AppStore';

class ProductsStore {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  appStore: AppStore = undefined!;

  withAppStore = (appStore: AppStore) => {
    this.appStore = appStore;
    this.appStore.resetState();
    return this;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

const productsStore = new ProductsStore();
function getProductsStore() {
  return productsStore;
}

export { ProductsStore, getProductsStore };
