export const RepaymentSource = {
  Salary: {
    id: 'Salary',
    name: 'Lön',
  },
  Pension: {
    id: 'Pension',
    name: 'Pension',
  },
  Savings: {
    id: 'Savings',
    name: 'Sparande',
  },
  SocialBenefits: {
    id: 'SocialBenefits',
    name: 'Försörjningsstöd',
  },
  EducationalSupport: {
    id: 'EducationalSupport',
    name: 'Studiestöd',
  },
  CommercialIncome: {
    id: 'CommercialIncome',
    name: 'Intäkter från näringsverksamhet inklusive hyresintäkter',
  },
  SaleOfRealEstate: {
    id: 'SaleOfRealEstate',
    name: 'Vinst från försäljning av fastighet',
  },
  SaleOfMovables: {
    id: 'SaleOfMovables',
    name: 'Vinst från försäljning av lös egendom (bil, båt, etc.)',
  },
  InheritanceGifts: {
    id: 'InheritanceGifts',
    name: 'Arv/gåvor',
  },
  YieldInvestments: {
    id: 'YieldInvestments',
    name: 'Vinst från investeringar',
  },
  ShareDividend: {
    id: 'ShareDividend',
    name: 'Aktieutdelning eller liknande vinst från näringsverksamhet',
  },
  Compensation: {
    id: 'Compensation',
    name: 'Annan typ av ersättning (t.ex. från försäkring, avgångsvederlag eller liknande)',
  },
  Gambling: {
    id: 'Gambling',
    name: 'Vinster från spel/lotterier',
  },
  Other: {
    id: 'Other',
    name: 'Annat (Kunden måste ange)',
  },
};

export interface IRepaymentSource {
  id: string;
  name: string;
}

export const repaymentSources: IRepaymentSource[] = [
  RepaymentSource.Salary,
  RepaymentSource.Pension,
  RepaymentSource.Savings,
  RepaymentSource.SocialBenefits,
  RepaymentSource.EducationalSupport,
  RepaymentSource.CommercialIncome,
  RepaymentSource.SaleOfRealEstate,
  RepaymentSource.SaleOfMovables,
  RepaymentSource.InheritanceGifts,
  RepaymentSource.YieldInvestments,
  RepaymentSource.ShareDividend,
  RepaymentSource.Compensation,
  RepaymentSource.Gambling,
  RepaymentSource.Other,
];
