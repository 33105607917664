import { observer } from 'mobx-react';
import * as React from 'react';
import BankIdButton from 'app/components/BankIdButton';
import { FinancingStore } from '../../FinancingStore';
import { Grid, List, ListItem, Box, CircularProgress, Checkbox, Typography } from '@material-ui/core';
import { Alert } from 'app/domain/Alert';

interface IProps {
  financingStore: FinancingStore;
  isLoading: boolean;
}

interface IState {
  items: {
    checked: boolean;
    text: string;
    bold?: boolean;
  }[];
}

class ApproveSignatureButton extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      items: [
        { checked: false, text: 'Är avtalet utskrivet i två exemplar?' },
        { checked: false, text: 'Är kunden legitimerad med giltig id-handling?' },
        {
          checked: false,
          text: 'Är typ av id-handling angivet samt id-handlingens nummer antecknat på avtalet?',
        },
        { checked: false, bold: true, text: 'Har kunden signerat avtalet?' },
      ],
    };
  }

  submitApplication = async () => {
    const showSigningLoader: Alert = {
      title: 'Manuell signering',
      hideAccept: true,
      hideCancel: true,
      content: (
        <>
          <Typography>Vänligen vänta tills signeringen är slutförd.</Typography>
          <Box mb={3} />
          <Grid style={{ height: 100, width: 520 }}>
            <Box paddingTop="20px" justifyContent="center" display="flex">
              <CircularProgress size="40px" />
            </Box>
          </Grid>
        </>
      ),
    };

    this.props.financingStore.appStore.showAlert(showSigningLoader);

    const response = await this.props.financingStore.updateApplication({ manuallySigned: true });

    if (response !== false) {
      this.props.financingStore.appStore.router.history.push(
        `/financing/application/${this.props.financingStore.financing.application.applicationId}`,
        { keepState: true }
      );

      await this.props.financingStore.loadApplication(
        this.props.financingStore.financing.application.applicationId
      );
    }

    this.props.financingStore.appStore.closeAlert(showSigningLoader);
    this.handleResetItems();
  };

  handleCheckItem = (index: number) => {
    const newItems = [...this.state.items];
    newItems[index].checked = !newItems[index].checked;

    this.setState({ items: newItems });
    this.props.financingStore.appStore.updateAlert(this.getSigningConfirmation());
  };

  handleResetItems = () => {
    this.setState((prev) => {
      prev.items.forEach((item) => {
        item.checked = false;
      });

      return prev;
    });
  };

  getSigningConfirmation = () => {
    const config: Alert = {
      title: 'Har kunden skrivit under avtalet?',
      acceptCallback: this.submitApplication,
      cancelCallback: this.handleResetItems,
      acceptText: 'Fortsätt',
      acceptDisabled: this.state.items.some((x) => !x.checked),
      content: (
        <>
          <Typography>
            Innan du fortsätter verifiera att du genomfört handlingarna genom att kryssa för respektive
            åtgärd:
          </Typography>
          <Box mb={2} />
          <Grid style={{ height: 220, width: 540 }}>
            <List>
              {this.state.items.map((item, index) => (
                <ListItem key={index} style={{ padding: 0 }}>
                  <Checkbox checked={item.checked} onChange={() => this.handleCheckItem(index)} />
                  <Typography variant={item.bold ? 'subtitle1' : 'body1'}>{item.text}</Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
        </>
      ),
    };

    return config;
  };

  render() {
    return (
      <BankIdButton
        fullWidth
        variant="contained"
        color="primary"
        disabled={this.props.isLoading}
        onClick={() => this.props.financingStore.appStore.showAlert(this.getSigningConfirmation())}
        isSigning={false}
      >
        KUND HAR SIGNERAT AVTAL, FORTSÄTT
      </BankIdButton>
    );
  }
}

export default observer(ApproveSignatureButton);
