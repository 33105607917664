import { EmploymentType } from 'app/domain';
import { RepaymentSource } from 'app/domain/RepaymentSource';
import { SchemaType } from 'utils/TypeUtils';
import { FormikHelpers as FormikActions } from 'formik';
import { array, boolean, number, object, string } from 'yup';
import { phoneSchema } from 'app/components/phone-field/PhoneField';
import { emailSchema } from 'app/components/phone-field/EmailField';

export type ApplySchema = SchemaType<typeof applySchema>;

export type ApplyActions = FormikActions<ApplySchema>;

const employerEmploymentTypes = [
  EmploymentType.PermanentJob.id,
  EmploymentType.TemporaryEmployed.id,
  EmploymentType.SelfEmployed.id,
];

export const applySchema = object({
  phoneNumber: phoneSchema,
  emailAddress: emailSchema,
  isCustomerAuthorized: boolean(),
  purchaseAmount: number()
    .positive('Köpbelopp kan ej vara negativt')
    .max(100000, 'Köpbelopp måste vara mindre än 100000')
    .required('Du måste ange köpbelopp'),

  monthlyIncomeBeforeTax: number()
    .min(0, 'Månadsinkomst före skatt kan ej vara negativt')
    .max(999999, 'Månadsinkomst före skatt måste vara mindre än 1000000')
    .integer()
    .when('isCustomerAuthorized', {
      is: false,
      then: number().required('Du måste ange månadsinkomst före skatt'),
    }),
  employmentTypeId: string().required('Du måste välja en anställningsform'),
  campaignCode: string().required('Du måste välja en kampanj'),
  chainId: string(),

  thirdPartyDeclaration: boolean().when('isCustomerAuthorized', {
    is: false,
    then: boolean().required().oneOf([true], 'Du måste bekräfta att du ansöker för någon annans räkning'),
  }),
  primaryIncomeRepayment: string(),
  repaymentSource: string().when('primaryIncomeRepayment', {
    is: 'no',
    then: string().required('Du måste ange var pengarna kommer ifrån'),
  }),
  repaymentSourceOther: string().when(['repaymentSource', 'primaryIncomeRepayment'], {
    is: (repaymentSource: string, primaryIncomeRepayment: string) =>
      repaymentSource === RepaymentSource.Other.id && primaryIncomeRepayment === 'no',
    then: string().required('Du måste ange var pengarna kommer ifrån'),
  }),
  citizenships: array()
    .of(
      object({
        code: string(),
        name: string(),
      })
    )
    .min(1, 'Du måste ange medborgarskap')
    .max(2, 'Får inte ha fler än 2 medborgarskap')
    .when('isCustomerAuthorized', {
      is: false,
      then: array().required(),
    }),
  politicallyExposedPerson: string().when('isCustomerAuthorized', {
    is: false,
    then: string().required(),
  }),
  employer: string().when(['employmentTypeId', 'isCustomerAuthorized'], {
    is: (employmentTypeId: string, isCustomerAuthorized: boolean) =>
      !isCustomerAuthorized && employerEmploymentTypes.includes(employmentTypeId),
    then: string().required('Du måste ange arbetsgivare'),
  }),
});
