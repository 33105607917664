import { Campaign } from 'app/domain';
import { FinancingClient } from '../FinancingClient';

export interface GetCampaignsRequest {
  chainId: string;
  purchaseAmount?: number;
}

export function GetCampaigns(client: FinancingClient, req: GetCampaignsRequest) {
  const request = {
    ...req,
  };
  return client.get<Campaign[]>(`/campaigns`, request);
}
