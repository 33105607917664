import { Button } from '@material-ui/core';
import { AppStore } from 'app/AppStore';
import type { Purchase } from 'app/domain';
import { makeAutoObservable } from 'mobx';
import { GetPurchasesRequest } from 'app/api/requests';
import { differenceInYears } from 'date-fns';
import { format } from 'date-fns';
import { ReturnStore } from './ReturnStore';

class FindPurchaseStore {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  returnStore: ReturnStore = undefined!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  appStore: AppStore = undefined!;
  ssn?: string;
  ssnIsValid = true;
  ssnError?: string;
  chainId?: string;
  purchases?: Purchase[] = undefined;
  selectedPurchase?: Purchase = undefined;
  returnAmount?: number;
  returnAmountIsValid = true;
  returnAmountError?: string;
  noResult?: string;

  constructor() {
    makeAutoObservable(this);
  }

  get chains() {
    return this.appStore.user.chains;
  }

  get tablePurchases() {
    if (!this.purchases) {
      return [];
    }

    return this.purchases.map((purchase) => [
      format(purchase.purchaseDate, 'YYYY-MM-DD HH:mm'),
      (purchase.returnableAmount ? purchase.returnableAmount : purchase.amount).toLocaleString('sv-SE') +
        ' kr',
      purchase.storeName, //TODO: change to chain name
      purchase.ocr,
      <Button
        key={purchase.transactionId}
        size="small"
        color="primary"
        variant="contained"
        onClick={() => {
          this.returnStore.completeReturn(purchase, this.ssn?.replace(/\D/g, '') ?? '', this.chainId ?? '');
        }}
      >
        Retur
      </Button>,
    ]);
  }

  withReturnStore(returnStore: ReturnStore) {
    this.returnStore = returnStore;
    this.appStore = returnStore.appStore;
    this.chainId = returnStore.appStore.user.chains[0].id;
    return this;
  }

  setSsn = (ssn: string) => {
    this.ssn = ssn;
  };

  isValidSwedishPIN = (pin: string) => {
    if (pin === undefined) {
      return false;
    }

    const pinArray = pin
      .replace(/\D/g, '') // strip out all but digits
      .split('') // convert string to array
      .reverse() // reverse order for Luhn
      .slice(0, 10); // keep only 10 digits (i.e. 1977 becomes 77)

    // verify we got 10 digits, otherwise it is invalid
    if (pinArray.length !== 10) {
      return false;
    }

    const sum = pinArray
      // convert to number
      .map((n: string) => {
        return Number(n);
      })
      // perform arithmetic and return sum
      .reduce((previous, current, index) => {
        // multiply every other number with two
        if (index % 2) {
          current *= 2;
        }
        // if larger than 10 get sum of individual digits (also n-9)
        if (current > 9) {
          current -= 9;
        }
        // sum it up
        return previous + current;
      });

    // sum must be divisible by 10
    return 0 === sum % 10;
  };

  isEighten = (ssn: string) => {
    if (ssn === undefined || ssn.length !== 12) {
      return true;
    }

    const birthDate = ssn.substring(0, 8);
    const age = differenceInYears(new Date(), birthDate);
    return age >= 18;
  };

  validateSsn = () => {
    const ssn = this.ssn?.replace(/\D/g, '') ?? '';

    if (!this.isValidSwedishPIN(ssn)) {
      this.ssnIsValid = false;
      this.ssnError = 'Personnumret du angivet är inte giltigt';
    } else if (!ssn.startsWith('19') && !ssn.startsWith('20') && !ssn.startsWith('21')) {
      this.ssnIsValid = false;
      this.ssnError = 'Personnummer måste börja med 19 eller 20';
    } else if (ssn.length !== 12) {
      this.ssnIsValid = false;
      this.ssnError = 'Personnummer måste ha 12 siffror';
    } else if (!this.isEighten(ssn)) {
      this.ssnIsValid = false;
      this.ssnError = 'Personen måste ha fyllt 18';
    } else if (ssn === '') {
      this.ssnIsValid = false;
      this.ssnError = 'Du måste ange Personnummer';
    } else {
      this.ssnIsValid = true;
    }
  };

  getPurchases = async () => {
    this.noResult = undefined;
    if (!this.ssn || !this.ssnIsValid || !this.chainId) {
      return;
    }

    const request: GetPurchasesRequest = {
      ssn: this.ssn.replace(/\D/g, ''),
      chainId: this.chainId,
    };

    const response = await this.appStore.api.getPurchases(request);

    if (response.isSuccess) {
      response.value.sort((a: Purchase, b: Purchase) => {
        return new Date(b.purchaseDate).getTime() - new Date(a.purchaseDate).getTime();
      });
      this.purchases = response.value;
    } else if (response.error?.code === 5001) {
      this.noResult = 'Det finns inga köp för detta personnummer.';
    }
  };

  clearPurchases = () => {
    this.purchases = undefined;
  };
}

const findPurchaseStore = new FindPurchaseStore();
function getFindPurchaseStore() {
  return findPurchaseStore;
}

export { FindPurchaseStore, getFindPurchaseStore };
