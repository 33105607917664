import { runInAction, makeAutoObservable } from 'mobx';

import {
  DelayedPurchaseRequest,
  PostPurchaseCLSRequest,
  SendPurchaseSmsRequest,
  PollSignatureRequest,
  InitiateSignatureRequest,
  PostponeApplicationRequest,
  ReservePurchaseRequest,
  ResendPurchaseSmsOptions,
} from 'app/api/requests';
import { AppStore } from 'app/AppStore';
import { Application, ApplicationBrief, Campaign, EmploymentType } from 'app/domain';
import * as React from 'react';
import { ApplyActions, ApplySchema } from './ApplySchema';
import { Financing } from './Financing';
import {
  IdentificationActions,
  IdentificationSchema,
} from './identification-view/parts/IdentificationSchema';
import { DecisionCode } from 'app/domain';
import ReasonCode from 'app/domain/ReasonCode';
import { sleep } from 'utils/Utils';
import { SigningStatus } from 'app/domain/SigningStatus';
import { RepaymentSource } from 'app/domain/RepaymentSource';
import { SigningMethod } from 'app/domain/SigningMethod';
import { useTracking } from 'utils/GA';
import { TrackingAction, TrackingCategory } from 'app/resources/Tracking';
import { SignFlow } from 'app/domain/SignFlow';
import { ApplicationContract } from 'app/domain/ApplicationContract';
import { AdditionalInfoActions, AdditionalInfoSchema } from './AdditionalInfoSchema';
import { featureFlags, semiSelfServiceAllowedFor } from 'utils/envUtils';
import { SendSelfCheckoutLinkRequest } from 'app/api/requests/SendSelfCheckoutLinkRequest';
import SalesScoringType from 'app/domain/SalesScoringType';
import { SemiSelfServiceSchema } from './identification-view/semi-self-service-dialog/SemiSelfServiceFormSchema';
import { Failure, Result } from 'utils/Result';
import { contractLoader } from './application-approved/parts/ContractLoader';

const dataLayer = useTracking(window.dataLayer);

export enum IdentificationSubmit {
  NewApplication,
  PendingApplications,
  RevolveCampaign,
}

export enum FinancingStep {
  Identification = 0,
  Application = 1,
  Deleted = 1,
  Finalize = 2,
  SignedAndFinalized = 3,
}

class FinancingStore {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  appStore: AppStore = undefined!;
  sideContainer?: React.MutableRefObject<null> = undefined;
  showSigningButton = false;
  signingUrl = '';
  ocrPurchaseAmount?: number = 0;
  ocrAmountIsValid = true;
  ocrAmountError?: string = undefined;
  isCustomerAuthorized = false;
  inquiryId = '';

  constructor() {
    makeAutoObservable(this);
  }

  get activeStep() {
    const { pathname } = this.appStore.router.location;
    const { application } = this.financing;

    if (pathname === '/financing') {
      return FinancingStep.Identification;
    } else if (pathname === '/financing/application') {
      return FinancingStep.Application;
    } else if (pathname.startsWith('/financing/application')) {
      const isApproved = application && application.decisionCode === DecisionCode.Approved;
      const isBankId = true;
      let isSigned = false;
      if (isApproved) {
        if (application.reasonCode === ReasonCode.AccountInactive) {
          isSigned = true;
        }

        if (application.reasonCode === ReasonCode.Activated) {
          isSigned = true;
        }
      }
      return isApproved && (isSigned || !isBankId)
        ? FinancingStep.SignedAndFinalized
        : FinancingStep.Finalize;
    } else if (pathname === '/financing/application/deleted') {
      return FinancingStep.Deleted;
    }
  }

  get getSteps() {
    const { pathname } = this.appStore.router.location;
    if (pathname.startsWith('/financing/customers/')) {
      return ['Identifiering', 'Kort', 'Transaktion', 'Byt kampanj'];
    } else if (pathname === '/financing/application/deleted') {
      return ['Ansökan borttagen'];
    } else {
      return ['Identifiering', 'Ansökan', 'Slutför'];
    }
  }

  // the financing object is shared between steps
  // and the fields are gradually filled in

  financing = new Financing();

  // identification state

  showOngoingApplications = false;
  showSemiServiceFlowPopup = false;

  ongoingApplications: ApplicationBrief[] = [];

  submitMethod = IdentificationSubmit.NewApplication;

  showChainList = false;

  existingCustomer = true;

  // application form state
  campaigns: Campaign[] = [];
  chainId = '';

  // "finished state"

  isSigningBankid = false;

  isSubmittingPurchase = false;

  signApplicationFailed = false;

  isContractGenerateFailed = false;

  startApplicationFailed = false;

  hasSubmittedPurchase = false;

  hasSignedForIncreasedAmount = false;

  keepPollingForSignature = true;

  keepPollingForContract = true;

  keepPollingForApplication = true;

  semiselfServiceUrl = '';

  isSubmitting = false;

  hasPostponedPayment = false;

  resendPurchaseSmsOptions: ResendPurchaseSmsOptions = {
    timeout: 0,
    isLoading: false,
  };

  error?: { text: string; value?: unknown } = undefined;

  pollingSignatureAlertVisible = false;

  setSideContainer = (sideContainer: React.MutableRefObject<null>) => {
    this.sideContainer = sideContainer;
  };

  resetState = () => {
    this.financing = new Financing();
    this.campaigns = [];
    this.showOngoingApplications = false;
    this.isSubmitting = false;
    this.isSigningBankid = false;
    this.isSubmittingPurchase = false;
    this.error = undefined;
    this.chainId = this.appStore.client.chainId;
    this.hasSubmittedPurchase = false;
    this.startApplicationFailed = false;
    this.inquiryId = '';
    this.resendPurchaseSmsOptions = {
      timeout: 0,
      error: undefined,
      clearErrorTimeout: undefined,
      isLoading: false,
    };
  };

  resetSubmittedPurchase() {
    this.hasSubmittedPurchase = false;
  }

  updateChainId = (chainId: string) => {
    this.chainId = chainId;
  };

  withAppStore = (appStore: AppStore) => {
    this.appStore = appStore;
    this.chainId = appStore.client.chainId;
    return this;
  };

  // identification actions
  setSubmitMethod = (submitMethod: IdentificationSubmit) => {
    this.submitMethod = submitMethod;
  };

  setSignedForIncreasedAmount = (increasedAmount: boolean) => {
    this.hasSignedForIncreasedAmount = increasedAmount;
  };

  submitIdentificationForm = (form: IdentificationSchema, actions: IdentificationActions) => {
    this.hasPostponedPayment = false;
    this.showSemiServiceFlowPopup = false;
    this.error = undefined;
    const method = this.submitMethod;
    this.signApplicationFailed = false;

    if (method === IdentificationSubmit.NewApplication) {
      dataLayer.pushInteraction(TrackingCategory.FinancingApplication, TrackingAction.NewApplication);
      this.beginApplication(form, actions);
    }

    if (method === IdentificationSubmit.PendingApplications) {
      dataLayer.pushInteraction(TrackingCategory.FinancingApplication, TrackingAction.ResumeApplication);
      this.findPendingApplications(form, actions);
    }
  };

  beginApplication = async (form: IdentificationSchema, actions: IdentificationActions) => {
    if (!form.purchaseAmount) return console.error('Missing purchaseAmount');

    this.resetState();
    this.error = undefined;
    this.inquiryId = '';
    form.socialSecurityNumber = form.socialSecurityNumber.replace(/\D/g, '');

    const customerReq = {
      socialSecurityNumber: form.socialSecurityNumber,
      purchaseAmount: form.purchaseAmount,
    };

    const customerResp = await this.appStore.api.getCustomer(customerReq);
    if (customerResp.isSuccess) {
      const application = await this.appStore.api.getApplication(customerResp.value.inquiryId);
      let salesScoringType = application.isSuccess ? application.value.salesScoringType : null;
      //Poll until we get salesScoringType

      while (salesScoringType === null || salesScoringType?.match(/^ *$/) !== null) {
        //Added timeout between calls to Financing/SF
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const app = await this.appStore.api.getApplication(customerResp.value.inquiryId);
        salesScoringType = app.isSuccess ? app.value.salesScoringType : null;
      }

      this.isCustomerAuthorized = salesScoringType === SalesScoringType.Authorization;

      this.financing.updateWithCustomer(customerResp.value);
      //ANSOK RETURNS WITHOUT MILLENIAL PART
      //USE THE MILLENIAL PART ENTERED BY USER;
      //HOPE WITH SALESFORCE IT MIGHT BE REMOVED...
      if (this.financing.customer.socialSecurityNumber.length == 10) {
        this.financing.customer.socialSecurityNumber =
          form.socialSecurityNumber.slice(0, 2) + this.financing.customer.socialSecurityNumber;
      }
      this.financing.purchaseAmount = form.purchaseAmount;

      const purchaseAmount = this.financing.purchaseAmount;
      const chainId = this.appStore.currentChainId;

      if (!chainId) return console.error('No chainId');

      const campaignsReq = {
        purchaseAmount,
        chainId,
      };

      const campaignsResp = await this.appStore.api.getCampaigns(campaignsReq);
      if (!campaignsResp.isSuccess) {
        if (campaignsResp.statusCode === 404) {
          actions.setFieldError(
            'purchaseAmount',
            'Köpbeloppet överstiger maxgränsen för tillgängliga kampanjer.'
          );
        } else {
          actions.setFieldError('purchaseAmount', 'Kunde inte hämta kampanjer.');
        }

        actions.setSubmitting(false);
        return;
      }

      runInAction(() => {
        this.campaigns = campaignsResp.value;
      });

      if (this.campaigns.length > 0) {
        this.setCampaign(campaignsResp.value[0].code);
      }
      actions.setSubmitting(false);

      runInAction(() => {
        this.inquiryId = customerResp.value.inquiryId;
      });

      const allowedStores = semiSelfServiceAllowedFor.split(',');
      const isAnyStoreAllowed = semiSelfServiceAllowedFor === '*';

      if (
        this.appStore.currentStoreId != null &&
        (isAnyStoreAllowed || allowedStores.indexOf(this.appStore.currentStoreId) > -1)
      ) {
        runInAction(() => {
          this.semiselfServiceUrl = import.meta.env.VITE_SEMISELFSEVICE_URL.toString()
            .replace('{0}', this.inquiryId)
            .replace('{1}', this.chainId);
          this.showSemiServiceFlowPopup = true;
        });
        return;
      } else {
        this.appStore.router.push('/financing/application');
        return;
      }
    }

    this.existingCustomer = false;

    // was failure

    const { statusCode, error } = customerResp;

    if (statusCode === 404) {
      actions.setFieldError(
        'socialSecurityNumber',
        'Kunde inte hitta en kund med uppgifterna. Fyll i personnummer.'
      );
    } else if (statusCode === 409) {
      actions.setFieldError('socialSecurityNumber', 'Kunden har redan en öppen ansökan');
    } else {
      runInAction(() => {
        if (error?.detail?.includes('SocialSecurityNumber')) {
          actions.setFieldError('socialSecurityNumber', error?.detail);
        }

        this.startApplicationFailed = true;
      });
    }
    actions.setSubmitting(false);
  };

  findPendingApplications = async (form: IdentificationSchema, actions: IdentificationActions) => {
    form.socialSecurityNumber = form.socialSecurityNumber.replace(/\D/g, '');

    runInAction(() => {
      this.startApplicationFailed = false;
    });

    if (!this.appStore.currentChainId) return console.error('No chainId');

    const applicationsReq = {
      socialSecurityNumber: form.socialSecurityNumber,
      chainId: this.appStore.currentChainId,
    };

    const applicationsResp = await this.appStore.api.getCustomerApplications(applicationsReq);
    actions.setSubmitting(false);

    if (applicationsResp.isSuccess) {
      this.financing.customer.socialSecurityNumber = form.socialSecurityNumber;
      this.ongoingApplications = applicationsResp.value;
      this.signApplicationFailed = false;
      this.showChainList = false;
      this.showOngoingApplications = true;
    } else if (applicationsResp.statusCode === 404) {
      const isMultiChain = (this.appStore.user?.chains.length || 0) > 1;

      if (isMultiChain) {
        this.showChainList = true;
        actions.setFieldError(
          'socialSecurityNumber',
          'Kunde inte hitta ansökningar på det angivna personnumret. Välj en kedja i listan och försök igen.'
        );
      } else {
        actions.setFieldError(
          'socialSecurityNumber',
          'Kunde inte hitta ansökningar på det angivna personnumret.'
        );
      }
    } else {
      const { error } = applicationsResp;

      if (error?.detail?.includes('SocialSecurityNumber')) {
        actions.setFieldError('socialSecurityNumber', error.detail);
      }

      this.startApplicationFailed = true;
    }
  };

  getRevolvementCampaigns = async () => {
    const purchaseAmount = this.financing.revolvement.authorizationAmount;
    const chainId = this.chainId;
    const campaignsReq = {
      purchaseAmount,
      chainId,
    };

    const campaignsResp = await this.appStore.api.getCampaigns(campaignsReq);

    if (!campaignsResp.isSuccess) {
      return;
    }
    runInAction(() => {
      this.campaigns = campaignsResp.value;
    });
  };

  back = () => {
    this.appStore.router.goBack();
  };

  resumeApplication = (application: ApplicationBrief) => {
    this.showOngoingApplications = false;
    this.signApplicationFailed = false;
    this.ongoingApplications = [];

    this.appStore.router.push(`/financing/application/${application.applicationId}`);
  };

  closeOngoingApplicationsDialog = () => {
    this.showOngoingApplications = false;
  };

  // application form actions

  cancelApplication = () => {
    this.isSigningBankid = false;
    this.appStore.router.push('/financing');
    this.resetState();
  };

  setCampaign = (campaignCode: string) => {
    this.financing.setCampaign(this.campaigns.find((c) => c.code === campaignCode));
  };

  convertYesNoToBoolean = (value?: string) => {
    if (value === 'yes') {
      return true;
    }
    if (value === 'no') {
      return false;
    }
    return '';
  };

  submitApplicationForm = async (form: ApplySchema, actions: ApplyActions) => {
    this.error = undefined;

    dataLayer.pushInteraction(TrackingCategory.FinancingApplication, TrackingAction.CreateApplication);

    const primaryIncomeRepayment = this.convertYesNoToBoolean(form.primaryIncomeRepayment);

    const politicallyExposedPerson = this.convertYesNoToBoolean(form.politicallyExposedPerson);

    const citizenships = form.citizenships?.map((country) => country.code) ?? [];

    const employer =
      form.employmentTypeId === EmploymentType.PermanentJob.id ||
      form.employmentTypeId === EmploymentType.SelfEmployed.id ||
      form.employmentTypeId === EmploymentType.TemporaryEmployed.id
        ? form.employer
        : '';

    const repaymentSource = primaryIncomeRepayment === false ? form.repaymentSource : '';

    const repaymentSourceOther =
      repaymentSource && form.repaymentSource === RepaymentSource.Other.id ? form.repaymentSourceOther : '';

    if (!this.appStore.currentChainId || !this.financing.campaign?.code || !this.financing.customer.inquiryId)
      return console.error('No chainId');

    const applicationReq = {
      chainId: this.appStore.currentChainId,
      phone: form.phoneNumber?.replace(/\s/g, ''),
      emailAddress: form.emailAddress,
      purchaseAmount: form.purchaseAmount,
      employmentType: form.employmentTypeId,
      campaignCode: this.financing.campaign?.code,
      socialSecurityNumber: this.financing.customer.socialSecurityNumber,
      inquiryId: this.financing.customer.inquiryId,
      monthlyGrossIncome: form.monthlyIncomeBeforeTax,
      primaryIncomeRepayment: primaryIncomeRepayment,
      employer: employer,
      repaymentSource: repaymentSource,
      repaymentSourceOther: repaymentSourceOther,
      politicallyExposedPerson: politicallyExposedPerson,
      thirdPartyDeclaration: form.thirdPartyDeclaration,
      citizenships: citizenships,
    };

    const applicationResp = await this.appStore.api.createApplication(applicationReq);
    actions.setSubmitting(false);

    if (applicationResp.statusCode == 409) {
      actions.setStatus('Kunden har redan en öppen ansökan på aktuell kedja.');
      return;
    } else if (!applicationResp.isSuccess) {
      actions.setStatus('Kunde inte skapa ansökan.');
      return;
    }

    // email is missing is application response
    this.financing.customer.emailAddress = form.emailAddress;
    // post application does not include applied amount, only get application does
    this.financing.application.purchaseAmount = form.purchaseAmount;
    this.financing.updateWithApplication(applicationResp.value);

    const appId = applicationResp.value.applicationId;
    this.signApplicationFailed = false;

    if (
      applicationResp.isSuccess &&
      applicationResp.value.decisionCode === DecisionCode.Pending &&
      applicationResp.value.reasonCode === ReasonCode.AdditionalInfo
    ) {
      this.appStore.router.push(`/financing/application/${appId}/additionalinfo`);
      this.isSubmitting = false;
      return;
    }

    this.appStore.router.push(`/financing/application/${appId}`);
  };

  unsetCampaign = () => {
    // if e.g. the purchasing amount is changed
    // the selected campaign information is no longer
    // valid and needs to be requested from backend
    this.financing.campaign = undefined;
    this.campaigns = [];
  };

  // "finished actions"

  loadApplication = async (applicationId?: string) => {
    if (applicationId == null) return console.error('No Application Id');
    const applicationResp = await this.appStore.api.getApplication(applicationId);
    if (!applicationResp.isSuccess) {
      this.signApplicationFailed = true;
      this.error = { text: 'Kunde inte ladda ansökan', value: 'Kontakta bank' };

      return;
    }

    if (applicationResp.value.reasonCode === 'ContactBank') {
      this.signApplicationFailed = true;
    }
    this.financing.updateWithApplication(applicationResp.value);

    this.ocrPurchaseAmount = this.financing.application.purchaseAmount;
  };

  deleteApplication = async () => {
    const applicationId = this.financing.application.applicationId;
    if (applicationId == null) return console.error('No Application ID');

    this.isSubmitting = true;

    const ssn = this.financing.customer.socialSecurityNumber;
    this.error = undefined;

    const deleteReq = {
      applicationId: applicationId,
      socialSecurityNumber: ssn,
    };

    const deleteResp = await this.appStore.api.deleteApplication(deleteReq);
    if (deleteResp.isSuccess) {
      this.appStore.router.push('/financing/application/deleted');
    } else if (deleteResp.statusCode === 404) {
      this.error = {
        text: 'Vänligen kontrollera personnummer och försök igen, vid fortsatt problem kontakta Santander Consumer Bank.',
        value: deleteResp.error || '',
      };
    } else if (
      deleteResp.statusCode === 500 &&
      deleteResp.error?.title.includes('Error when deleting application')
    ) {
      this.error = {
        text: 'Ansökan är signerad och kan därför inte makuleras, var god och återuppta ansökan eller ladda om sidan.',
      };
    }
    this.isSubmitting = false;
    return;
  };

  updateApplicationByPurchaseId = async (args: { purchaseAmount?: number }) => {
    if (this.financing.application.applicationId == null) return console.error('No Application ID');

    const ssn = this.financing.customer.socialSecurityNumber;
    this.error = undefined;
    if (!ssn) {
      this.error = { text: 'Kan inte komplettera ansökan då personnummer saknas.' };
      return;
    }

    const updateReq = {
      applicationId: this.financing.application.applicationId,
      socialSecurityNumber: ssn,
      purchaseAmount: args.purchaseAmount,
    };

    const updateResp = await this.appStore.api.updateApplication(updateReq);

    if (!updateResp.isSuccess) {
      this.error = { text: 'Kompletteringsanrop misslyckades', value: 'Kontakta bank' };

      return;
    }

    this.financing.applyApplicationUpdate(updateResp.value);
  };

  updateApplication = async (args: { purchaseAmount?: number; manuallySigned?: boolean }) => {
    if (this.financing.application.applicationId == null) return console.error('No Application ID');

    dataLayer.pushInteraction(TrackingCategory.FinancingApplication, TrackingAction.UpdateApplication);
    const ssn = this.financing.customer.socialSecurityNumber;
    this.error = undefined;
    if (!ssn) {
      this.error = { text: 'Kan inte komplettera ansökan då personnummer saknas.' };
      return false;
    }

    const updateReq = {
      applicationId: this.financing.application.applicationId,
      socialSecurityNumber: ssn,
      creditLimit: args.purchaseAmount,
      manuallySigned: args.manuallySigned,
    };

    const updateResp = await this.appStore.api.updateApplication(updateReq);

    if (!updateResp.isSuccess) {
      this.error = { text: 'Kompletteringsanrop misslyckades', value: 'Kontakta bank' };

      return false;
    }

    this.financing.applyApplicationUpdate(updateResp.value);
  };

  setOcrPurchaseAmount = (amount: string) => {
    const ocrAmountValue: number = parseFloat(amount);
    this.ocrPurchaseAmount = ocrAmountValue;
  };

  validateOcrPurchaseAmount = () => {
    const ocrPurchaseAmount = this.ocrPurchaseAmount;
    if (ocrPurchaseAmount == null) return console.error('No purchase amount');

    if (isNaN(ocrPurchaseAmount)) {
      this.ocrPurchaseAmount = this.financing.application.purchaseAmount;
      return;
    }

    if (ocrPurchaseAmount <= 0) {
      this.ocrAmountIsValid = false;
      this.ocrAmountError = 'Beloppet måste vara större än 0';
      return;
    }

    this.ocrAmountIsValid = true;
  };

  initiateSigning = async (signingMethod: SigningMethod) => {
    const { applicationId } = this.financing.application;
    if (applicationId == null) return console.error('No Application ID');

    this.signingUrl = '';
    this.showSigningButton = false;
    this.isSigningBankid = true;
    this.error = undefined;
    this.signApplicationFailed = false;
    this.keepPollingForSignature = true;
    this.isContractGenerateFailed = false;

    const signReq = {
      applicationId: applicationId,
      chainId: this.chainId,
      sendSmsWithLink: signingMethod === SigningMethod.PHONE,
      sendEmailWithLink: signingMethod === SigningMethod.EMAIL,
      manualSign: signingMethod === SigningMethod.MANUAL,
    };

    let newTab: Window | null = null;
    if (signingMethod === SigningMethod.WEB) {
      newTab = window.open();

      newTab?.document.write(contractLoader); // inject loading screen
    }

    const transactionResp = await this.appStore.api.initiateSignature(signReq);

    if (!transactionResp.isSuccess) {
      if (
        transactionResp.statusCode === 400 &&
        transactionResp.error?.title.includes('Could not initiate signature')
      ) {
        this.pollingSignatureAlertVisible = true;
      } else {
        this.error = { text: 'Kan inte skapa kontrakt.', value: 'Vänligen kontakta banken' };
        this.isSigningBankid = false;
        this.signApplicationFailed = true;
        this.isContractGenerateFailed = true;
      }

      newTab?.close();
      return;
    }

    this.showSigningButton = true;
    this.signingUrl = transactionResp.value.signingUrl;

    if (newTab && this.signingUrl) {
      newTab.location.href = this.signingUrl;
    }
  };

  pollforSignature = async () => {
    if (this.financing.application.applicationId == null) return console.error('No Application ID');
    const pollReq: PollSignatureRequest = {
      applicationId: this.financing.application.applicationId,
      chainId: this.chainId,
    };
    this.isSigningBankid = true;

    while (this.keepPollingForSignature) {
      const pollResp = await this.appStore.api.pollSignature(pollReq);
      if (!pollResp.isSuccess) {
        switch (pollResp.statusCode) {
          case 408:
            this.error = {
              text: 'Signeringen tog för lång tid. Vänligen försök igen.',
              value: '',
            };
            this.isSigningBankid = false;
            return;
          case 400:
            this.error = {
              text: 'Signering avbruten av kund. Vänligen försök igen.',
              value: '',
            };
            this.isSigningBankid = false;
            return;
          case 404:
            this.error = {
              text: 'Kunde inte hitta ansökan. Vänligen försök igen.',
              value: '.',
            };
            this.isSigningBankid = false;
            return;
          default:
            this.error = { text: 'Kunde inte signera med BankID.', value: 'Kontakta bank' };
            this.isSigningBankid = false;
            this.signApplicationFailed = true;
            return;
        }
      }

      switch (pollResp.value.signingStatus) {
        case SigningStatus.Completed:
          this.isSigningBankid = true;
          await this.loadApplication(this.financing.application.applicationId);
          return;
        case SigningStatus.Expired:
        case SigningStatus.Rejected:
        case SigningStatus.Deleted:
          this.error = { text: 'Bank id signering kunde inte genomföras.', value: 'Kontakta bank' };
          this.isSigningBankid = false;
          return;
        default:
          if (!this.isSigningBankid) return;
          await sleep(2000);
          break;
      }
    }
  };

  pollForContract = async () => {
    if (this.financing.application.applicationId == null) return console.error('No Application ID');

    this.appStore.startLoading();
    let response: ApplicationContract | undefined = undefined;

    this.keepPollingForContract = true;
    const pollTimeout = 1500;
    let pollTime = 0;

    while (this.keepPollingForContract) {
      const pollResp = await this.appStore.api.getApplicationContract(
        this.financing.application.applicationId
      );

      if (!pollResp.isSuccess) {
        switch (pollResp.statusCode) {
          case 408:
            this.error = {
              text: 'Signeringen tog för lång tid. Vänligen försök igen.',
              value: '',
            };
            break;
          case 400:
            this.error = {
              text: 'Signering avbruten av kund. Vänligen försök igen.',
              value: '',
            };
            break;
          case 404:
            // if polling for longer than 90 seconds
            if (pollTime >= 90 * 1000) {
              this.error = {
                text: 'Signeringen tog för lång tid. Vänligen försök igen.',
                value: '',
              };
              this.keepPollingForContract = false;
              break;
            }

            pollTime += pollTimeout;
            await sleep(pollTimeout);
            continue;
          default:
            this.error = { text: 'Kunde inte signera med BankID.', value: 'Kontakta bank' };
            break;
        }
      } else {
        response = pollResp.value;
        break;
      }
    }

    this.appStore.finishedLoading();
    return response;
  };

  signWithBankId = async () => {
    const { applicationId } = this.financing.application;
    if (applicationId == null) return console.error('No Application ID');

    this.signingUrl = '';
    this.showSigningButton = false;
    this.isSigningBankid = true;
    this.error = undefined;
    this.signApplicationFailed = false;
    this.keepPollingForSignature = true;

    const signReq: InitiateSignatureRequest = {
      applicationId: applicationId,
      chainId: this.chainId,
      sendSmsWithLink: false,
      sendEmailWithLink: false,
    };

    const transactionResp = await this.appStore.api.initiateSignature(signReq);

    if (!transactionResp.isSuccess) {
      this.error = { text: 'Kunde inte signera med BankID.', value: 'Kontakta bank' };
      this.isSigningBankid = false;
      this.signApplicationFailed = true;
      return;
    }

    this.showSigningButton = true;
    this.signingUrl = transactionResp.value.signingUrl;

    const pollReq = {
      applicationId: applicationId,
      chainId: this.chainId,
    };

    while (this.keepPollingForSignature) {
      const pollResp = await this.appStore.api.pollSignature(pollReq);
      if (!pollResp.isSuccess) {
        switch (pollResp.statusCode) {
          case 408:
            this.error = {
              text: 'Signeringen tog för lång tid. Vänligen försök igen.',
              value: '',
            };
            this.isSigningBankid = false;
            return;
          case 400:
            this.error = {
              text: 'Signering avbruten av kund. Vänligen försök igen.',
              value: '',
            };
            this.isSigningBankid = false;
            return;
          case 404:
            this.error = {
              text: 'Kunde inte hitta ansökan. Vänligen försök igen.',
              value: '.',
            };
            this.isSigningBankid = false;
            return;
          default:
            this.error = { text: 'Kunde inte signera med BankID.', value: 'Kontakta bank' };
            this.isSigningBankid = false;
            this.signApplicationFailed = true;
            return;
        }
      }
      switch (pollResp.value.signingStatus) {
        case SigningStatus.Completed:
          this.isSigningBankid = true;
          await this.loadApplication(applicationId);
          return;
        case SigningStatus.Expired:
        case SigningStatus.Rejected:
        case SigningStatus.Deleted:
          this.error = { text: 'Bank id signering kunde inte genomföras.', value: 'Kontakta bank' };
          this.isSigningBankid = false;
          return;
        default:
          if (!this.isSigningBankid) return;
          await sleep(2000);
          break;
      }
    }
  };

  sendSmsWithPurchaseCode = async () => {
    const purchaseId = this.financing.application.purchaseId;
    this.isSubmittingPurchase = true;
    this.error = undefined;

    if (!purchaseId) {
      this.error = { text: 'Kunde inte ladda ansökan', value: 'Kontakta bank' };
      this.isSubmittingPurchase = false;
      return;
    }
    const amount = this.financing.application.purchaseAmount;
    const { applicationId } = this.financing.application;

    const request: SendPurchaseSmsRequest = {
      purchaseId: purchaseId,
      amount: amount,
      chainId: this.chainId,
      applicationId: applicationId ?? '',
    };

    dataLayer.pushInteraction(TrackingCategory.FinancingApplication, TrackingAction.PurchaseWithNets);
    const response = await this.appStore.api.sendPurchaseSms(request);
    if (!response.isSuccess) {
      this.isSubmittingPurchase = false;
      this.error = { text: 'SMS kunde inte skickas till kund', value: 'Försök igen' };
      return;
    }
    this.isSubmittingPurchase = false;
    this.hasSubmittedPurchase = true;
    this.resendPurchaseSmsOptions.timeout = Date.now();
  };

  resendSmsWithPurchaseCode = async () => {
    const RESEND_AFTER = 1000 * 60; // 60s

    const now = Date.now();
    const delta = now - this.resendPurchaseSmsOptions.timeout;

    if (delta >= RESEND_AFTER) {
      this.resendPurchaseSmsOptions.isLoading = true;
      this.resendPurchaseSmsOptions.error = '';

      await this.sendSmsWithPurchaseCode();
    } else {
      const timeRemaining = RESEND_AFTER - delta;
      this.resendPurchaseSmsOptions.error = `Försök igen om ${Math.round(timeRemaining / 1000)} sekunder`;
      clearTimeout(this.resendPurchaseSmsOptions.clearErrorTimeout);
      this.resendPurchaseSmsOptions.clearErrorTimeout = setTimeout(
        () => (this.resendPurchaseSmsOptions.error = ''),
        5000
      );
    }

    this.resendPurchaseSmsOptions.isLoading = false;
  };

  submitPurchaseCLS = async () => {
    this.isSubmittingPurchase = true;
    this.error = undefined;

    if (!this.ocrAmountIsValid) {
      return;
    }

    const purchaseId = this.financing.application.purchaseId ? this.financing.application.purchaseId : '';

    const request: PostPurchaseCLSRequest = {
      purchaseId: purchaseId,
      amount: this.ocrPurchaseAmount,
      chainId: this.chainId,
    };

    dataLayer.pushInteraction(TrackingCategory.FinancingApplication, TrackingAction.PurchaseWithOCR);
    const response = await this.appStore.api.postPurchaseCLS(request);

    if (!response.isSuccess) {
      this.parsePurchaseError(response);
      this.isSubmittingPurchase = false;
      return;
    }

    this.isSubmittingPurchase = false;
    this.hasSubmittedPurchase = true;
  };

  postponePurchase = async () => {
    this.error = undefined;
    this.isSubmittingPurchase = true;
    dataLayer.pushInteraction(TrackingCategory.FinancingApplication, TrackingAction.PostponePurchase);

    let response: Result<unknown>;
    if (featureFlags?.PostAuthorizeEndpointEnabled) {
      const request: ReservePurchaseRequest = {
        purchaseId: this.financing.application.purchaseId ?? '',
        chainId: this.chainId,
        amount: this.financing.application.purchaseAmount,
      };

      response = await this.appStore.api.reservePurchase(request);
    } else {
      const request: PostponeApplicationRequest = {
        applicationId: this.financing.application.applicationId ?? '',
        chainId: this.chainId,
      };

      response = await this.appStore.api.postponeApplication(request);
    }

    if (!response.isSuccess) {
      this.parsePurchaseError(response);
      this.isSubmittingPurchase = false;
      return;
    }
    this.isSubmittingPurchase = false;
    this.hasPostponedPayment = true;
    this.hasSubmittedPurchase = true;
  };

  delayedPurchase = async () => {
    this.error = undefined;

    if (!this.ocrAmountIsValid) {
      return;
    }

    const request: DelayedPurchaseRequest = {
      ssn: this.financing.customer.socialSecurityNumber,
      applicationId: this.financing.application.applicationId || '',
      purchaseId: this.financing.application.purchaseId || '',
      amount: this.ocrPurchaseAmount || 0,
      paymentSolution: this.appStore.user?.paymentMethod || '',
    };

    const response = await this.appStore.api.delayedPurchase(request);
    if (!response.isSuccess) {
      this.parsePurchaseError(response);

      return;
    }
    this.hasSubmittedPurchase = true;
    this.resendPurchaseSmsOptions.timeout = Date.now();
  };

  returnToPaymentList = () => {
    this.financing.application.applicationId = undefined;
    this.appStore.router.history.push(`/future-payments`, { keepState: true });
  };

  submitAdditionalInfoForm = async (form: AdditionalInfoSchema, actions: AdditionalInfoActions) => {
    this.isSubmitting = true;
    this.error = undefined;

    dataLayer.pushInteraction(TrackingCategory.FinancingApplication, TrackingAction.UpdateAdditionalInfo);

    const applicationId = this.financing.application.applicationId ?? '';
    const additionalInfoReq = {
      applicationId: applicationId,
      housingCostPerMonth: form.housingCostPerMonth,
      accommodationType: form.accommodationTypeId,
      numberOfChildren: parseInt(form.numberOfChildren, 10),
    };

    const response = await this.appStore.api.updateAdditionalInfo(additionalInfoReq);

    if (!response.isSuccess) {
      this.error = { text: 'Kompletteringsanrop misslyckades', value: 'Kontakta bank' };
      actions.setStatus('Kompletteringsanrop misslyckades.');
    }

    const applicationData = await this.pollForApplication();
    actions.setSubmitting(false);
    this.isSubmitting = false;

    if (!applicationData) return;

    this.financing.updateWithApplication(applicationData);
    this.appStore.router.push(`/financing/application/${applicationId}`);
  };

  pollForApplication = async () => {
    if (this.financing.application.applicationId == null) return console.error('No Application ID');

    this.appStore.startLoading();
    let response: Application | undefined = undefined;

    this.keepPollingForApplication = true;
    const pollTimeout = 1000;
    let pollTime = 0;

    while (this.keepPollingForApplication) {
      const pollResp = await this.appStore.api.getApplication(this.financing.application.applicationId);

      if (pollResp.isSuccess) {
        const isProcessing =
          pollResp.value.decisionCode === DecisionCode.Pending &&
          pollResp.value.reasonCode === ReasonCode.Processing;
        //Poll until we get reason code different than processing
        if (isProcessing) {
          if (pollTime >= 60 * 1000) {
            this.error = {
              text: 'Lastningen tog för lång tid. Vänligen försök igen.',
              value: '',
            };
            response = pollResp.value;
            this.keepPollingForApplication = false;
            break;
          }

          pollTime += pollTimeout;
          await sleep(pollTimeout);
          continue;
        } else {
          response = pollResp.value;
          break;
        }
      } else {
        this.error = { text: 'Kunde inte ladda ansökan..', value: 'Kontakta bank' };
        break;
      }
    }

    this.appStore.finishedLoading();
    return response;
  };

  submitSelfService = async (form: SemiSelfServiceSchema) => {
    if (form.flowMethod === SignFlow.NOS) {
      this.appStore.router.push('/financing/application');
      this.showSemiServiceFlowPopup = false;
      return;
    }

    const request: SendSelfCheckoutLinkRequest = { inquiryId: this.inquiryId };

    if (form.flowMethod === SignFlow.PHONE) {
      const phoneWithoutSpaces = form.phoneNumber?.replace(/\s/g, '');

      this.financing.customer.phone = phoneWithoutSpaces;
      request.phoneNumber = phoneWithoutSpaces;
    } else if (form.flowMethod === SignFlow.EMAIL) {
      this.financing.customer.emailAddress = form.emailAddress;
      request.emailAddress = form.emailAddress;
    }

    await this.appStore.api.sendSelfCheckoutLink(request);

    this.appStore.router.push('/');
    this.showSemiServiceFlowPopup = false;
  };

  private parsePurchaseError(response: Failure) {
    if (response.error?.detail?.includes('Insufficient funds')) {
      this.error = { text: 'Transaktion kan inte genomföras, köpesumma överstiger tillgänglig kredit.' };
    } else if (response.error?.code === 1005) {
      this.error = { text: 'Transaktion kan inte genomföras, kontot är stängt.' };
    } else {
      this.error = { text: 'Kunde inte genomföra köpet med OCR nummer', value: 'Kontakta bank' };
    }
  }
}

const financingState = new FinancingStore();

function getFinancingStore() {
  return financingState;
}

export { FinancingStore, getFinancingStore };
