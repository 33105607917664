import { makeAutoObservable } from 'mobx';
import { Typography } from '@material-ui/core';
import {
  CreateUserRequest,
  DeleteUserRequest,
  GetUserRequest,
  ResetUserPasswordRequest,
  UpdateUserRequest,
} from 'app/api/requests/UserRequests';
import { AppStore } from 'app/AppStore';
import { Alert } from 'app/domain/Alert';
import type { StoreUser } from 'app/domain/StoreUser';
import { FormikHelpers as FormikActions } from 'formik';
import * as React from 'react';
import { EditUserSchema } from './EditUserSchema';

class EditUserStore {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  appStore: AppStore = undefined!;
  editUser?: StoreUser;
  error?: string = undefined;

  withAppStore = (appStore: AppStore) => {
    this.appStore = appStore;
    return this;
  };

  resetState = () => {
    this.editUser = undefined;
  };

  constructor() {
    makeAutoObservable(this);
  }

  get currentUserStores() {
    return this.appStore.user.stores.map((userStore) => ({ ...userStore, isAdmin: false }));
  }

  get currentUserStoresWhereAdmin() {
    return this.appStore.user.stores
      .filter((s) => s.isAdmin === true)
      .map((userStore) => ({ ...userStore, isAdmin: false }));
  }

  getUser = async (userId: string) => {
    const request: GetUserRequest = {
      userId,
    };
    const getUserResponse = await this.appStore.api.getUser(request);
    if (getUserResponse.isSuccess) {
      this.editUser = getUserResponse.value;
      return;
    }
    const showNoUserFoundAlert: Alert = {
      title: 'Användaren kunde inte hämtas.',
      hideCancel: true,
      content: <Typography></Typography>,
      acceptCallback: () => {
        this.appStore.router.push(`/admin/find-user`);
      },
    };
    this.appStore.showAlert(showNoUserFoundAlert);
  };

  createUser = async (editUserSchema: EditUserSchema, actions: FormikActions<EditUserSchema>) => {
    this.error = '';

    const createUserCall = async () => {
      const userResponse = await this.appStore.api.createUser(request);

      if (userResponse.isSuccess) {
        const showPasswordAlert: Alert = {
          title: 'Användaren har skapats',
          content: (
            <Typography>
              Ett tillfälligt lösenord har skickats till användarens email: <strong>{request.email}</strong>
            </Typography>
          ),
          acceptCallback: () => {
            this.appStore.router.push(`/admin/edit-user/${userResponse.value.userId}`);
          },
        };
        this.appStore.showAlert(showPasswordAlert);
      } else if (!userResponse.isSuccess && userResponse.statusCode === 409) {
        this.error = 'Kan inte skapa användare, användarnamnet används redan inom denna butik.';
      } else {
        this.error = 'Kunde inte skapa användare';
      }
    };

    const request: CreateUserRequest = {
      ...(editUserSchema as CreateUserRequest),
    };
    this.validateEmail(request, actions, createUserCall);
  };

  updateUser = async (editUserSchema: EditUserSchema, actions: FormikActions<EditUserSchema>) => {
    if (!editUserSchema.userId) {
      console.error('Missing userId');
      return;
    }

    const updateUserCall = async () => {
      const userResponse = await this.appStore.api.updateUser(request);
      actions.setSubmitting(false);

      if (userResponse.isSuccess) {
        const showUserUpdatedAlert: Alert = {
          title: 'Användaren har uppdaterats',
          content: <Typography></Typography>,
          hideCancel: true,
          acceptCallback: () => {
            actions.setValues(userResponse.value);
            this.editUser = userResponse.value;
          },
        };
        this.appStore.showAlert(showUserUpdatedAlert);
      } else {
        this.error = 'Kunde inte uppdatera användare';
      }
    };

    const request: UpdateUserRequest = {
      ...(editUserSchema as UpdateUserRequest),
    };

    const getUserRequest: GetUserRequest = {
      userId: editUserSchema.userId,
    };

    const getUserResponse = await this.appStore.api.getUser(getUserRequest);
    if (getUserResponse.isSuccess && getUserResponse.value.email === editUserSchema.email) {
      updateUserCall();
    } else {
      this.validateEmail(request, actions, updateUserCall);
    }
  };

  validateEmail = async (
    request: CreateUserRequest | UpdateUserRequest,
    actions: FormikActions<EditUserSchema>,
    onSuccess: () => void
  ) => {
    const emailResponse = await this.appStore.api.validateEmail(request);
    actions.setSubmitting(false);

    if (emailResponse.isSuccess) {
      if (emailResponse.value) {
        onSuccess();
      } else {
        const showPasswordAlert: Alert = {
          title: 'E-postadressen används redan',
          content: (
            <Typography>
              E-postadressen <strong>{request.email}</strong> används redan av en annan användare för denna
              butik. Om du fortsätter kommer de användare som delar samma e-postadress inte kunna använda
              återställ lösenords funktionen.
            </Typography>
          ),
          acceptText: 'Fortsätt',
          cancelText: 'Avbryt',
          acceptCallback: onSuccess,
        };
        this.appStore.showAlert(showPasswordAlert);
      }
    } else {
      this.error = 'Kunde inte skapa användare';
    }
  };

  deleteUser = async () => {
    const request: DeleteUserRequest = {
      userId: this.editUser?.userId ?? '',
    };
    const showDeleteAlert: Alert = {
      title: 'Är du säker på att du vill ta bort användaren?',
      content: (
        <Typography>
          Användaren kommer tas bort från samtliga butiker och kommer ej kunna logga in.
        </Typography>
      ),
      acceptCallback: async () => {
        const userResponse = await this.appStore.api.deleteUser(request);
        if (userResponse.isSuccess) {
          this.appStore.router.push(`/admin/find-user`);
        } else {
          this.error = 'Kunde inte ta bort användare';
        }
      },
    };
    this.appStore.showAlert(showDeleteAlert);
  };

  resetUserPassword = () => {
    const resetUserPasswordRequest: ResetUserPasswordRequest = {
      userId: this.editUser?.userId ?? '',
    };

    const showPasswordAlert: Alert = {
      title: 'Är du säker?',
      content: (
        <Typography>
          Ett tillfälligt lösenord skickas till användarens email: {this.editUser?.email}
        </Typography>
      ),
      acceptCallback: async () => {
        const resetUserPasswordResponse = await this.appStore.api.resetUserPassword(resetUserPasswordRequest);
        if (resetUserPasswordResponse.isSuccess) {
          const passwordAlert: Alert = {
            title: 'Lösenordet har skickats!',
            hideCancel: true,
            content: (
              <Typography>
                Ett tillfälligt lösenord har skickats till användarens email:{' '}
                <strong>{this.editUser?.email}</strong>
              </Typography>
            ),
            acceptCallback: () => null,
          };
          this.appStore.showAlert(passwordAlert);
        }
      },
    };
    this.appStore.showAlert(showPasswordAlert);
  };

  submitEditUser = (editUserSchema: EditUserSchema, actions: FormikActions<EditUserSchema>) => {
    if (editUserSchema.userId) {
      this.updateUser(editUserSchema, actions);
    } else {
      this.createUser(editUserSchema, actions);
    }
  };
}

const editUserStore = new EditUserStore();
function getEditUserStore() {
  return editUserStore;
}

export { EditUserStore, getEditUserStore };
