import { observer } from 'mobx-react';
import * as React from 'react';
import { pdf } from '@react-pdf/renderer';
import print from 'print-js';
import PDFMerger from 'pdf-merger-js/browser';
import { FinancingStore } from '../../FinancingStore';
import { useTracking } from 'utils/GA';
import { TrackingAction, TrackingCategory } from 'app/resources/Tracking';
import { Contract } from '../contracts/Contract';
import { Button } from '@material-ui/core';
import { SigningMethod } from 'app/domain/SigningMethod';

const DYNAMIC_PAGES = ['SESF200', 'SESF201', 'SESF202', 'SESF203', 'SESF205', 'SESF219', 'SESF221'];
const MEDIA_MARKT_PAGES = ['SESF204'];

const dataLayer = useTracking(window.dataLayer);

interface IProps {
  financingStore: FinancingStore;
  isLoading: boolean;
  onUpdateLoading: (loading: boolean) => void;
}

class PrintDocumentsButton extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  async getDynamicPage(id: string) {
    const importedPage = await import(`../contracts/${id}.tsx`);
    if ('default' in importedPage) {
      return importedPage.default;
    } else {
      throw new Error('No default export');
    }
  }

  async getStaticPage(id: string, isMediaMarkt: boolean) {
    let documentName = id;
    if (isMediaMarkt && MEDIA_MARKT_PAGES.includes(id)) {
      documentName += '_MM';
    }

    const extraPdf = await fetch(`/resources/pdf/${documentName}.pdf`);
    const file = await extraPdf.blob();

    if (file.type === 'application/pdf') {
      return file;
    } else {
      throw new Error('File is not a PDF');
    }
  }

  printDocuments = async () => {
    try {
      this.props.onUpdateLoading(true);

      dataLayer.pushInteraction(TrackingCategory.FinancingApplication, TrackingAction.GenerateAgreement);

      await this.props.financingStore.initiateSigning(SigningMethod.MANUAL);
      if (this.props.financingStore.isContractGenerateFailed) {
        return;
      }

      const contractData = await this.props.financingStore.pollForContract();
      if (!contractData) return;

      const data = contractData.documentPackages.documentPackage;
      const dynamicPagesToAdd = [];
      const staticPagesToAdd = [];

      const templates = data.templates.template.map((x) => x.id);

      // Some documents have a normal and MM versions
      const isMediaMarkt = data.contracts.contract.brand === 'MEDIAMARKT';

      for (const document of templates) {
        try {
          if (DYNAMIC_PAGES.includes(document)) {
            dynamicPagesToAdd.push(await this.getDynamicPage(document));
          } else {
            staticPagesToAdd.push(await this.getStaticPage(document, isMediaMarkt));
          }
        } catch (e) {
          console.log('Missing page', document);
          console.log(e);
        }
      }

      const blob = await pdf(<Contract data={data} pages={dynamicPagesToAdd} />).toBlob();
      const contract = URL.createObjectURL(blob);

      const merger = new PDFMerger();

      // Add dynamic pages
      await merger.add(contract);

      // Add static pages
      for (const staticPage of staticPagesToAdd) {
        await merger.add(staticPage);
      }

      const mergedPdf = await merger.saveAsBlob();

      const url = URL.createObjectURL(mergedPdf);
      print(url);
    } finally {
      this.props.onUpdateLoading(false);
    }
  };

  render() {
    return (
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        onClick={this.printDocuments}
        disabled={this.props.isLoading}
      >
        SKRIV UT
      </Button>
    );
  }
}

export default observer(PrintDocumentsButton);
