import { Form, FormikProps } from 'formik';
import * as React from 'react';

import { Button, Grid } from '@material-ui/core';

import { EmploymentType } from 'app/domain';
import { ErrorText } from 'ui';
import { ApplySchema } from './ApplySchema';
import { FinancingStore } from './FinancingStore';
import { PersonalFormFields } from './PersonalFormFields';
import { RepaymentSource } from 'app/domain/RepaymentSource';
import { CampaignFormFields } from './CampaignFormFields';
import { useTracking } from 'utils/GA';
import { TrackingAction, TrackingCategory } from 'app/resources/Tracking';

const dataLayer = useTracking(window.dataLayer);

type IProps = {
  financingStore: FinancingStore;
  productInformation: React.ReactNode;
  campaigns: React.ReactText[][];
  formikProps: FormikProps<ApplySchema>;
};

export class ApplyForm extends React.Component<IProps> {
  state = { step: 1 };

  render() {
    const { financingStore, productInformation, campaigns, formikProps } = this.props;
    const { values, submitForm, isSubmitting, status } = formikProps;

    const showEmployerField =
      values.employmentTypeId === EmploymentType.PermanentJob.id ||
      values.employmentTypeId === EmploymentType.SelfEmployed.id ||
      values.employmentTypeId === EmploymentType.TemporaryEmployed.id;
    const showRepaymentSourceField = values.primaryIncomeRepayment === 'no';
    const showRepaymentSourceOtherField =
      showRepaymentSourceField && values.repaymentSource === RepaymentSource.Other.id;

    const stepForward = () => {
      dataLayer.pushInteraction(
        TrackingCategory.FinancingApplication,
        TrackingAction.IdentificationStep1Continue
      );
      if (this.state.step < 2) {
        this.setState({ step: this.state.step + 1 });
      }
    };

    const stepBack = () => {
      dataLayer.pushInteraction(
        TrackingCategory.FinancingApplication,
        TrackingAction.IdentificationStep1Cancel
      );
      if (this.state.step > 1) {
        this.setState({ step: this.state.step - 1 });
      }
    };

    const isLastStep = this.state.step === 2;
    const isFirstStep = this.state.step === 1;

    const renderFormFields = () => {
      switch (this.state.step) {
        case 1:
          return (
            <CampaignFormFields
              campaigns={campaigns}
              financingStore={financingStore}
              productInformation={productInformation}
              {...formikProps}
            />
          );
        case 2:
          return (
            <PersonalFormFields
              financingStore={financingStore}
              showEmployerField={showEmployerField}
              showRepaymentSourceField={showRepaymentSourceField}
              showRepaymentSourceOtherField={showRepaymentSourceOtherField}
              {...formikProps}
            />
          );
        default:
          return <div></div>;
      }
    };

    return (
      <Form>
        {status && <ErrorText>{status}</ErrorText>}
        {renderFormFields()}
        <Grid container justifyContent="space-between">
          <Grid item sm={4}>
            {isFirstStep ? (
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => {
                  dataLayer.pushInteraction(
                    TrackingCategory.FinancingApplication,
                    TrackingAction.CancelApplication
                  );
                  financingStore.cancelApplication();
                }}
              >
                Avbryt
              </Button>
            ) : (
              <Button fullWidth variant="outlined" color="primary" onClick={stepBack} disabled={isSubmitting}>
                Tillbaka
              </Button>
            )}
          </Grid>
          <Grid item sm={4}>
            {isLastStep ? (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={
                  Boolean(formikProps.errors.phoneNumber) ||
                  Boolean(formikProps.errors.emailAddress) ||
                  isSubmitting ||
                  (!financingStore.isCustomerAuthorized && !formikProps.isValid)
                }
                onClick={submitForm}
              >
                Ansök
              </Button>
            ) : (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={values.campaignCode === ''}
                onClick={stepForward}
              >
                Fortsätt
              </Button>
            )}
          </Grid>
        </Grid>
      </Form>
    );
  }
}
