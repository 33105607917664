import { Campaign } from 'app/domain/Campaign';
import { CampaignType } from 'app/domain/CampaignType';

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const idleTimer = (limit: number, callback: () => void) => {
  let time: ReturnType<typeof setTimeout>;
  window.onload = resetTimer;
  document.onclick = resetTimer;
  document.onkeydown = resetTimer;
  document.oncontextmenu = resetTimer;
  resetTimer();

  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(callback, limit);
  }
};

function numberWithSpaces(value?: string | number, suffix?: string) {
  if (value == null) return '0';

  const parts = value.toString().split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  let output = parts[0];
  if (Number(parts[1]) !== 0) output = parts.join(',');

  return `${output} ${suffix}`;
}

function getCampaignCostDesc(campaign: Campaign) {
  if (campaign.campaignType === CampaignType.BNPL) {
    return `Betala i ${getMonthName(campaign.calculatedInterestFreeDate, 'sv-SE')}`;
  }
  if (campaign.campaignType === CampaignType.FixedAmount) {
    return campaign.cost.monthlyCost + ' kr i ' + campaign.cost.calculatedMonths + ' månader';
  }
  return campaign.cost.monthlyCost + ' kr i månaden';
}

function getMonthName(date: string | undefined, locale: string) {
  if (!date) return;
  const formatter = new Intl.DateTimeFormat(locale, { month: 'long' });
  try {
    return formatter.format(new Date(date));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export { sleep, idleTimer, numberWithSpaces, getCampaignCostDesc, getMonthName };
