import { observer } from 'mobx-react';
import * as React from 'react';
import { FormControlLabel, Grid, Radio, Typography } from '@material-ui/core';
import { FinancingStore } from '../../FinancingStore';
import { SignFlow } from 'app/domain/SignFlow';
import QRCode from 'react-qr-code';
import { Field, FormikProps } from 'formik';
import { PhoneField } from 'app/components/phone-field/PhoneField';
import { EmailField } from 'app/components/phone-field/EmailField';
import { SemiSelfServiceSchema } from './SemiSelfServiceFormSchema';
import { RadioGroup } from 'formik-material-ui';

interface IProps extends FormikProps<SemiSelfServiceSchema> {
  financingStore: FinancingStore;
}

class SemiSelfServiceDialogForm extends React.Component<IProps> {
  render() {
    const { financingStore, values } = this.props;

    return (
      <Grid container spacing={2}>
        <Typography style={{ padding: 8 }}>
          Du kan distribuera ansökan till kunden så kunden kan slutföra den på sin egna enhet. Låt kunden
          skanna QR-koden eller skicka en länk till ansökan med SMS eller e-post.
        </Typography>
        <Grid item xs={12} style={{ textAlign: 'center', margin: '16px 0' }}>
          <QRCode size={150} value={financingStore.semiselfServiceUrl} />
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              <Field component={RadioGroup} name="flowMethod">
                <FormControlLabel label="Skicka med SMS" value={SignFlow.PHONE} control={<Radio />} />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                component={PhoneField}
                name="phoneNumber"
                disabled={values.flowMethod !== SignFlow.PHONE}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              <Field component={RadioGroup} name="flowMethod">
                <FormControlLabel label="Skicka med E-post" value={SignFlow.EMAIL} control={<Radio />} />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                component={EmailField}
                name="emailAddress"
                variant="outlined"
                disabled={values.flowMethod !== SignFlow.EMAIL}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default observer(SemiSelfServiceDialogForm);
