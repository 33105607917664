import { Document, Font } from '@react-pdf/renderer';
import { DocumentPackage } from 'app/domain/ApplicationContract';
import React from 'react';

Font.register({
  family: 'Santander',
  src: `/resources/fonts/SantanderTextW05-Regular.ttf`,
});

Font.register({
  family: 'Santander Bold',
  src: `/resources/fonts/SantanderHeadlineW05-Bold.ttf`,
});

Font.register({
  family: 'Santander Light',
  src: `/resources/fonts/SantanderTextW05-Light.ttf`,
});

interface ContractProps {
  data: DocumentPackage;
  pages: React.FC<{ data: DocumentPackage }>[];
}

export const Contract = ({ data, pages }: ContractProps) => {
  return (
    <Document>
      {pages.map((Page, index) => (
        <Page key={index} data={data} />
      ))}
    </Document>
  );
};
