import { AppStore } from 'app/AppStore';
import { type BannerChannel, GLOBAL_BANNER } from 'app/domain';
import { makeAutoObservable } from 'mobx';

class BannerManagementStore {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  appStore: AppStore = undefined!;

  currentChannel: BannerChannel = GLOBAL_BANNER;
  currentMessage?: string;
  isLoading?: boolean;

  constructor() {
    makeAutoObservable(this);
  }

  get message() {
    return this.currentMessage ?? this.currentBanner?.message;
  }

  get isPublished() {
    return Boolean(this.currentBanner?.message);
  }

  get currentBanner() {
    if (this.currentChannel === GLOBAL_BANNER) {
      return this.appStore.globalBanner;
    }

    return this.appStore.tenantBanner;
  }

  withAppStore(appStore: AppStore) {
    this.appStore = appStore;
    return this;
  }

  changeChannel = async (channel: BannerChannel) => {
    this.currentChannel = channel;
    this.currentMessage = undefined;
  };

  changeMessage = async (message: string) => {
    this.currentMessage = message;
  };

  publish = async () => {
    if (!this.message) return;

    const currentDate = new Date();

    this.appStore.updateBanner({
      key: this.currentChannel,
      message: this.message,
      dateTo: new Date(currentDate.setMonth(currentDate.getMonth() + 6)).toISOString(),
    });
  };

  unpublish = async () => {
    this.appStore.deleteBanner(this.currentChannel);
  };
}

const bannerManagementStore = new BannerManagementStore();

function getBannerManagementStore() {
  return bannerManagementStore;
}

export { BannerManagementStore, getBannerManagementStore };
