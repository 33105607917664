import { observer } from 'mobx-react';
import React from 'react';
import { Button, Grid, Box, LinearProgress } from '@material-ui/core';
import { ErrorText } from 'ui';
import FinancingConditions from '../FinancingConditions';
import { FinancingStore } from '../FinancingStore';
import ManualApplicationChecklist from './parts/ManualApplicationChecklist';
import ApplicantDetails from '../application-view/parts/ApplicantDetails';
import PrintDocumentsButton from './parts/PrintDocumentsButton';
import ApproveSignatureButton from './parts/ApproveSignatureButton';
import ReasonCode from 'app/domain/ReasonCode';
import { DecisionCode } from 'app/domain';
import { Navigate } from 'react-router';
import { RouteComponentProps, withRouter } from 'app/components/withRouter';

interface IProps extends RouteComponentProps {
  financingStore: FinancingStore;
  futurePayment?: boolean;
}

class ManualSigningView extends React.Component<IProps> {
  state: { isLoading: boolean } = {
    isLoading: false,
  };

  componentDidMount() {
    const { financingStore } = this.props;
    const applicationId = this.props.params.applicationId;

    if (!financingStore.financing.application.applicationId) {
      financingStore.loadApplication(applicationId);
    }
  }

  render() {
    const { financingStore } = this.props;
    const { application } = financingStore.financing;

    if (!application.applicationId) {
      return <></>;
    }

    const isApproved = application.decisionCode === DecisionCode.Approved;

    if (
      !isApproved ||
      application.reasonCode === ReasonCode.Activated ||
      !financingStore.appStore.isManualSigning
    ) {
      return <Navigate replace to={`/financing/application/${application.applicationId}`} />;
    }

    return (
      <Grid container spacing={3}>
        <Grid item md={4} sm={4} xs>
          <FinancingConditions />
        </Grid>
        <Grid item md={8} sm={8} xs>
          {financingStore.error && (
            <Grid container justifyContent="center" style={{ padding: 15 }}>
              <ErrorText>{financingStore.error.text}</ErrorText>
              <ErrorText>{financingStore.error.value}</ErrorText>
            </Grid>
          )}
          <ApplicantDetails financingStore={financingStore} />

          <ManualApplicationChecklist />

          <Box my={4} />

          <Grid container direction="row">
            <Grid item md={4}>
              <Button
                disabled={this.state.isLoading}
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() =>
                  financingStore.appStore.router.history.push(
                    `/financing/application/${financingStore.financing.application.applicationId}`,
                    { keepState: true }
                  )
                }
              >
                AVBRYT
              </Button>
            </Grid>
            <Grid item md={4}>
              <PrintDocumentsButton
                onUpdateLoading={(isLoading) => this.setState({ isLoading })}
                financingStore={financingStore}
                isLoading={this.state.isLoading}
              />
            </Grid>
            <Grid item md={8}>
              <ApproveSignatureButton financingStore={financingStore} isLoading={this.state.isLoading} />
              {this.state.isLoading && <LinearProgress />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(observer(ManualSigningView));
