import {
  createStyles,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import { PageTitle } from 'app/components/PageTitle';
import { PageComponent } from 'utils/ComponentUtils';
import { FinancingStore, getFinancingStore } from './FinancingStore';
import ApplicationView from './application-view/ApplicationView';
import ApplicationDeletedView from './ApplicationDeletedView';
import ApplyView from './ApplyView';
import IdentificationView from './identification-view/IdentificationView';
import ManualSigningView from './manual-signing/ManualSigningView';
import AdditionalInfoView from './AdditionalInfoView';

const styles = (theme: Theme) =>
  createStyles({
    stepper: {
      marginBottom: theme.spacing(1),
    },
  });

class FinancingPage extends PageComponent<WithStyles<typeof styles>> {
  financingStore: FinancingStore = getFinancingStore().withAppStore(this.props.appStore);

  render() {
    const { financingStore } = this;
    const { classes } = this.props;
    const steps = financingStore.getSteps;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Helmet>
            <title>Online Service - Finansiering</title>
          </Helmet>
          <PageTitle>Finansiering</PageTitle>

          <Grid container>
            <Grid item md>
              <Stepper color="secondary" className={classes.stepper} activeStep={financingStore.activeStep}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>

          <Routes>
            <Route path="/application" element={<ApplyView financingStore={financingStore} />} />
            <Route
              path="/application/deleted/"
              element={<ApplicationDeletedView financingStore={financingStore} />}
            />
            <Route
              path="/application/:applicationId"
              element={<ApplicationView financingStore={financingStore} />}
            />
            <Route
              path="/application/:applicationId/manual"
              element={<ManualSigningView financingStore={financingStore} />}
            />
            <Route
              path="/application/:applicationId/additionalinfo"
              element={<AdditionalInfoView financingStore={financingStore} />}
            />
            <Route path="/" element={<IdentificationView financingStore={financingStore} />} />
          </Routes>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(observer(FinancingPage));
