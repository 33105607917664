import { SchemaType } from 'utils/TypeUtils';
import { FormikHelpers as FormikActions } from 'formik';
import { mixed, object } from 'yup';
import { phoneSchema } from 'app/components/phone-field/PhoneField';
import { emailSchema } from 'app/components/phone-field/EmailField';
import { SignFlow } from 'app/domain/SignFlow';
import { string } from 'yup';

export type SemiSelfServiceSchema = SchemaType<typeof semiSelfServiceSchema>;

export type SemiSelfServiceActions = FormikActions<SemiSelfServiceSchema>;

export const semiSelfServiceSchema = object({
  phoneNumber: string().optional().when('flowMethod', {
    is: SignFlow.PHONE,
    then: phoneSchema,
  }),
  emailAddress: emailSchema.optional().when('flowMethod', {
    is: SignFlow.EMAIL,
    then: emailSchema,
  }),
  flowMethod: mixed<SignFlow>().oneOf(Object.values(SignFlow)),
});
