import { observer } from 'mobx-react';
import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import { DecisionCode } from 'app/domain';
import { FinancingStore } from '../../FinancingStore';
import { useTracking } from 'utils/GA';
import { TrackingAction, TrackingCategory } from 'app/resources/Tracking';

const dataLayer = useTracking(window.dataLayer);

interface IProps {
  financingStore: FinancingStore;
}

class OngoingApplicationsDialog extends React.Component<IProps> {
  render() {
    const { financingStore } = this.props;
    const { Approved, Pending, Denied } = DecisionCode;

    return (
      <Dialog maxWidth="md" open={financingStore.showOngoingApplications}>
        <DialogTitle id="confirmation-dialog-title">Pågående ansökningar</DialogTitle>
        <DialogContent style={{ width: '600px' }}>
          <List>
            {financingStore.ongoingApplications.map((appBrief) => (
              <ListItem divider key={appBrief.applicationId}>
                <ListItemText
                  primary={`${appBrief.applyDate.substring(0, 10)}`}
                  secondary={
                    <>
                      <div>Id: {appBrief.applicationId}</div>
                      <div>Belopp: {appBrief.purchaseAmount} kr</div>
                      <div>
                        {'Beslut: '}
                        {appBrief.decisionCode === Approved && 'Godkänd'}
                        {appBrief.decisionCode === Pending && 'Väntande'}
                        {appBrief.decisionCode === Denied && 'Ej godkänd'}
                      </div>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dataLayer.pushInteraction(
                TrackingCategory.FinancingApplication,
                TrackingAction.ContinueResumeApplication
              );
              financingStore.resumeApplication(financingStore.ongoingApplications[0]);
            }}
            color="primary"
          >
            Återuppta
          </Button>
          <Button onClick={financingStore.closeOngoingApplicationsDialog} color="primary">
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default observer(OngoingApplicationsDialog);
