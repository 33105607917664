import { Grid } from '@material-ui/core';
import { DocumentLink } from 'app/components/DocumentLink';
import { PageTitle } from 'app/components/PageTitle';
import { content } from 'app/resources/Content';
import { PageComponent } from 'utils/ComponentUtils';
import { observer } from 'mobx-react';
import Helmet from 'react-helmet';

class ManualsPage extends PageComponent {
  render() {
    return (
      <>
        <Helmet>
          <title>Online Service - Manualer</title>
        </Helmet>
        <PageTitle>Manualer</PageTitle>

        <Grid container>
          <Grid item md={12}>
            {content().map((m, index) => (
              <DocumentLink {...m} key={index} />
            ))}
          </Grid>
        </Grid>
      </>
    );
  }
}

export default observer(ManualsPage);
