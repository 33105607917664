import {
  Avatar,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import { CURRENT_TENANT, TenantsEnum } from 'app/Tenant';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
  },
}));

const FinancingConditions = () => {
  const classes = useStyles();

  const check = (
    <ListItemAvatar>
      <Avatar className={classes.avatar}>
        <Check />
      </Avatar>
    </ListItemAvatar>
  );

  const Condition = (props: { text: string }) => (
    <ListItem>
      {check}
      <ListItemText>{props.text}</ListItemText>
    </ListItem>
  );

  return (
    <>
      <Typography variant="h6">Grundkrav för att ansöka</Typography>
      <List>
        <Condition text="Fyllt 18 år" />
        <Condition text="Inga betalningsanmärkningar" />
        <Condition text="Årsinkomst minst 150 000kr" />
      </List>
      <List dense>
        {/* <ListItem><ListItemText secondary={<Link>Hämta manual</Link>}>Manual som både hjälper dig som är ny användare och dig som vill ha mer utförlig information om Online Service.</ListItemText></ListItem> */}
        <ListItem>
          <ListItemText
            secondary={
              <Link target="_blank" title="Santander store" href="http://santander.store.elanders.com/">
                Gå till Santander store
              </Link>
            }
          >
            Beställa gratis butiksmaterial?
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText secondary="Be kunden att logga in på Internetbanken/appen">
            Aktivera kundens kort{' '}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText secondary="08-559 514 00 (vardagar 9-16)">
            Kundtjänst när er kund har frågor t.ex om sitt konto:{' '}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText
            secondary={
              <>
                {CURRENT_TENANT === TenantsEnum.OS ? '08-559 513 23' : '08-525 03 200'} (vardagar 9-20 helger
                10-18) <br />
                <Link href="mailto:butiksservice@santanderconsumer.se">
                  butiksservice@santanderconsumer.se
                </Link>
              </>
            }
          >
            Butiksservice (endast för butiken)
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
};

export default FinancingConditions;
