export const contractLoader = `<!DOCTYPE html>
<html>
  <head>
    <title>Öppnar avtal...</title>
    <style>
      .center {
        position: absolute;
        left: 50%;
        top: 50%;
        -moz-transform: translate(-50%, -50%); /* Firefox */
        -ms-transform: translate(-50%, -50%); /* IE 9 */
        -webkit-transform: translate(-50%, -50%); /* Safari and Chrome*/
        -o-transform: translate(-50%, -50%); /* Opera */
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Verdana, Geneva, 'Segoe UI', Tahoma, sans-serif;
      }

      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes dash {
        0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -35;
        }
        100% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -124;
        }
      }

      .loader {
        margin-bottom: 16px;
        width: 40px;
        height: 40px;
        color: #ec0000;
        animation: rotate 1.4s linear infinite;
        display: inline-block;
      }

      .loader circle {
        animation: dash 1.4s ease-in-out infinite;
        stroke-dasharray: 80px, 200px;
        stroke-dashoffset: 0px;
        stroke: currentColor;
      }
      
    </style>
  </head>
  <body>
    <div class="center">
      <div class="loader" role="progressbar">
        <svg viewBox="22 22 44 44">
          <circle
            cx="44"
            cy="44"
            r="20.2"
            fill="none"
            stroke-width="3.6"
          ></circle>
        </svg>
      </div>
      <div>Öppnar avtal, vänligen vänta...</div>
    </div>
  </body>
</html>
`;
