import { ThemeOptions } from '@material-ui/core/styles/createTheme';
const primaryColor = '#EC0000';
const secondaryColor = '#eef6f8';
const secondaryColorDark = '#deeef2';

const defaultTheme: ThemeOptions = {
  typography: {
    fontSize: 14,
    fontFamily: '"SantanderText-Regular"',
    body2: {
      fontFamily: '"SantanderText-Regular"',
    },
    body1: {
      fontFamily: '"SantanderHeadline-Regular"',
    },
    h1: {
      fontFamily: '"SantanderHeadline-Bold"',
    },
    h2: {
      fontFamily: '"SantanderHeadline-Bold"',
    },
    h3: {
      fontFamily: '"SantanderHeadline-Bold"',
    },
    h4: {
      fontFamily: '"SantanderHeadline-Bold"',
    },
    h5: {
      fontFamily: '"SantanderHeadline-Bold"',
    },
    h6: {
      fontFamily: '"SantanderHeadline-Bold"',
    },
    subtitle1: {
      fontFamily: '"SantanderHeadline-Bold"',
    },
    subtitle2: {
      fontFamily: '"SantanderHeadline-Regular"',
    },
  },
  props: {
    MuiGrid: {
      spacing: 2,
    },
  },
  palette: {
    primary: { main: primaryColor },
    secondary: { main: secondaryColor },
    background: {
      default: '#fff',
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    // MuiSvgIcon: {
    //     root: {
    //         marginTop: '4px'
    //     }
    // },

    MuiStepper: {
      root: {
        padding: '8px',
        // paddingBottom: '8px',
        backgroundColor: 'transparent',
      },
    },
    MuiStepLabel: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      active: { fontWeight: 'bold !important' as any },
      completed: {},
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: 'green',
        },
        '&$active': {
          color: 'gray',
        },
        color: 'lightgray',
      },
      active: {},
      completed: {},
    },
    MuiButton: {
      root: {
        paddingTop: '14px',
        paddingBottom: '14px',
      },
      contained: {
        paddingTop: '14px ',
        paddingBottom: '14px ',
      },
      outlined: {
        paddingTop: '14px ',
        paddingBottom: '14px ',
      },
    },
    MuiInput: {
      formControl: {
        backgroundColor: '#fff',
      },
      inputMultiline: {
        height: 'auto',
      },
      root: {
        padding: '0px',
        height: '60px',
      },
      multiline: {
        height: 'auto',
      },
      underline: {
        '&:hover:not(.Mui-disabled):before': {
          borderWidth: '0px',
        },
        '&:after': {
          borderWidth: '1px!important',
        },
      },
      input: {
        padding: '27px 12px 10px',
        overflow: 'hidden',
        height: '100%',
        boxSizing: 'border-box',
        backgroundColor: '#fff',
      },
    },
    MuiFilledInput: {
      input: {
        backgroundColor: '#fff',
        border: `2px solid ${secondaryColorDark}`,
      },
      underline: {
        '&:before': {
          borderBottom: `2px solid ${secondaryColorDark}`,
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: `gray`,
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: `gray`,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: 'gray',
      },
    },
  },
};
export { defaultTheme, secondaryColor, primaryColor, secondaryColorDark };
