import { observer } from 'mobx-react';
import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { PaymentMethod } from 'app/domain';
import { FinancingStore } from '../../FinancingStore';

interface IProps {
  financingStore: FinancingStore;
  title: string;
}

class PurchaseSubmitted extends React.Component<IProps> {
  render() {
    const { financingStore, title } = this.props;

    const { paymentMethod } = financingStore.appStore.user;

    const isNets = paymentMethod === PaymentMethod.Nets;

    let confirmationText, paymentInfoText: string;
    let smsInfoText: string | undefined = undefined;

    if (financingStore.hasPostponedPayment) {
      confirmationText = 'Betalning uppskjuten';
      paymentInfoText = 'Betalningen är uppskjuten och kan genomföras när varan skall lämnas ut.';
    } else if (isNets) {
      confirmationText = 'SMS med köp-id skickat till kunden';
      paymentInfoText =
        'Be kunden slutföra betalning i terminalen. När kunden är klar kan du stänga den här sidan.';
      smsInfoText =
        'Om kunden inte fått SMS med köp-id kan du skicka ett nytt SMS med knappen nedan, kontrollera även att rätt telefonnummer är angivet.';
    } else {
      confirmationText = 'Köpet ' + financingStore.ocrPurchaseAmount + ' kr är skickat';
      paymentInfoText =
        'Ange OCR-nummer och belopp i kassasystemet för att slutföra köpet. När du är klar kan du stänga den här sidan.';
    }

    return (
      <>
        <Grid item md={12}>
          <Typography>{title}</Typography>
        </Grid>
        <Grid item>
          <Box py={2}>
            <Grid container direction="column" justifyContent="flex-start">
              <Grid item>
                <Box py={2}>
                  <Typography variant="body1">
                    <span style={{ color: 'green' }}>{confirmationText}</span>
                  </Typography>
                </Box>
                {isNets && financingStore.hasSubmittedPurchase && !financingStore.hasPostponedPayment && (
                  <Box py={2}>
                    <Typography variant="body1">
                      <span style={{ color: 'green' }}>
                        Tillgängligt köputrymme {financingStore.financing.application.availableCredit} kr
                      </span>
                    </Typography>
                  </Box>
                )}

                <Box py={2}>
                  <Typography variant="h6">Slutför betalning</Typography>
                  <Typography variant="body1">{paymentInfoText}</Typography>
                  {smsInfoText && (
                    <>
                      <Box mb={2} />
                      <Typography variant="body1">{smsInfoText}</Typography>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </>
    );
  }
}

export default observer(PurchaseSubmitted);
