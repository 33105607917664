import {
  Box,
  Button,
  Card,
  CardContent,
  createStyles,
  Grid,
  TextField,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import PaymentIcon from '@material-ui/icons/Payment';
import { AppStore } from 'app/AppStore';
import { PageSubTitle, PageTitle } from 'app/components/PageTitle';
import { PageComponent } from 'utils/ComponentUtils';
import { observer } from 'mobx-react';
import Helmet from 'react-helmet';
import { getProductsStore, ProductsStore } from './ProductsStore';
import { GetPaymentPlanText, GetBillingFeeText } from 'app/domain';
import { ErrorText } from 'ui';

const styles = (theme: Theme) =>
  createStyles({
    card: {
      marginBottom: theme.spacing(1),
    },
    boldTypo: {
      fontWeight: 'bold',
    },
    cardHeader: {
      '&:hover': {
        cursor: 'default',
      },
    },
  });

interface IProps extends WithStyles<typeof styles> {
  appStore: AppStore;
}

class ProductsPage extends PageComponent<IProps> {
  productsStore: ProductsStore = getProductsStore().withAppStore(this.props.appStore);
  state: { _purchaseAmount?: number } = { _purchaseAmount: undefined };

  componentDidMount() {
    this.productsStore.appStore.getCampaigns();
  }

  render() {
    const { campaigns, getCampaigns, purchaseAmount, campaignError } = this.productsStore.appStore;
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item md={12}>
          <Helmet>
            <title>Online Service - Produkter</title>
          </Helmet>
          <PageTitle>Produkter</PageTitle>
          <PageSubTitle>Information om aktuella kampanjer</PageSubTitle>
          <Grid container>
            <Grid item md={4}>
              <Grid container>
                <Grid item md={12}>
                  <TextField
                    label="Köpbelopp"
                    variant="filled"
                    onKeyDown={(e) => e.key === '-' && e.preventDefault()}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const purchaseAmountValue = Number(event.target.value);
                      this.setState({ _purchaseAmount: purchaseAmountValue });
                    }}
                    value={this.state._purchaseAmount}
                    fullWidth
                    type="number"
                    title=" "
                    helperText={'Ange för att kunna beräkna månadskostnad'}
                  />
                </Grid>
                <Grid item md={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      getCampaigns(this.state._purchaseAmount);
                    }}
                  >
                    <Box component={PaymentIcon} mr={1} /> Beräkna månadskostnad
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={8}>
              {campaignError && <ErrorText>{campaignError}</ErrorText>}
              {campaigns.map((campaign) => {
                const paymentPlanContent = GetPaymentPlanText(campaign);

                return (
                  <Card className={classes.card} key={campaign.code}>
                    <CardContent>
                      <Grid container className={classes.cardHeader} justifyContent="space-between">
                        <Grid item>
                          <Typography variant="subtitle1" color="primary">
                            {`${campaign.code} - ${campaign.name}`}
                          </Typography>
                        </Grid>
                      </Grid>
                      {paymentPlanContent.map((text, key) => (
                        <Typography variant="body2" key={key}>
                          {text}
                        </Typography>
                      ))}
                      <Typography variant="body2">
                        {`Uppläggningsavgift: ${campaign.startupFee} kr`}
                      </Typography>
                      <Typography variant="body2">
                        {'Administrationsavgift: ' + GetBillingFeeText(campaign)}
                      </Typography>
                      <Typography variant="body2">
                        {`Beloppsgräns: ${campaign.minAmount} - ${campaign.maxAmount}`}
                      </Typography>
                      {campaign.cost && (
                        <>
                          <Typography variant="body2" className={classes.boldTypo}>
                            {`Avbetalning: ${campaign.cost.monthlyCost} kr/mån i ${campaign.cost.calculatedMonths} månader`}
                          </Typography>
                          <Typography variant="body2" className={classes.boldTypo}>
                            {`Total kostnad: ${campaign.cost.totalCost} kr`}
                          </Typography>
                        </>
                      )}
                      {purchaseAmount && (
                        <Typography variant="body2" className={classes.boldTypo}>
                          {`Effektiv ränta: ${campaign.effectiveInterest1}%`}
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(observer(ProductsPage));
