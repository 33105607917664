import { Grid, Typography } from '@material-ui/core';
import * as React from 'react';

const ErrorText = ({ children }: React.PropsWithChildren<unknown>) =>
  children ? (
    <Grid container justifyContent="center">
      <Grid item md={12}>
        <Typography color="primary">{children}</Typography>
      </Grid>
    </Grid>
  ) : null;

export default ErrorText;
