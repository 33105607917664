import { observer } from 'mobx-react';
import * as React from 'react';

import { Button, Grid, Typography, Box } from '@material-ui/core';

import FinancingConditions from './FinancingConditions';
import { FinancingStore } from './FinancingStore';
import { useTracking } from 'utils/GA';
import { TrackingAction, TrackingCategory } from 'app/resources/Tracking';

const dataLayer = useTracking(window.dataLayer);

interface IProps {
  financingStore: FinancingStore;
}

class ApplicationDeletedView extends React.Component<IProps> {
  render() {
    const { financingStore } = this.props;

    if (!financingStore.financing.application.applicationId) {
      return <></>;
    }

    const { customer, application } = financingStore.financing;

    return (
      <Grid container spacing={3}>
        <Grid item md={4} sm={4} xs>
          <FinancingConditions />
        </Grid>
        <Grid item md={8} sm={8} xs>
          <Grid container>
            <Grid item xs={12}>
              <Typography>
                <strong>Kund</strong>
              </Typography>
              <Typography>{customer.name}</Typography>
              <Typography>{customer.socialSecurityNumber}</Typography>
              <Typography>{customer.address}</Typography>
              <Typography>
                {customer.zipCode} {customer.city}
              </Typography>
              <Box mt={4} />
              <Typography>
                <strong>Ansökan</strong>
              </Typography>
              <Typography>Vald kampanj {application.campaignCode}</Typography>
              <Typography>Kreditgräns {application.approvedAmount}</Typography>
              <Box mt={4} />
              <Typography>
                <strong>Ansökan borttagen</strong>
              </Typography>
              <Typography>Ansökan är borttagen men UC-förfrågan kvarstår</Typography>
              <Box mt={4} />
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dataLayer.pushInteraction(
                      TrackingCategory.FinancingApplication,
                      TrackingAction.EndDeletingApplication
                    );
                    financingStore.cancelApplication();
                  }}
                >
                  Avsluta
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default observer(ApplicationDeletedView);
