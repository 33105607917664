import { TrackingAction, TrackingCategory } from 'app/resources/Tracking';

interface DataLayerItem {
  event: string;
  eventCategory: TrackingCategory;
  eventAction: TrackingAction;
  eventLabel: string;
}
interface PageViewItem {
  event: 'pageview';
  page_title: string;
  page_location?: string;
  page_path: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useTracking = (dataLayer: any) => {
  const push = (item: DataLayerItem | PageViewItem) => {
    if (dataLayer) {
      dataLayer.push(item);
    }
  };

  const pushPageView = (pagePath: string, pageSearch?: string, customObject?: object) => {
    const pageviewObject: PageViewItem = {
      ...customObject,
      event: 'pageview',
      page_title: pagePath,
      page_location: pageSearch,
      page_path: pagePath,
    };

    push(pageviewObject);
  };

  const pushInteraction = (
    eventCategory: TrackingCategory,
    eventAction: TrackingAction,
    eventPrefix?: string
  ) => {
    if (!eventCategory || !eventAction) return;
    const event = eventPrefix ? `${eventPrefix} | NOS` : 'NOS';
    push({
      event,
      eventCategory,
      eventAction,
      eventLabel: window.location.pathname,
    });
  };

  return {
    push,
    pushPageView,
    pushInteraction,
  };
};
