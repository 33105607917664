import { SchemaType } from 'utils/TypeUtils';
import { differenceInYears } from 'date-fns';
import { FormikHelpers as FormikActions } from 'formik';
import { object, string, number, boolean } from 'yup';

const isValidSwedishPIN = (pin?: string) => {
  if (pin === undefined) {
    return false;
  }

  const pinArray = pin
    .replace(/\D/g, '') // strip out all but digits
    .split('') // convert string to array
    .reverse() // reverse order for Luhn
    .slice(0, 10); // keep only 10 digits (i.e. 1977 becomes 77)

  // verify we got 10 digits, otherwise it is invalid
  if (pinArray.length !== 10) {
    return false;
  }

  const sum = pinArray
    // convert to number
    .map((n: string) => {
      return Number(n);
    })
    // perform arithmetic and return sum
    .reduce((previous, current, index) => {
      // multiply every other number with two
      if (index % 2) {
        current *= 2;
      }
      // if larger than 10 get sum of individual digits (also n-9)
      if (current > 9) {
        current -= 9;
      }
      // sum it up
      return previous + current;
    });

  // sum must be divisible by 10
  return 0 === sum % 10;
};

const isEighten = (ssn?: string) => {
  if (ssn === undefined || ssn.length !== 12) {
    return true;
  }

  const birthDate = ssn.substring(0, 8);
  const age = differenceInYears(new Date(), birthDate);
  return age >= 18;
};

export type IdentificationSchema = SchemaType<typeof identificationSchema>;

export type IdentificationActions = FormikActions<IdentificationSchema>;

export const identificationSchema = object({
  newApplication: boolean(),

  purchaseAmount: number().when('newApplication', {
    is: true,
    then: (schema) =>
      schema
        .positive('Belopp kan inte vara negativt')
        .max(100000, 'Belopp måste vara mindre än 100000')
        .required('Belopp saknas'),
  }),

  socialSecurityNumber: string()
    .transform((value) => value.replace(/\D/g, ''))
    .test('is-ssn', 'Personnumret du angivet är inte giltigt', (value) => isValidSwedishPIN(value))
    .test(
      'starts-with-century',
      'Personnummer måste börja med 19 eller 20',
      (value = '19') => value.startsWith('19') || value.startsWith('20') || value.startsWith('21')
    )
    .length(12, 'Personnummer måste ha 12 siffror')
    .test('is-eighteen', 'Personen måste ha fyllt 18', (ssn) => isEighten(ssn))
    .required('Du måste ange Personnummer'),
});
