import { Box, Button, LinearProgress } from '@material-ui/core';
import { Field, Form, Formik, FormikValues } from 'formik';
import { SchemaType } from 'utils/TypeUtils';
import { observer } from 'mobx-react';
import * as React from 'react';
import { number, object, ref } from 'yup';
import AlertDialog from 'app/AlertDialog';
import { FinancingStore } from '../../FinancingStore';
import SalesScoringType from 'app/domain/SalesScoringType';
import { TextField } from 'formik-material-ui';
import { SigningMethod } from 'app/domain/SigningMethod';
import { useTracking } from 'utils/GA';
import { TrackingAction, TrackingCategory } from 'app/resources/Tracking';

const dataLayer = useTracking(window.dataLayer);

const changeAmountSchema = object({
  maxLimit: number(),
  minLimit: number(),
  creditLimit: number()
    .min(ref('minLimit'), (val) => `Lägsta tillåtna kreditgräns är ${val.min} kr.`)
    .max(ref('maxLimit'), (val) => `Högsta möjliga kreditgräns är ${val.max} kr.`),
});

interface IProps {
  financingStore: FinancingStore;
}

class ChangeLimitButton extends React.Component<IProps> {
  changeAmountFormRef: React.MutableRefObject<FormikValues | null>;

  constructor(props: IProps) {
    super(props);
    this.changeAmountFormRef = React.createRef<FormikValues | null>();
  }

  state: { contractDeliveryMethod: SigningMethod; showAlert: boolean; buttonsEnabled: boolean } = {
    contractDeliveryMethod: SigningMethod.WEB,
    showAlert: false,
    buttonsEnabled: true,
  };

  renderChangeAmountForm = () => {
    dataLayer.pushInteraction(TrackingCategory.FinancingApplication, TrackingAction.ChangeCreditLimit);
    this.setState({ showAlert: true, buttonsEnabled: true });
  };

  closeAlertForm = () => {
    this.setState({ showAlert: false });
    this.toggleButtons(true);
  };

  toggleButtons = (isEnabled: boolean) => {
    this.setState({ buttonsEnabled: isEnabled });
  };

  render() {
    const { financingStore } = this.props;

    const application = financingStore.financing.application;
    const isAuthorization = application.salesScoringType == SalesScoringType.Authorization;
    let changeAmountMin = (application.totalCreditLimit ?? 0) + (application.approvedAmount ?? 0) + 1000;
    if (changeAmountMin > (application.possibleCreditLimit ?? 0)) {
      changeAmountMin = application.possibleCreditLimit ?? 0;
    }

    const canIncreaseLimit = (application.maxCreditLimit || 0) > 0;
    if (!canIncreaseLimit) return null;

    return (
      <>
        {this.state.showAlert && (
          <AlertDialog
            appStore={financingStore.appStore}
            alert={{
              title: 'Ändra kreditgräns',
              acceptText: 'Ändra',
              acceptDisabled: !this.state.buttonsEnabled,
              cancelDisabled: !this.state.buttonsEnabled,
              keepOpenOnAccept: true,
              content: (
                <Formik<SchemaType<typeof changeAmountSchema>>
                  innerRef={(p) => (this.changeAmountFormRef.current = p)}
                  validationSchema={changeAmountSchema}
                  initialValues={{
                    maxLimit: application.possibleCreditLimit ?? 0,
                    minLimit: changeAmountMin,
                    creditLimit: application.possibleCreditLimit ?? 0,
                  }}
                  onSubmit={async (values) => {
                    application.approvedAmount = undefined;
                    this.toggleButtons(false);
                    await financingStore.updateApplication({ purchaseAmount: values.creditLimit });
                    this.closeAlertForm();
                  }}
                >
                  {({ isSubmitting }) => {
                    return (
                      <Box minWidth="250px">
                        <Form style={{ width: '100%' }}>
                          <Field
                            fullWidth
                            label="Möjlig kreditgräns"
                            name="creditLimit"
                            placeholder=""
                            variant="filled"
                            type="number"
                            component={TextField}
                          />
                        </Form>
                        {isSubmitting && (
                          <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                          </Box>
                        )}
                      </Box>
                    );
                  }}
                </Formik>
              ),
              acceptCallback: async () => {
                if (this.changeAmountFormRef.current) {
                  this.changeAmountFormRef.current.submitForm();
                }
              },
              cancelCallback: async () => {
                this.closeAlertForm();
              },
            }}
          ></AlertDialog>
        )}
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          disabled={isAuthorization || financingStore.isSigningBankid}
          onClick={this.renderChangeAmountForm}
        >
          Ändra kreditgräns
        </Button>
      </>
    );
  }
}

export default observer(ChangeLimitButton);
