import { createStyles, Grid, Theme, WithStyles, withStyles } from '@material-ui/core';
import RLink from 'app/components/Link';
import { PageTitle } from 'app/components/PageTitle';
import { PageComponent } from 'utils/ComponentUtils';
import { observer } from 'mobx-react';
import Helmet from 'react-helmet';
import { Navigate, Route, Routes } from 'react-router-dom';
import FindFuturePayment from '../futurePayment/FindFuturePayment';
import { getFuturePaymentStore } from '../futurePayment/FuturePaymentStore';
import EditUser from './EditUser';
import FindUser from './FindUser';
import ReportPage from './ReportPage';
import { Settings } from './settings/Settings';
import { isAllowedBannersManagement, isAllowedManualSigning } from 'utils/envUtils';

const styles = (theme: Theme) =>
  createStyles({
    menu: {
      marginBottom: theme.spacing(1),
    },
  });

class AdminPage extends PageComponent<WithStyles<typeof styles>> {
  menuItems = [
    {
      title: 'Ny användare',
      path: 'new-user',
      element: <EditUser appStore={this.props.appStore} />,
    },
    {
      title: 'Sök användare',
      path: 'find-user',
      element: <FindUser appStore={this.props.appStore} />,
    },
    {
      title: 'Rapport',
      path: 'report',
      element: <ReportPage appStore={this.props.appStore} />,
    },
    {
      title: 'Uppskjutna betalningar',
      path: 'future-payment',
      element: (
        <FindFuturePayment futurePaymentStore={getFuturePaymentStore().withAppStore(this.props.appStore)} />
      ),
    },
    {
      title: 'Inställningar',
      path: 'settings',
      element: <Settings appStore={this.props.appStore} />,
      visible:
        isAllowedManualSigning(this.props.appStore.user.userId) ||
        isAllowedBannersManagement(this.props.appStore.user.userId),
    },
  ];

  render() {
    const { classes } = this.props;
    const {
      appStore: { currentPath },
    } = this.props;

    return (
      <Grid container>
        <Grid item md={12}>
          <Helmet>
            <title>Online Service - Admin</title>
          </Helmet>
          <PageTitle>Admin</PageTitle>

          <Grid container className={classes.menu}>
            {this.menuItems
              .filter((x) => x.title && x.visible !== false)
              .map((item) => (
                <Grid key={item.path} item>
                  <RLink to={item.path}>
                    {currentPath.startsWith(item.path) ? <strong>{item.title}</strong> : item.title}
                  </RLink>
                </Grid>
              ))}
          </Grid>

          <Routes>
            {this.menuItems
              .filter((x) => x.visible !== false)
              .map((item) => (
                <Route key={item.path} path={item.path} element={item.element} />
              ))}
            <Route path="/edit-user/:id" element={<EditUser appStore={this.props.appStore} />} />
            <Route path="/" element={<Navigate replace to={this.menuItems[0].path} />} />
          </Routes>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(observer(AdminPage));
