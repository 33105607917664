import * as React from 'react';

import { MuiThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route, Routes } from 'react-router-dom';
import { Authenticator } from './api/Authenticator';
import { defaultTheme } from './AppTheme';
import { AdminPage, FinancingPage, ManualsPage, ProductsPage, TrainingPage, ReturnPage } from './features';
import ErrorPage from './features/ErrorPage';
import DisclaimerPage from './features/other/DisclaimerPage';
import SettingsPage from './features/settings/SettingsPage';
import FuturePaymentPage from './features/futurePayment/FuturePaymentPage';
import PageLayout from './components/page-layout/PageLayout';
import { useTracking } from '../utils/GA';
import { Navigate, useLocation } from 'react-router';
import { IAppStoreProps } from '../utils/ComponentUtils';
import { router } from 'index/router';
import { Router } from 'react-router-dom';
import { AuthorizeAdmin } from './components/AuthorizeAdmin';

import { useState, useEffect, useLayoutEffect } from 'react';
const dataLayer = useTracking(window.dataLayer);

const App = ({ appStore }: IAppStoreProps) => {
  const currentTheme = createTheme(defaultTheme);

  const [state, setState] = useState({
    action: router.history.action,
    location: router.history.location,
  });

  useLayoutEffect(() => router.subscribe(setState), []);

  return (
    <MuiThemeProvider theme={currentTheme}>
      <CssBaseline />
      <Authenticator appStore={appStore}>
        <Router location={state.location} navigationType={state.action} navigator={router.history}>
          <PageLayout appStore={appStore}>
            <AppWithRoutes appStore={appStore} />
          </PageLayout>
        </Router>
      </Authenticator>
    </MuiThemeProvider>
  );
};

const AppWithRoutes = (props: IAppStoreProps) => {
  const { appStore } = props;

  const { pathname, search } = useLocation();

  useEffect(() => {
    dataLayer.pushPageView(pathname, search);
  }, [pathname, search]);

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/financing" />} />
      <Route path="/financing/*" element={<FinancingPage appStore={appStore} />} />
      <Route path="/settings/*" element={<SettingsPage appStore={appStore} />} />
      <Route path="/return/*" element={<ReturnPage appStore={appStore} />} />
      <Route path="/future-payments/*" element={<FuturePaymentPage appStore={appStore} />} />
      <Route path="/products" element={<ProductsPage appStore={appStore} />} />
      <Route path="/manuals" element={<ManualsPage appStore={appStore} />} />
      <Route path="/disclaimer" element={<DisclaimerPage appStore={appStore} />} />
      <Route path="/error" element={<ErrorPage appStore={appStore} />} />
      <Route path="/training" element={<TrainingPage appStore={appStore} />} />
      <Route element={<AuthorizeAdmin appStore={appStore} />}>
        <Route path="/admin/*" element={<AdminPage appStore={appStore} />} />
      </Route>
    </Routes>
  );
};

export default App;
