import { router as mobxRouter } from 'index/router';
import { runInAction, makeAutoObservable } from 'mobx';
import { userManager } from './api/Authenticator';
import { setupAxiosInterceptors } from './api/AxiosSetup';
import { FinancingApi } from './api/FinancingApi';
import { FinancingClient } from './api/FinancingClient';
import {
  GetCampaignsRequest,
  GetManualSigningRequest,
  UpdateManualSigningRequest,
  UpdateBannerRequest,
} from './api/requests';
import { BannerChannel, Campaign, GLOBAL_BANNER, type Banner } from './domain';
import { Alert } from './domain/Alert';
import * as User from './domain/User';
import { FindFuturePaymentStore } from './features/futurePayment/FindFuturePaymentStore';
import { CURRENT_TENANT } from './Tenant';

class AppStore {
  findFuturePaymentStore?: FindFuturePaymentStore;
  // services

  router = mobxRouter;
  client = new FinancingClient();
  api = new FinancingApi(this.client);

  // state

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  user: User.User = undefined!;
  currentStoreId?: string;
  currentChainId?: string;

  loadingCounter = 0;

  alerts: Alert[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  showMenu = false;
  isIE = false;
  purchaseAmount?: number;
  campaigns: Campaign[] = [];
  campaignError?: string;

  isManualSigning?: boolean;
  tenantBanner?: Banner;
  globalBanner?: Banner;

  get currentPath() {
    const { pathname } = this.router.location;
    return pathname;
  }

  get isOnAdminPage() {
    return this.currentPath.substr(0, 6) === '/admin';
  }

  constructor() {
    makeAutoObservable(this);
    setupAxiosInterceptors(this);
  }

  get currentStore() {
    return this.user?.stores.find((s) => s.id.toString() === this.currentStoreId?.toString());
  }

  toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    this.showMenu = !this.showMenu;
  };

  goToPath = (path: string) => {
    this.router.push(path);
  };

  initCache = () => {
    this.getManualSigning();
    this.getTenantBanner();
    this.getGlobalBanner();
  };

  handleUser = (user: Oidc.User) => {
    const { profile } = user;

    this.user = profile;

    this.currentStoreId = this.user?.activeStoreId;
    this.currentChainId = this.user?.chains[0].id;

    this.client.token = user.access_token;
    this.client.chainId = this.currentChainId;
    this.client.storeId = this.currentStoreId;

    this.initCache();
  };

  changeChain = (chainId: string) => {
    this.currentChainId = chainId;
    this.client.chainId = chainId;
    this.getCampaigns(this.purchaseAmount);
    this.findFuturePaymentStore?.getAllFuturePayments();
    this.findFuturePaymentStore?.getFilteredFuturePayments();

    this.initCache();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showError = (error: any) => {
    this.error = error;
    this.router.push('/error');
  };

  showAlert = (alert: Alert) => {
    this.alerts.push({ ...alert, id: crypto.randomUUID() });
  };

  updateAlert = (alert: Partial<Alert>) => {
    this.alerts = this.alerts.map((existing) =>
      existing.title === alert.title ? { ...existing, ...alert } : existing
    );
  };

  closeAlert = (alert: Pick<Alert, 'title'>) => {
    this.alerts = this.alerts.filter((a) => a.title !== alert.title);
  };

  hideAlert = (alert: Pick<Alert, 'title'>) => {
    const toHide = this.alerts.filter((a) => a.title == alert.title);
    toHide[0].hidden = true;
  };

  getCampaigns = async (purchaseAmount?: number) => {
    const request: GetCampaignsRequest = {
      purchaseAmount,
      chainId: this.currentChainId ?? '',
    };

    const campaignsResponse = await this.api.getCampaigns(request);

    this.purchaseAmount = purchaseAmount;

    if (campaignsResponse.isSuccess) {
      this.campaignError = undefined;
      this.campaigns = campaignsResponse.value;

      return;
    } else {
      this.campaigns = [];
      const error = campaignsResponse.error;

      if (error?.detail?.includes('PurchaseAmount')) {
        this.campaignError = error?.detail;
      } else {
        this.campaignError = 'Det gick inte att hämta produkter.';
      }
    }
  };

  assignFinancingStore(findFuturePaymentStore: FindFuturePaymentStore) {
    this.findFuturePaymentStore = findFuturePaymentStore;
  }
  resetState = () => {
    this.purchaseAmount = undefined;
  };

  handleLogOutCallback = async () => {
    // this.setState({ isAuthenticated: false, logoutUrl: undefined });
  };

  startLoading = () => {
    this.loadingCounter++;
  };

  finishedLoading = () => {
    this.loadingCounter--;
  };

  get isLoading() {
    return this.loadingCounter > 0;
  }

  logOut = () => {
    userManager.signoutRedirect();
  };

  updateBanner = async (data: UpdateBannerRequest) => {
    const response = await this.api.updateBanner(data);

    if (!response.isSuccess) {
      return;
    }

    if (data.key === GLOBAL_BANNER) {
      this.getGlobalBanner();
    } else {
      this.getTenantBanner();
    }
  };

  deleteBanner = async (key: BannerChannel) => {
    const response = await this.api.deleteBanner({ key });

    if (!response.isSuccess) {
      return;
    }

    if (key === GLOBAL_BANNER) {
      this.getGlobalBanner();
    } else {
      this.getTenantBanner();
    }
  };

  getTenantBanner = async () => {
    const res = await this.api.getBanner({ key: CURRENT_TENANT });

    runInAction(() => {
      if (res.isSuccess && res.value !== '') {
        this.tenantBanner = {
          ...res.value,
          key: CURRENT_TENANT,
        };
      } else {
        this.tenantBanner = undefined;
      }
    });
  };

  getGlobalBanner = async () => {
    const res = await this.api.getBanner({ key: GLOBAL_BANNER });

    runInAction(() => {
      if (res.isSuccess && res.value !== '') {
        this.globalBanner = {
          ...res.value,
          key: GLOBAL_BANNER,
        };
      } else {
        this.globalBanner = undefined;
      }
    });
  };

  updateManualSigning = async (state: boolean) => {
    const req: UpdateManualSigningRequest = {
      state,
      tenant: import.meta.env.VITE_TENANT,
    };

    const response = await this.api.updateManualSigning(req);
    if (!response.isSuccess) {
      return;
    }

    runInAction(() => {
      this.isManualSigning = req.state;
    });
  };

  getManualSigning = async () => {
    const req: GetManualSigningRequest = {
      tenant: import.meta.env.VITE_TENANT,
    };

    const response = await this.api.getManualSigning(req);
    if (!response.isSuccess) {
      return;
    }

    this.isManualSigning = response.value.state;
  };
}

// All (.*)State files are marked as stateful modules in
// hmr.ts, which keeps the state in the appState variable
// between hot reloads.
const appStore = new AppStore();

function getAppStore() {
  return appStore;
}

export { AppStore, getAppStore };
