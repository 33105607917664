import { observer } from 'mobx-react';
import React from 'react';
import { format } from 'date-fns';
import List from 'app/components/List';
import { FinancingStore } from '../../FinancingStore';

interface IProps {
  financingStore: FinancingStore;
}

class AccountDetails extends React.Component<IProps> {
  render() {
    const { financingStore } = this.props;

    const { application } = financingStore.financing;

    const labelWidthValue = 250;

    return (
      <List
        title="Konto"
        entries={[
          {
            label: 'OCR-/Kontonummer',
            value: application.ocr,
            labelWidth: labelWidthValue,
          },
          {
            label: 'Skapat datum',
            value: format(application.accountCreationDate ?? '', 'YYYY-MM-DD'),
            labelWidth: labelWidthValue,
          },
          {
            label: 'Tillgänlig kredit',
            value: application.availableCredit + ' kr',
            labelWidth: labelWidthValue,
          },
          {
            label: 'Kreditgräns',
            value: application.totalCreditLimit + ' kr',
            labelWidth: labelWidthValue,
          },
        ]}
      />
    );
  }
}

export default observer(AccountDetails);
