import {
  Box,
  Radio,
  Grid,
  Typography,
  FormControlLabel,
  CircularProgress,
  RadioGroup,
} from '@material-ui/core';
import { FormikValues } from 'formik';
import { observer } from 'mobx-react';
import * as React from 'react';
import BankIdButton from 'app/components/BankIdButton';

import { Alert } from 'app/domain/Alert';
import { FinancingStore } from '../../FinancingStore';
import { MutableRefObject } from 'react';
import { SigningMethod } from 'app/domain/SigningMethod';
import { useTracking } from 'utils/GA';
import { TrackingAction, TrackingCategory } from 'app/resources/Tracking';
import AlertDialog from 'app/AlertDialog';

const dataLayer = useTracking(window.dataLayer);

interface IProps {
  financingStore: FinancingStore;
}

class SignContractButton extends React.Component<IProps> {
  changeAmountFormRef: MutableRefObject<FormikValues | null>;
  showContractBtn;

  constructor(props: IProps) {
    super(props);
    this.changeAmountFormRef = React.createRef<FormikValues | null>();
    this.showContractBtn = React.createRef();
  }

  state: { contractDeliveryMethod: SigningMethod } = {
    contractDeliveryMethod: SigningMethod.WEB,
  };

  render() {
    const { financingStore } = this.props;
    const appStore = financingStore.appStore;

    const popupStyle = { height: 250, width: 520 };

    const showContractPopup: Alert = {
      title: 'Signera avtal',
      acceptText: 'Fortsätt',
      content: (
        <Grid style={popupStyle}>
          <Typography variant="body1">
            För att slutföra köpet behöver kunden godkänna genom att signera avtalet. Du kan välja att öppna
            avtalet på denna enhet, kunden behöver då signera med mobilt BankID, du kan även välja att skicka
            en länk till kunden via E-post eller SMS, då kan kunden öppna avtalet på sin enhet och signera med
            mobilt BankID.
          </Typography>
          <RadioGroup
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              this.setState({ contractDeliveryMethod: e.target.value });
            }}
            defaultValue={this.state.contractDeliveryMethod}
          >
            <FormControlLabel
              value={SigningMethod.WEB}
              control={<Radio />}
              label="Visa avtal på denna enhet."
            />
            <FormControlLabel
              value={SigningMethod.PHONE}
              control={<Radio />}
              label={`Skicka länk till kundens telefon (${financingStore.financing.customer.phone})`}
            />
            <FormControlLabel
              value={SigningMethod.EMAIL}
              control={<Radio />}
              label={`Skicka länk till kundens mail (${financingStore.financing.customer.emailAddress})`}
            />
          </RadioGroup>
        </Grid>
      ),
      hideCancel: false,
      acceptCallback: async () => {
        dataLayer.pushInteraction(
          TrackingCategory.FinancingApplication,
          TrackingAction.ContinueSigningAgreement,
          this.state.contractDeliveryMethod
        );

        financingStore.pollingSignatureAlertVisible = true;

        await financingStore.initiateSigning(this.state.contractDeliveryMethod);

        //poll for signature
        await financingStore.pollforSignature();

        financingStore.pollingSignatureAlertVisible = false;
      },
    };

    return (
      <>
        {financingStore.pollingSignatureAlertVisible && (
          <AlertDialog
            appStore={appStore}
            alert={{
              title: 'Väntar på signering',
              hideAccept: true,
              hideCancel: false,
              cancelCallback: async () => {
                financingStore.pollingSignatureAlertVisible = false;
                financingStore.keepPollingForSignature = false;
              },
              content: (
                <Grid style={popupStyle}>
                  <Typography>
                    Väntar på att kunden skall signera avtalet med mobilt BankID, när avtalet är signerat
                    kommer denna dialog att stängas.
                  </Typography>
                  <Typography>Det signerade avtalet kommer finnas tillgängligt på internetbanken.</Typography>
                  <Box paddingTop="20px" justifyContent="center" display="flex">
                    <CircularProgress size="40px" />
                  </Box>
                </Grid>
              ),
            }}
          />
        )}
        <BankIdButton
          fullWidth
          variant="contained"
          color="primary"
          onClick={async () => {
            if (appStore.isManualSigning) {
              appStore.router.history.push(
                `/financing/application/${financingStore.financing.application.applicationId}/manual`,
                { keepState: true }
              );
            } else {
              dataLayer.pushInteraction(TrackingCategory.FinancingApplication, TrackingAction.SignAgreement);
              financingStore.error = undefined;

              appStore.showAlert(showContractPopup);
            }
          }}
          isSigning={!financingStore.hasSignedForIncreasedAmount && financingStore.isSigningBankid}
          signingError={!financingStore.hasSignedForIncreasedAmount && financingStore.signApplicationFailed}
        >
          Signera Avtal
        </BankIdButton>
      </>
    );
  }
}

export default observer(SignContractButton);
