import { observer } from 'mobx-react';
import React from 'react';
import { Grid, Typography, Box, List, ListItem, Link } from '@material-ui/core';
import { Bold } from 'app/components/bold/Bold';

class ManualApplicationChecklist extends React.Component {
  render() {
    return (
      <Grid item md={12}>
        <Box mt={4} />
        <Typography>
          Just nu går det inte att signera kontrakten digitalt med mobilt BankID. För att slutföra ansökan och
          genomföra köpet måste avtalet signeras manuellt. Följ instruktionerna nedan:
        </Typography>
        <List>
          <ListItem>
            <Typography>
              1) Skriv ut avtalet i <Bold>två exemplar</Bold> genom att trycka på knappen &quot;Skriv ut&quot;
              .
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>2) Legitimera kunden med giltig id-handling.</Typography>
          </ListItem>
          <ListItem>
            <Typography>
              3) Kryssa för typ av id-handling samt anteckna id-handlingens nummer vid angiven plats på
              avtalets förstasida.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>4) Be kunden underteckna båda exemplaren av avtalet.</Typography>
          </ListItem>
          <ListItem>
            <Typography>
              5) När avtalet är undertecknat, klickar du på knappen &quot;Kund har signerat avtal,
              fortsätt&quot;, för att slutföra ansökan.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>6) Ge kunden ett av de undertecknade avtalen inklusive samtliga bilagor.</Typography>
          </ListItem>
          <ListItem>
            <Typography>
              <Bold>
                7) Posta ett av de undertecknade avtalen inklusive samtliga bilagor till Santander på nedan
                angiven adress.
              </Bold>
            </Typography>
          </ListItem>
        </List>
        <Typography>
          Du kan läsa hur du legitimerar kunden med id-handling{' '}
          <Link target="_blank" title="Santander store" href="/resources/pdf/ID_check_guidelines.pdf">
            här
          </Link>
          .
        </Typography>
        <Box mt={4} />
        <Bold style={{ fontSize: '1rem' }}>
          <div>Santander Consumer Bank AS</div>
          <div>Att: Sales Support</div>
          <div>Hemvärnsgatan 9</div>
          <div>FE 302</div>
          <div>171 75 Stockholm</div>
        </Bold>
      </Grid>
    );
  }
}

export default observer(ManualApplicationChecklist);
