import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Field, Form, Formik, FormikValues } from 'formik';
import { observer } from 'mobx-react';
import * as React from 'react';
import { number, object, ref } from 'yup';
import { MutableRefObject } from 'react';
import ReasonCode from 'app/domain/ReasonCode';
import { SchemaType } from 'utils/TypeUtils';
import { Alert } from 'app/domain/Alert';
import { TextField } from 'formik-material-ui';
import { FinancingStore } from '../../FinancingStore';
import ShredApplicationButton from '../../application-approved/parts/ShredApplicationButton';
import { CURRENT_TENANT, TenantsEnum } from 'app/Tenant';

const changeAmountSchema = object({
  maxLimit: number(),
  purchaseAmount: number()
    .min(0, 'Ansökan om negativ kredit stöds ej.')
    .max(ref('maxLimit'), 'Angivet belopp är för högt.'),
});

interface IProps {
  financingStore: FinancingStore;
}

class ApplicationPending extends React.Component<IProps> {
  changeAmountFormRef: MutableRefObject<FormikValues | null>;

  constructor(props: IProps) {
    super(props);
    this.changeAmountFormRef = React.createRef<FormikValues | null>();
  }

  render() {
    const { financingStore } = this.props;
    const { application } = financingStore.financing;

    const isContactBank = application.reasonCode === ReasonCode.ContactBank;
    const isDownsell = application.reasonCode === ReasonCode.Downsell;
    const isProcessing = application.reasonCode === ReasonCode.Processing;
    const isAdditionalInfo = application.reasonCode === ReasonCode.AdditionalInfo;

    if (isProcessing || isAdditionalInfo) return null;

    return (
      <Grid item md={12}>
        <Grid container alignItems="center">
          {isContactBank && (
            <Grid item>
              <Typography>
                Vänligen kontakta Santander Consumer Bank Butiksservice på telefon{' '}
                {CURRENT_TENANT === TenantsEnum.OS ? '08-559 513 23' : '08-525 03 200'}
              </Typography>
            </Grid>
          )}

          {!isProcessing && !isAdditionalInfo && (
            <>
              <Grid item>
                {isDownsell && (
                  <Typography>Kunden kan ansöka om maximalt {application.preApprovedLimit} kr.</Typography>
                )}
              </Grid>
              <Grid item container direction="row">
                {isDownsell && (
                  <Grid item md={4}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={this.renderChangeAmountForm}
                    >
                      Ändra sökt belopp
                    </Button>
                  </Grid>
                )}

                <Grid item md={4}>
                  <ShredApplicationButton financingStore={financingStore} />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    );
  }

  renderChangeAmountForm = () => {
    const { financingStore } = this.props;
    const { application } = financingStore.financing;

    const showChangeAmountAlert: Alert = {
      title: 'Ändra sökt belopp',
      acceptText: 'Ändra',
      keepOpenOnAccept: true,
      content: (
        <Formik<SchemaType<typeof changeAmountSchema>>
          innerRef={(p) => (this.changeAmountFormRef.current = p)}
          validationSchema={changeAmountSchema}
          initialValues={{
            maxLimit: application.preApprovedLimit ?? 0,
            purchaseAmount: application.preApprovedLimit ?? 0,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            financingStore.appStore.hideAlert(showChangeAmountAlert);
            await financingStore.updateApplicationByPurchaseId({ purchaseAmount: values.purchaseAmount });
            setSubmitting(false);
            financingStore.appStore.closeAlert(showChangeAmountAlert);
          }}
        >
          {() => (
            <Box minWidth="250px">
              <Form style={{ width: '100%' }}>
                <Grid container>
                  <Grid item>
                    <Field
                      label="Möjligt belopp"
                      name="purchaseAmount"
                      placeholder=""
                      variant="filled"
                      type="number"
                      component={TextField}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Form>
            </Box>
          )}
        </Formik>
      ),
      acceptCallback: async () => {
        if (this.changeAmountFormRef.current) {
          this.changeAmountFormRef.current.submitForm();
        }
      },
    };
    financingStore.appStore.showAlert(showChangeAmountAlert);
  };
}

export default observer(ApplicationPending);
