enum ReasonCode {
  Downsell = 'Downsell',
  ContactBank = 'ContactBank',
  MissingAnnualIncome = 'MissingAnnualIncome',
  AccountInactive = 'AccountInactive',
  Activated = 'Activated',
  Processing = 'Processing',
  AdditionalInfo = 'AdditionalInfo',
}

export default ReasonCode;
