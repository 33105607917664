import { observer } from 'mobx-react';
import * as React from 'react';
import { ReturnStore } from './ReturnStore';
import { getCompleteReturnStore } from './CompleteReturnStore';
import { Typography, Box, Grid, Button, TextField } from '@material-ui/core';
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import List from 'app/components/List';
import { format } from 'date-fns';
import { PaymentMethod } from 'app/domain';
import FinancingConditions from '../financing/FinancingConditions';

class CompleteReturn extends React.Component<{ returnStore: ReturnStore }> {
  completeReturnStore = getCompleteReturnStore().withReturnStore(this.props.returnStore);

  render() {
    const {
      ocr,
      ssn,
      purchaseAmount,
      returnableAmount,
      returnAmount,
      returnAmountIsValid,
      returnAmountError,
      setReturnAmount,
      validateReturnAmount,
      returnPurchase,
      returnPerformed,
      purchaseDate,
      purchaseStoreName,
      transactionId,
    } = this.completeReturnStore;

    const { paymentMethod } = this.completeReturnStore.appStore.user;
    const isNets = paymentMethod === PaymentMethod.Nets;

    return (
      <Grid container>
        <Grid item md={4} sm={12} xs>
          <FinancingConditions />
        </Grid>
        <Grid item md={8} sm={12} xs>
          <Box pr={3}>
            <Typography variant="h6">Köptransaktion</Typography>
            <List
              entries={[
                { label: 'Transaktionsdatum', value: format(purchaseDate ?? '', 'YYYY-MM-DD HH:mm') },
                {
                  label: 'Belopp',
                  value:
                    (returnableAmount ? returnableAmount : purchaseAmount)?.toLocaleString('sv-SE') + ' kr',
                },
                { label: 'Butik', value: purchaseStoreName },
                { label: 'Transaktions-id/PP-ref för retur', value: transactionId },
                { label: 'OCR-nummer', value: ocr },
                { label: 'Personnummer', value: ssn?.slice(0, 8) + '-' + ssn?.slice(8) },
              ]}
            />
          </Box>
          <Box py={2} pr={3}>
            {!isNets && (
              <Accordion expanded={true}>
                <AccordionSummary style={noneCursor}>
                  <Typography variant="h6">Retur med OCR-nummer</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction="column" justify="flex-start">
                    <Grid item>
                      <Typography variant="h6">Starta retur</Typography>
                      <Typography variant="body1">Ange beloppet som ska återbetalas</Typography>
                    </Grid>
                    <Grid item md={6}>
                      {!returnPerformed ? (
                        <>
                          <Box py={2}>
                            <TextField
                              style={{ paddingBottom: '10px' }}
                              label="Belopp att återbetala"
                              type="number"
                              title=" "
                              value={returnAmount}
                              onChange={(e) => setReturnAmount(e.target.value)}
                              error={!returnAmountIsValid}
                              helperText={returnAmountIsValid ? '' : returnAmountError}
                              onBlur={validateReturnAmount}
                              variant="filled"
                              fullWidth
                            />
                          </Box>
                          <Box py={2}>
                            <Button onClick={returnPurchase} variant="contained" color="primary" fullWidth>
                              Manuell retur
                            </Button>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box py={2}>
                            <Typography variant="body1">
                              <span style={{ color: 'green' }}>
                                Retur på {returnAmount?.toLocaleString('sv-SE') + ' kr'} skickad
                              </span>
                            </Typography>
                          </Box>
                          <Box py={2}>
                            <Typography variant="h6">Slutför</Typography>
                            <Typography variant="body1">
                              Skriv in OCR-nummer och belopp i kassan för att slutföra returen. När du är klar
                              kan du stänga den här sidan.
                            </Typography>
                            <Grid item md={6}>
                              <Box py={2}>
                                <Button
                                  onClick={this.props.returnStore.returnToStartPage}
                                  variant="outlined"
                                  color="primary"
                                  fullWidth
                                >
                                  Stäng
                                </Button>
                              </Box>
                            </Grid>
                          </Box>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default observer(CompleteReturn);

const noneCursor = {
  cursor: 'default',
};
