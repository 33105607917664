import * as React from 'react';
import { Button, Typography } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import s from './BankIdButton.module.scss';

interface IProps extends ButtonProps {
  isSigning: boolean;
  signingText?: string;
  signingError?: boolean;
  signingErrorText?: string;
}

const BankIdButton: React.SFC<IProps> = (props) => {
  const { className, isSigning, signingText, signingError, signingErrorText, children, ...rest } = props;

  const classes = [s.button];

  if (className) {
    classes.push(className);
  }

  return (
    <>
      <Button className={classes.join(' ')} {...rest}>
        {children}
      </Button>
      {isSigning && !signingError && (
        <Typography variant="body2" className={s.text}>
          {signingText}
        </Typography>
      )}
      {signingError && (
        <Typography variant="body2" className={s.text}>
          <span className={s.error}>{signingErrorText}</span>
        </Typography>
      )}
    </>
  );
};

export default BankIdButton;
