import React from 'react';

export const PageContext = React.createContext<{
  setSubtitle: (title: string) => void;
  setTitle: (title: string) => void;
}>({
  setSubtitle: () => {
    /* void */
  },
  setTitle: () => {
    /* void */
  },
});
