import { Field, FormikProps } from 'formik';
import { TextField, RadioGroup, Checkbox } from 'formik-material-ui';
import { observer } from 'mobx-react';
import * as React from 'react';

import {
  Grid,
  MenuItem,
  Box,
  TextField as MUITextField,
  Radio,
  Chip,
  Typography,
  FormControlLabel,
  Divider,
  Input,
} from '@material-ui/core';

import { EmploymentType, employmentTypes } from 'app/domain';
import { FinancingStore } from './FinancingStore';
import { repaymentSources } from 'app/domain/RepaymentSource';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { countryList, ICountryListItem } from 'app/domain/countries';
import { ApplySchema } from './ApplySchema';
import { ValidationTextField } from 'ui';
import { PhoneField } from 'app/components/phone-field/PhoneField';
import { EmailField } from 'app/components/phone-field/EmailField';

type PersonalFormFieldsProps = FormikProps<ApplySchema> & {
  financingStore: FinancingStore;
  showRepaymentSourceField: boolean;
  showRepaymentSourceOtherField: boolean;
  showEmployerField: boolean;
};

export const PersonalFormFields = observer(
  class PersonalFormFields extends React.Component<PersonalFormFieldsProps> {
    render() {
      const {
        financingStore,
        handleChange,
        showRepaymentSourceOtherField,
        showRepaymentSourceField,
        showEmployerField,
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      } = this.props;

      const handleEmploymentTypeChange =
        (handler: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>) =>
        (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          const { value } = e.target;
          if (value === EmploymentType.Student.id || value === EmploymentType.Unemployed.id) {
            setFieldValue('primaryIncomeRepayment', 'no');
          } else {
            setFieldValue('primaryIncomeRepayment', 'yes');
          }
          handler(e);
        };

      const sortCountries = (list: ICountryListItem[]) => {
        const splitIndex = list.findIndex((item) => item.isDivider);
        const topItems = list.slice(0, splitIndex);

        const sortedList = list.slice(splitIndex, list.length).sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });

        return topItems.concat(sortedList);
      };

      return (
        <>
          <Grid container>
            <Grid item sm={6} xs={12}>
              <Field autoFocus component={PhoneField} name="phoneNumber" />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Field component={EmailField} name="emailAddress" disabled={isSubmitting} />
            </Grid>
            {!financingStore.isCustomerAuthorized && (
              <React.Fragment>
                <Grid item sm={6} xs={12}>
                  <MUITextField
                    value={values.employmentTypeId}
                    onChange={handleEmploymentTypeChange(handleChange)}
                    select
                    label="Anställningsform"
                    name="employmentTypeId"
                    fullWidth
                    variant="filled"
                    disabled={isSubmitting}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {employmentTypes.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </MUITextField>
                </Grid>
                <Grid item sm={6} xs={12}>
                  {showEmployerField && (
                    <Field
                      component={TextField}
                      label="Arbetsgivare"
                      name="employer"
                      autoComplete="off"
                      fullWidth
                      variant="filled"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value?.trim().length) {
                          setFieldValue('employer', e.target.value);
                        } else {
                          setFieldValue('employer', '');
                        }
                      }}
                      onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('employer', e.target.value?.trim())
                      }
                    />
                  )}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Field
                    style={{ paddingBottom: '10px' }}
                    label="Månadsinkomst före skatt"
                    name="monthlyIncomeBeforeTax"
                    component={ValidationTextField}
                    placeholder="Månadsinkomst före skatt"
                    onKeyPress={(event: { key: string; preventDefault: () => void }) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    fullWidth
                    variant="filled"
                    type="number"
                    value={values.monthlyIncomeBeforeTax}
                    InputProps={{
                      inputComponent: Input,
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}></Grid>
                <Grid item sm={6} xs={12}>
                  <Typography variant="body1">
                    Jag kommer återbetala krediten med min huvudsakliga inkomst
                  </Typography>
                  <Field component={RadioGroup} row name="primaryIncomeRepayment" disabled={isSubmitting}>
                    <FormControlLabel disabled={isSubmitting} value="yes" control={<Radio />} label="Ja" />
                    <FormControlLabel disabled={isSubmitting} value="no" control={<Radio />} label="Nej" />
                  </Field>
                </Grid>
                <Grid item sm={6} xs={12}></Grid>
                <Grid item sm={6} xs={12}>
                  {showRepaymentSourceField && (
                    <>
                      <Typography variant="body1">Var kommer pengarna ifrån?</Typography>
                      <Typography variant="body2" color="textSecondary">
                        Välj var pengarna huvudsakligen kommer ifrån som ska användas för att återbetala
                        krediten.
                      </Typography>
                      <Field
                        component={TextField}
                        label="Inkomstkälla"
                        select
                        helperText={' '} // Fixes misalignment in grid on error
                        name="repaymentSource"
                        fullWidth
                        variant="filled"
                      >
                        {repaymentSources.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </>
                  )}
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}
                >
                  {showRepaymentSourceOtherField && (
                    <>
                      <Typography variant="body1">Ange var pengarna kommer ifrån</Typography>
                      <Field
                        label="Annat"
                        component={TextField}
                        name="repaymentSourceOther"
                        autoComplete="off"
                        fullWidth
                        helperText={' '} // Fixes misalignment in grid on error
                        variant="filled"
                      />
                    </>
                  )}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography variant="body1">Ange medborgarskap</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Fyll i alla medborgarskap (max 2 st.)
                  </Typography>
                  <Field
                    onBlur={() => setFieldTouched('citizenships')}
                    onChange={(e: React.ChangeEvent<unknown>, data: string[]) =>
                      setFieldValue('citizenships', data)
                    }
                    name="citizenships"
                    value={values.citizenships}
                    component={Autocomplete}
                    disabled={isSubmitting}
                    options={sortCountries(countryList)}
                    getOptionLabel={(option: ICountryListItem) => option.name}
                    getOptionDisabled={(option: ICountryListItem) => option.isDivider}
                    renderInput={(params: object) => (
                      <MUITextField
                        {...params}
                        variant="outlined"
                        helperText={touched.citizenships && errors.citizenships}
                        error={touched.citizenships && !!errors.citizenships}
                      />
                    )}
                    renderTags={(
                      value: ICountryListItem[],
                      getTagProps: ({ index }: { index: number }) => object
                    ) =>
                      value.map((option: ICountryListItem, index: number) => (
                        <Chip
                          key={option.code}
                          variant="outlined"
                          label={option.name}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderOption={(option: ICountryListItem) => {
                      if (option.isDivider) {
                        return <Divider style={{ width: '100%' }} />;
                      }
                      return option.name;
                    }}
                    multiple
                  />
                </Grid>
                <Grid item sm={6} xs={12}></Grid>
                <Grid item sm={6} xs={12}>
                  <Typography variant="body1">Person i politiskt utsatt ställning (PEP)</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Är du eller har du varit, en person i politiskt utsatt ställning (PEP) eller är du en
                    familjemedlem eller känd medarbetare till en sådan person?
                  </Typography>
                  <Field component={RadioGroup} row name="politicallyExposedPerson">
                    <FormControlLabel disabled={isSubmitting} value="yes" control={<Radio />} label="Ja" />
                    <FormControlLabel disabled={isSubmitting} value="no" control={<Radio />} label="Nej" />
                  </Field>
                </Grid>
                <Grid item sm={6} xs={12}></Grid>
                <Grid item sm={12} xs={12}>
                  <FormControlLabel
                    label="Jag bekräftar att jag inte ansöker om kredit för någon annans räkning"
                    control={
                      <Field
                        type="checkbox"
                        name="thirdPartyDeclaration"
                        component={Checkbox}
                        disabled={isSubmitting}
                      />
                    }
                  />
                </Grid>
              </React.Fragment>
            )}
          </Grid>
          <Box mt={2} />
        </>
      );
    }
  }
);
