let featureFlags: FeatureManagement;
let specialPrivileges: SpecialPrivilegeOption[];
let semiSelfServiceAllowedFor: string | '*';

const isDev = import.meta.env.DEV;

try {
  const temp = JSON.parse(`{"string": "${import.meta.env.VITE_ALLOW_SEMISELFSERVICE_FOR}"}`); // dirty hack to extract Octopus string variable
  semiSelfServiceAllowedFor = temp.string;
  if (isDev) console.log(semiSelfServiceAllowedFor);
} catch {
  console.error('Could not read SemiSelfServiceAllowedFor');
}

try {
  const temp = JSON.parse(`{"object": ${import.meta.env.VITE_FEATURE_MANAGEMENT}}` ?? '{}'); // dirty hack to extract Octopus JSON variable
  featureFlags = temp.object;
  if (isDev) console.log(featureFlags);
} catch {
  console.error('Could not read FeatureManagement');
}

try {
  const temp = JSON.parse(`{"object": ${import.meta.env.VITE_PRIVILEGES}}` ?? '{}'); // dirty hack to extract Octopus JSON variable
  specialPrivileges = temp.object;
  if (isDev) console.log(specialPrivileges);
} catch {
  console.error('Could not read Privileges');
}

export { featureFlags, semiSelfServiceAllowedFor };

export function isAllowedBannersManagement(userId?: string | number) {
  return isAllowed('Banners', userId);
}

export function isAllowedManualSigning(userId?: string | number) {
  return isAllowed('ManualSigningSwitch', userId);
}

function isAllowed(name: PrivilegeName, userId?: string | number) {
  if (isDev) return true;

  if (!userId) return false;

  return Boolean(
    specialPrivileges?.find((p) => p.PrivilegeName === name)?.UserIds.includes(userId.toString())
  );
}

interface FeatureManagement {
  StoreStatisticsEndpointEnabled: boolean;
  StoreStatisticsExportEndpointEnabled: boolean;
  PostApplicationEndpointEnabled: boolean;
  GetApplicationEndpointEnabled: boolean;
  PostApplicationSignatureEndpointEnabled: boolean;
  PostStartMobileSigningApplicationEndpointEnabled: boolean;
  GetMobileSigningStatusEndpointEnabled: boolean;
  GetSigningStatusEndpointEnabled: boolean;
  SendPurchaseSmsEndpointEnabled: boolean;
  PutApplicationEndpoint: boolean;
  DeleteApplicationEndpointEnabled: boolean;
  ContractGetEndpointEnabled: boolean;
  CustomerGetEndpointEnabled: boolean;
  CustomerGetApplicationEndpointEnabled: boolean;
  CustomerGetCardsEndpointEnabled: boolean;
  CustomerGetTransactionsEndpointEnabled: boolean;
  CustomerUpdateTransactionEndpointEnabled: boolean;
  GetCampaignsEndpointEnabled: boolean;
  GetStoresEndpointEnabled: boolean;
  FindUsersEndpointEnabled: boolean;
  GetUserEndpointEnabled: boolean;
  CreateUserEndpointEnabled: boolean;
  UpdateUserEndpointEnabled: boolean;
  ChangePasswordEndpointEnabled: boolean;
  ResetPasswordEndpointEnabled: boolean;
  PostThirdPartyUserEndpointEnabled: boolean;
  GetThirdPartyValidationEndpointEnabled: boolean;
  GetPurchasesEndpointEnabled: boolean;
  PostPurchaseEndpointEnabled: boolean;
  PostRefundWithPurchaseIdEnabled: boolean;
  PostRefundMigratedPurchaseEnabled: boolean;
  GetFuturePaymentsEnabled: boolean;
  UpdateFuturePaymentsEnabled: boolean;
  DelayedPurchaseEnabled: boolean;
  CreateAdminUserEndpointEnabled: boolean;
  AuthenticateWithBankIdEnabled: boolean;
  StorePasswordValidityEnabled: boolean;
  StartAuthenticateWithBankId: boolean;
  GetAuthenticateWithBankIdResult: boolean;
  SendSelfCheckoutLink: boolean;
  PostAuthorizeEndpointEnabled: boolean;
  PostCancelEndpointEnabled: boolean;
  PostCaptureEndpointEnabled: boolean;
  GetApplicationContractEndpointEnabled: boolean;
  GetBannerEndpointEnabled: boolean;
  SetBannerEndpointEnabled: boolean;
  RemoveBannerEndpointEnabled: boolean;
  ManageManualContractEndpointEnabled: boolean;
}

type PrivilegeName = 'Banners' | 'ManualSigningSwitch';

interface SpecialPrivilegeOption {
  PrivilegeName: PrivilegeName;
  UserIds: string[];
}
