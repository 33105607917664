import { Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IProps {
  title?: string;
  entries: Array<{
    label: string;
    smallLabel?: string;
    value: string | number | JSX.Element | undefined;
    labelWidth?: number;
  }>;
}

class List extends React.Component<IProps> {
  render() {
    const { title } = this.props;
    return (
      <>
        {title && <Typography variant="h6">{title}</Typography>}
        <Table padding="normal">
          <TableBody>
            {this.props.entries.map((e, index) => (
              <TableRow style={index % 2 ? { background: '#eef6f8' } : {}} key={index}>
                <TableCell component="th" scope="row" style={e.labelWidth ? { width: e.labelWidth } : {}}>
                  <strong>{e.label}</strong>
                  {e.smallLabel && <small style={{ paddingLeft: '8px' }}>{e.smallLabel}</small>}
                </TableCell>
                <TableCell>{e.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  }
}

export default observer(List);
