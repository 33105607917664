import { createStyles, Grid, Theme, WithStyles, withStyles } from '@material-ui/core';
import RLink from 'app/components/Link';
import { PageTitle } from 'app/components/PageTitle';
import { PageComponent } from 'utils/ComponentUtils';
import { observer } from 'mobx-react';
import Helmet from 'react-helmet';
import { Navigate, Route, Routes } from 'react-router-dom';
import ChangePassword from './ChangePassword';

const styles = (theme: Theme) =>
  createStyles({
    menu: {
      marginBottom: theme.spacing(1),
    },
  });

class SettingsPage extends PageComponent<WithStyles<typeof styles>> {
  render() {
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item md={12}>
          <Helmet>
            <title>Online Service - Inställningar</title>
          </Helmet>
          <PageTitle>Inställningar</PageTitle>

          <Grid container className={classes.menu}>
            <Grid item>
              <RLink to="/settings/change-password">Byt lösenord</RLink>
            </Grid>
          </Grid>

          <Routes>
            <Route path="/change-password" element={<ChangePassword appStore={this.props.appStore} />} />
            <Route path="/" element={<Navigate replace to="change-password" />} />
          </Routes>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(observer(SettingsPage));
