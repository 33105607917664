import { ExportStoreStatisticsRequest, GetStoreStatisticsRequest } from 'app/api/requests/StoreRequests';
import { AppStore } from 'app/AppStore';
import { StoreStatistic } from 'app/domain/StoreStatistic';
import { format } from 'date-fns';
import { makeAutoObservable } from 'mobx';
import { saveAs } from 'file-saver';

class ReportStore {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  appStore: AppStore = undefined!;
  storeStatistics: StoreStatistic[] = [];
  withAppStore = (appStore: AppStore) => {
    this.appStore = appStore;
    return this;
  };

  getStoreStatistics = async () => {
    const req: GetStoreStatisticsRequest = {};
    const storeStatisticsResponse = await this.appStore.api.getStoreStatistics(req);
    if (!storeStatisticsResponse.isSuccess) {
      return;
    }
    this.storeStatistics = storeStatisticsResponse.value;
  };
  exportStoreStatistics = async () => {
    const req: ExportStoreStatisticsRequest = {};
    const storeStatisticsResponse = await this.appStore.api.exportStoreStatistics(req);
    if (!storeStatisticsResponse.isSuccess) {
      return;
    }
    const fileName = `Rapport ${format(new Date(), 'YYYY-MM-DD HH:mm')}.xlsx`;
    saveAs(storeStatisticsResponse.value, fileName);
  };

  constructor() {
    makeAutoObservable(this);
  }
}

const reportStore = new ReportStore();

function getReportStore() {
  return reportStore;
}

export { ReportStore, getReportStore };
