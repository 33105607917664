import { makeStyles } from '@material-ui/core';
import { FieldProps } from 'formik';
import { TextField } from 'formik-material-ui';
import * as React from 'react';

const useGreenOnValid = makeStyles({
  root: {
    '&.valid div:before, &.valid div:after': {
      borderBottomColor: '#47b52e',
    },
    '&.valid label': {
      color: '#257b11',
    },
  },
});

const ValidationTextField = (props: FieldProps) => {
  const classes = useGreenOnValid();

  const error = props.form.errors[props.field.name];
  const dirty = props.form.initialValues[props.field.name] !== props.field.value;

  const isValid = dirty && !error;

  return (
    <TextField
      {...props}
      variant="filled"
      className={isValid ? 'valid' : undefined}
      classes={{ root: classes.root }}
    />
  );
};

export default ValidationTextField;
