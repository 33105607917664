import { Grid, Typography } from '@material-ui/core';
import { PageTitle } from 'app/components/PageTitle';
import { TrainingVideos } from 'app/resources/TrainingVideos';
import { PageComponent } from 'utils/ComponentUtils';
import { observer } from 'mobx-react';
import * as React from 'react';
import Helmet from 'react-helmet';
import { Video } from 'ui';

class TrainingPage extends PageComponent {
  render() {
    return (
      <>
        <Helmet>
          <title>Utbildning</title>
        </Helmet>
        <PageTitle>Utbildning</PageTitle>

        <Grid container>
          <Typography variant="h5" style={{ margin: '25px 0 0 7px' }}>
            Fördelarna för er och era kunder
          </Typography>
        </Grid>

        <Grid container>
          {TrainingVideos.trainingVideos.map((video) => (
            <Video key={video.url} title={video.title} ytlink={video.url} />
          ))}
        </Grid>
      </>
    );
  }
}

export default observer(TrainingPage);
