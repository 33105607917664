import { StoreStatistic } from 'app/domain';
import { FinancingClient } from '../FinancingClient';

export interface ExportStoreStatisticsRequest {
  startDate?: string;
  endDate?: string;
}

export function ExportStoreStatistics(client: FinancingClient, req: ExportStoreStatisticsRequest) {
  const query = {
    ...req,
    chainId: client.chainId,
  };

  return client.request<Blob>({
    url: '/admin/stores/statistics-excel',
    method: 'GET',
    responseType: 'blob',
    params: query,
  });
}

export interface GetStoreStatisticsRequest {
  startDate?: string;
  endDate?: string;
}

export function GetStoreStatistics(client: FinancingClient, req: GetStoreStatisticsRequest) {
  const query = {
    ...req,
    chainId: client.chainId,
  };

  return client.get<StoreStatistic[]>(`/admin/stores/statistics`, query);
}
