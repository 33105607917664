import { AppStore } from 'app/AppStore';
import * as React from 'react';

export interface IAppStoreProps {
  appStore: AppStore;
}

export type PageComponentProps<TProps> = IAppStoreProps & TProps;

abstract class PageComponent<TProps = unknown, TState = unknown> extends React.Component<
  IAppStoreProps & TProps,
  TState
> {}

export { PageComponent };
