import { Formik } from 'formik';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Box, Grid, List as MaterialList, ListItem, ListItemText, Typography } from '@material-ui/core';
import List from 'app/components/List';
import { EmploymentType } from 'app/domain';
import { Country } from 'app/domain/countries';
import { ApplyForm } from './ApplyForm';
import { applySchema, ApplySchema } from './ApplySchema';
import FinancingConditions from './FinancingConditions';
import { FinancingStore } from './FinancingStore';
import { getPhoneNumberWithPrefix } from 'utils/phoneValidation';
import { getCampaignCostDesc } from 'utils/Utils';

interface IProps {
  financingStore: FinancingStore;
}

class ApplyView extends React.Component<IProps> {
  componentDidMount() {
    const { financingStore } = this.props;
    const application = financingStore.financing.application;

    if (application && application.reasonCode != null) {
      financingStore.appStore.router.history.push(`/financing/application/${application.applicationId}`, {
        keepState: true,
      });
    }
  }

  render() {
    const { financingStore } = this.props;
    const { customer, purchaseAmount } = financingStore.financing;

    const svenskaHemChain = '00000515';
    const amount = financingStore.appStore.currentChainId === svenskaHemChain ? '6.000' : '20.000';

    return (
      <>
        <Grid container>
          <Grid item md={4} sm={12} xs>
            <Typography variant="h6">Vikten av rätt information</Typography>
            <MaterialList dense>
              <ListItem>
                <ListItemText>
                  Det är viktigt för oss att vi har korrekta kontaktuppgifter till kunden. Kontrollera med
                  kunden att ni har skrivit in korrekt mobiltelefonnummer samt e-postadress.
                </ListItemText>
              </ListItem>
            </MaterialList>

            <Typography variant="h6">Kreditprövning</Typography>
            <MaterialList dense>
              <ListItem>
                <ListItemText>
                  Vi kreditprövar kunden för upp till {amount} kr i extra kredit utöver köpbelopp.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>Det påverkar inte kundens möjlighet att bli beviljad.</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>Oanvänd kredit kostar inget extra!</ListItemText>
              </ListItem>
            </MaterialList>

            <FinancingConditions />
          </Grid>
          <Grid item md={8} sm={12} xs>
            <Grid container>
              <Grid item sm={12} md={4}>
                <Typography>
                  <strong>{customer.name}</strong>
                </Typography>
                <Typography>{customer.socialSecurityNumber}</Typography>
                <Typography>{customer.address}</Typography>
                <Typography gutterBottom>
                  {customer.zipCode} {customer.city}
                </Typography>
              </Grid>
            </Grid>

            <Formik<ApplySchema>
              validationSchema={applySchema}
              validateOnMount
              initialValues={{
                phoneNumber: getPhoneNumberWithPrefix(customer.phone),
                emailAddress: customer.emailAddress ?? '',
                employmentTypeId: customer.employmentType || EmploymentType.PermanentJob.id,
                employer: customer.employer || '',
                campaignCode: financingStore.financing.campaign ? financingStore.financing.campaign.code : '',
                purchaseAmount: purchaseAmount || 0,
                chainId: financingStore.appStore.user.chains[0].id,
                primaryIncomeRepayment: 'yes',
                repaymentSource: '',
                repaymentSourceOther: '',
                citizenships: [Country.SE],
                politicallyExposedPerson: 'no',
                thirdPartyDeclaration: false,
                isCustomerAuthorized: financingStore.isCustomerAuthorized,
                monthlyIncomeBeforeTax: '' as unknown as number,
              }}
              onSubmit={financingStore.submitApplicationForm}
            >
              {(formikProps) => (
                <ApplyForm
                  financingStore={financingStore}
                  productInformation={this.renderProductInformation()}
                  campaigns={this.tableCampaigns()}
                  formikProps={formikProps}
                />
              )}
            </Formik>
          </Grid>
        </Grid>
      </>
    );
  }

  renderProductInformation() {
    const { purchaseAmount, campaign } = this.props.financingStore.financing;
    const cost = campaign && campaign.cost;

    return (
      <>
        <Typography variant="h6">Produktinformation</Typography>

        <Grid container>
          <Grid item sm={12}>
            <Box pr={3}>
              <List
                entries={[
                  { label: 'Uppläggningsavgift', value: campaign && campaign.startupFee + ' kr' },
                  { label: 'Administrationsavgift', value: campaign && campaign.billingFee + ' kr' },
                  { label: 'Ränta', value: campaign && campaign.interest + ' %' },
                  {
                    label: 'Effektiv ränta',
                    smallLabel: `vid ${purchaseAmount} kr`,
                    value: campaign && campaign.effectiveInterest1 + ' %',
                  },
                  { label: 'Total kostnad', value: cost && cost.totalCost + ' kr' },
                ]}
              />
            </Box>
            <Box mb={4} />
          </Grid>
        </Grid>
      </>
    );
  }

  tableCampaigns = () => {
    const campaigns = this.props.financingStore.campaigns;

    if (!campaigns) {
      return [];
    }

    return campaigns.map((campaign) => [
      getCampaignCostDesc(campaign),
      campaign.name,
      campaign.code,
      campaign.campaignType,
    ]);
  };
}

export default observer(ApplyView);
