import { Formik } from 'formik';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Grid } from '@material-ui/core';

import FinancingConditions from '../FinancingConditions';
import { FinancingStore } from '../FinancingStore';
import { IdentificationForm } from './parts/IdentificationForm';
import { IdentificationSchema, identificationSchema } from './parts/IdentificationSchema';
import SemiSelfServiceDialog from './semi-self-service-dialog/SemiSelfServiceDialog';
import OngoingApplicationsDialog from './parts/OngoingApplicationsDialog';

interface IProps {
  financingStore: FinancingStore;
}

class IdentificationView extends React.Component<IProps> {
  componentDidMount() {
    const { financingStore } = this.props;
    financingStore.resetState();
  }

  render() {
    const { financingStore } = this.props;

    return (
      <>
        <Grid container spacing={3}>
          <Grid item md={6} sm={6} xs>
            <FinancingConditions />
          </Grid>
          <Grid item md={6} sm={6} xs>
            <Formik<IdentificationSchema>
              validationSchema={identificationSchema}
              initialValues={{
                socialSecurityNumber: '',
                purchaseAmount: '' as unknown as number,
                newApplication: true,
              }}
              validateOnBlur={!financingStore.startApplicationFailed}
              validateOnChange={!financingStore.startApplicationFailed}
              onSubmit={financingStore.submitIdentificationForm}
            >
              {(formikProps) => <IdentificationForm financingStore={financingStore} {...formikProps} />}
            </Formik>
          </Grid>
        </Grid>
        <SemiSelfServiceDialog financingStore={financingStore} />
        <OngoingApplicationsDialog financingStore={financingStore} />
      </>
    );
  }
}

export default observer(IdentificationView);
