import { AppStore } from 'app/AppStore';
import Axios, { AxiosError } from 'axios';

function setupAxiosInterceptors(appState: AppStore) {
  Axios.defaults.baseURL = import.meta.env.VITE_API_BASEURL;

  Axios.interceptors.request.use(
    (config) => {
      appState.startLoading();
      return config;
    },
    (error: AxiosError) => {
      appState.finishedLoading();
      return Promise.reject(error);
    }
  );
  Axios.interceptors.response.use(
    (config) => {
      appState.finishedLoading();
      return config;
    },
    (error: AxiosError) => {
      appState.finishedLoading();

      return Promise.reject(error);
    }
  );
}

export { setupAxiosInterceptors };
