import * as React from 'react';
import { ReactComponent as FileTextSvg } from '../resources/file-text.inline.svg';
import s from './DocumentLink.module.scss';

interface IProps {
  title: string;
  description: string;
  url: string;
}

const DocumentLink: React.SFC<IProps> = (props) => (
  <a target="_blank" rel="noopener noreferrer" href={props.url} className={s.item}>
    <i>
      <FileTextSvg />
    </i>
    <div className={s.texts}>
      <span className={s.title}>{props.title}</span>
      <span className={s.description}>{props.description}</span>
    </div>
  </a>
);

export { DocumentLink };
