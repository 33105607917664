import { AppStore } from 'app/AppStore';
import { FuturePayment } from 'app/domain';
import { makeAutoObservable } from 'mobx';
import { FinancingStore } from '../financing/FinancingStore';

class FuturePaymentStore {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  appStore: AppStore = undefined!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  financingStore: FinancingStore = undefined!;
  purchase?: { applicationId: string };

  constructor() {
    makeAutoObservable(this);
  }

  withAppStore(appStore: AppStore) {
    this.appStore = appStore;

    return this;
  }

  withFinancingStore(financingStore: FinancingStore) {
    this.financingStore = financingStore;

    return this;
  }

  completePurchase = async (purchase: FuturePayment) => {
    //Set this flag to false as it breaks labels
    this.financingStore.hasPostponedPayment = false;

    this.purchase = purchase;
    this.appStore.router.push(`/future-payments/application/${purchase.applicationId}`);
  };
  returnToStartPage = () => {
    this.appStore.router.push(`/`);
  };
}

const futurePaymentState = new FuturePaymentStore();

function getFuturePaymentStore() {
  return futurePaymentState;
}

export { FuturePaymentStore, getFuturePaymentStore };
