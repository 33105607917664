import { CURRENT_TENANT, TenantsEnum } from 'app/Tenant';

export const content = () => {
  const manuals: { title: string; description: string; url: string }[] = [];

  if (CURRENT_TENANT === TenantsEnum.NOS) {
    manuals.push({
      title: 'Användarmanual',
      description: 'Manual för nya versionen av Online Service',
      url: '/resources/pdf/OSManualen_Feb2023 - NETS MEDIA MARKT v.3.X L_k2.pdf',
    });
    manuals.push({
      title: 'Användarmanual - Kort version',
      description: 'Manual för nya versionen av Online Service, kort version',
      url: '/resources/pdf/OSManualen_Sept2022 - NETS MEDIA MARKT v.3.X K_SR.pdf',
    });
    manuals.push({
      title: 'Self-service i Butik',
      description: 'Kundens Self-service ansökningsflöde i butik',
      url: '/resources/pdf/Semi-self service Nets.pdf',
    });
    manuals.push({
      title: 'FAQ',
      description: 'Svaren på de vanligaste förekommande frågorna.',
      url: '/resources/pdf/FAQ MediaMarkt.pdf',
    });
  } else {
    manuals.push({
      title: 'Användarmanual OCR',
      description: 'Manual för nya versionen av Online Service för butiker med OCR betalningslösning',
      url: '/resources/pdf/OSManualen_November2023 – OCR v.1_k5.pdf',
    });
    manuals.push({
      title: 'Self-service i Butik - OCR',
      description: 'Kundens Self-service ansökningsflöde för OCR anslutna butiker',
      url: '/resources/pdf/Semi-self service OCR.pdf',
    });
    manuals.push({
      title: 'Användarmanual NETS',
      description: 'Manual för nya versionen av Online Service för butiker med NETS betalningslösning',
      url: '/resources/pdf/OSManualen_November2023 – Nets v.1_k5.pdf',
    });
    manuals.push({
      title: 'Self-service i Butik - NETS',
      description: 'Kundens Self-service ansökningsflöde för NETS anslutna butiker',
      url: '/resources/pdf/Semi-self service Nets.pdf',
    });
    manuals.push({
      title: 'Så här fungerar “Köp nu, betala senare',
      description: 'Lär dig hur kampanjen "Köp nu, betala senare" fungerar.',
      url: '/resources/pdf/Så här fungerar BNPL - 17102023.pdf',
    });
  }

  manuals.push({
    title: 'Bra att veta',
    description: 'Generell information från Santander',
    url: '/resources/pdf/BraAttVeta.pdf',
  });
  manuals.push({
    title: 'Kundförändringar',
    description: 'Hur påverkas våra slutkunder av förändringen?',
    url: '/resources/pdf/Förändringar för slutkunden.pdf',
  });
  manuals.push({
    title: 'Hantera användare i Online Service (NOS)',
    description: 'Denna manual beskriver hur du som Administratör kan hantera användare i Online service.',
    url: '/resources/pdf/Hantera användare i Online Service.pdf',
  });

  return manuals;
};
