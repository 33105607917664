import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import * as React from 'react';
import { LinkProps } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
/**
 * Wraps a Link from react-router-dom, but adds
 * additional styling to it.
 */
const RLink: React.SFC<LinkProps> = (props) => {
  const { className, children, to } = props;
  return (
    <Typography color="primary">
      <Link component={RouterLink} className={className || ''} to={to}>
        {children}
      </Link>
    </Typography>
  );
};

export default RLink;
