import { AppStore } from 'app/AppStore';
import { makeAutoObservable } from 'mobx';
import type { Purchase } from 'app/domain';

class ReturnStore {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  appStore: AppStore = undefined!;
  purchase?: Purchase;
  ssn?: string;
  chainId?: string;

  constructor() {
    makeAutoObservable(this);
  }

  withAppStore(appStore: AppStore) {
    this.appStore = appStore;

    return this;
  }

  completeReturn = (purchase: Purchase, ssn: string, chainId: string) => {
    this.purchase = purchase;
    this.ssn = ssn;
    this.chainId = chainId;
    this.appStore.router.push(`/return/complete`);
  };

  returnToStartPage = () => {
    this.appStore.router.push(`/`);
  };
}

const returnState = new ReturnStore();

function getReturnStore() {
  return returnState;
}

export { ReturnStore, getReturnStore };
