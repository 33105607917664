import { SchemaType } from 'utils/TypeUtils';
import { FormikHelpers as FormikActions } from 'formik';
import { array, object, string } from 'yup';
import { emailSchema } from 'app/components/phone-field/EmailField';

export type EditUserSchema = SchemaType<typeof editUserSchema>;

export type ApplyActions = FormikActions<EditUserSchema>;

export const editUserSchema = object({
  username: string().required('Du måste ange Användarnamn').max(20, 'Användarnamn får max vara 20 tecken.'),
  firstName: string().required('Du måste ange Förnamn').max(25, 'Förnamn får max vara 25 tecken.'),
  lastName: string().required('Du måste ange Efternamn').max(25, 'Efternamn får max vara 25 tecken.'),
  email: emailSchema,
  sellerId: string().max(20, 'Säljar ID får max vara 20 tecken.').nullable(),
  userId: string(),
  stores: array().required(),
});
