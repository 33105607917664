import React from 'react';
import { NavigateFunction, Params, useLocation, useNavigate, useParams } from 'react-router-dom';

export interface RouteComponentProps {
  location: Location;
  navigate: NavigateFunction;
  params: Readonly<Params<string>>;
}

export function withRouter<TProps>(Component: React.ComponentType<TProps>) {
  function ComponentWithRouterProp(props: Omit<TProps, keyof RouteComponentProps>) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...(props as TProps)} location={location} navigate={navigate} params={params} />;
  }

  return ComponentWithRouterProp;
}
