import { Grid, Typography } from '@material-ui/core';
import { PageTitle } from 'app/components/PageTitle';
import { PageComponent } from 'utils/ComponentUtils';
import { observer } from 'mobx-react';
import Helmet from 'react-helmet';
import { CURRENT_TENANT, TenantsEnum } from 'app/Tenant';

class ErrorPage extends PageComponent {
  render() {
    const {
      appStore: { error },
    } = this.props;

    return (
      <>
        <Helmet>
          <title>Online Service - Tillfälligt fel</title>
        </Helmet>
        <PageTitle>Tillfälligt fel</PageTitle>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Typography>
              Tyvärr har ett tekniskt problem uppstått och vi jobbar med att åtgärda felet så fort som
              möjligt.
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="h6">Ny ansökan</Typography>
            <Typography>
              Fyll i kontoansökan och skriv ut den från webbshopen för butiksmaterial.
              <br />
              Ring Santander Consumer Bank Butiksservice för kreditprövning,
              <br />
              {CURRENT_TENANT === TenantsEnum.OS ? '08-559 513 23' : '08-525 03 200'}.
              <br />
              Du hittar kontoansökan på{' '}
              <a href="http://santander.store.elanders.com">santander.store.elanders.com</a>
              <br />
              Logga in med ert butiksnummer, både som användarnamn och lösenord.
              <br />
              I menyn till vänster välj Ansökningsmaterial.
              <br />
              Välj därefter Kontoansökan (utskrivbar PDF) och klicka på moln-ikonen för att öppna, fylla i och
              skriva ut ansökan.
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="h6">Återuppta ansökan</Typography>
            <Typography>
              Ring Butiksservice {CURRENT_TENANT === TenantsEnum.OS ? '08-559 513 23' : '08-525 03 200'}.
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="h6">Befintligt konto</Typography>
            <Typography>
              Ring Butiksservice {CURRENT_TENANT === TenantsEnum.OS ? '08-559 513 23' : '08-525 03 200'}.
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="h6">Övrigt</Typography>
            <Typography>
              Om kunden vill prata direkt med Santander Consumer Bank, be kunden ringa 08-559 514 00.
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="h6">Teknisk information</Typography>
            {error && <Typography>{JSON.stringify(error)}</Typography>}
            {!error && <Typography>Saknas</Typography>}
          </Grid>
        </Grid>
      </>
    );
  }
}

export default observer(ErrorPage);
