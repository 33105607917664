export const AccommodationType = {
  Condominium: {
    id: 'Condominium',
    name: 'Bostadsrätt',
  },
  OwnedHouse: {
    id: 'OwnedHouse',
    name: 'Boende i eget hus/egen fastighet',
  },
  Tenancy: {
    id: 'Tenancy',
    name: 'Hyresrätt via ett förstahandskontrakt',
  },
  SecondhandApartment: {
    id: 'SecondhandApartment',
    name: 'Hyresrätt via ett andrahandskontrakt',
  },
  CollectiveHousing: {
    id: 'CollectiveHousing',
    name: 'Boende i ett kollektiv (delat boende)',
  },
};

export interface AccommodationType {
  id: string;
  name: string;
}

export const accommodationTypes: AccommodationType[] = [
  AccommodationType.Condominium,
  AccommodationType.OwnedHouse,
  AccommodationType.Tenancy,
  AccommodationType.SecondhandApartment,
  AccommodationType.CollectiveHousing,
];
