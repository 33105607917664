import { FinancingClient } from './FinancingClient';

import {
  ChangePassword,
  ChangePasswordRequest,
  CreateApplication,
  CreateApplicationRequest,
  CreateUser,
  CreateUserRequest,
  ValidateEmail,
  DeleteUser,
  DeleteUserRequest,
  DeleteApplication,
  DeleteApplicationRequest,
  ExportStoreStatistics,
  ExportStoreStatisticsRequest,
  FindUsers,
  FindUsersRequest,
  GetApplication,
  GetCampaigns,
  GetCampaignsRequest,
  GetCustomer,
  GetCustomerApplications,
  GetCustomerApplicationsRequest,
  GetCustomerRequest,
  GetStoreStatistics,
  GetStoreStatisticsRequest,
  GetUser,
  GetUserRequest,
  InitiateSignature,
  InitiateSignatureRequest,
  PollSignature,
  PollSignatureRequest,
  ResetUserPassword,
  ResetUserPasswordRequest,
  UpdateApplication,
  UpdateApplicationRequest,
  UpdateUser,
  UpdateUserRequest,
  GetPurchases,
  GetPurchasesRequest,
  RefundPurchase,
  RefundPurchaseRequest,
  SendPurchaseSms,
  PostPurchaseCLS,
  PostPurchaseCLSRequest,
  SendPurchaseSmsRequest,
  GetFuturePayments,
  GetFuturePaymentsRequest,
  CancelApplicationRequest,
  DelayedPurchaseRequest,
  DelayedPurchase,
  GetApplicationContract,
  GetBanner,
  GetBannerRequest,
  UpdateBanner,
  UpdateBannerRequest,
  DeleteBannerRequest,
  DeleteBanner,
  CancelApplication,
  PostponeApplication,
  PostponeApplicationRequest,
  ReservePurchase,
  ReservePurchaseRequest,
  CancelPurchase,
  CancelPurchaseRequest,
  UpdateAdditionalInfoRequest,
  UpdateAdditionalInfo,
} from './requests';
import {
  GetManualSigning,
  GetManualSigningRequest,
  UpdateManualSigning,
  UpdateManualSigningRequest,
} from './requests/CacheRequests';
import { SendSelfCheckoutLink, SendSelfCheckoutLinkRequest } from './requests/SendSelfCheckoutLinkRequest';

export class FinancingApi {
  client = new FinancingClient();

  constructor(client: FinancingClient) {
    this.client = client;
  }

  getApplication(applicationId: string) {
    return GetApplication(this.client, applicationId);
  }

  getApplicationContract(applicationId: string) {
    return GetApplicationContract(this.client, applicationId);
  }

  createApplication(req: CreateApplicationRequest) {
    return CreateApplication(this.client, req);
  }

  initiateSignature(req: InitiateSignatureRequest) {
    return InitiateSignature(this.client, req);
  }

  pollSignature(req: PollSignatureRequest) {
    return PollSignature(this.client, req);
  }

  updateApplication(req: UpdateApplicationRequest) {
    return UpdateApplication(this.client, req);
  }

  deleteApplication(req: DeleteApplicationRequest) {
    return DeleteApplication(this.client, req);
  }

  getCustomer(req: GetCustomerRequest) {
    return GetCustomer(this.client, req);
  }

  getCustomerApplications(req: GetCustomerApplicationsRequest) {
    return GetCustomerApplications(this.client, req);
  }

  getCampaigns(req: GetCampaignsRequest) {
    return GetCampaigns(this.client, req);
  }
  // admin api

  updateBanner(req: UpdateBannerRequest) {
    return UpdateBanner(this.client, req);
  }

  getBanner(req: GetBannerRequest) {
    return GetBanner(this.client, req);
  }

  deleteBanner(req: DeleteBannerRequest) {
    return DeleteBanner(this.client, req);
  }

  getStoreStatistics(req: GetStoreStatisticsRequest) {
    return GetStoreStatistics(this.client, req);
  }

  updateManualSigning(req: UpdateManualSigningRequest) {
    return UpdateManualSigning(this.client, req);
  }

  getManualSigning(req: GetManualSigningRequest) {
    return GetManualSigning(this.client, req);
  }

  exportStoreStatistics(req: ExportStoreStatisticsRequest) {
    return ExportStoreStatistics(this.client, req);
  }

  createUser(req: CreateUserRequest) {
    return CreateUser(this.client, req);
  }

  validateEmail(req: CreateUserRequest) {
    return ValidateEmail(this.client, req);
  }

  deleteUser(req: DeleteUserRequest) {
    return DeleteUser(this.client, req);
  }

  updateUser(req: UpdateUserRequest) {
    return UpdateUser(this.client, req);
  }

  getUser(req: GetUserRequest) {
    return GetUser(this.client, req);
  }

  findUsers(req: FindUsersRequest) {
    return FindUsers(this.client, req);
  }

  changePassword(req: ChangePasswordRequest) {
    return ChangePassword(this.client, req);
  }

  resetUserPassword(req: ResetUserPasswordRequest) {
    return ResetUserPassword(this.client, req);
  }

  getPurchases(req: GetPurchasesRequest) {
    return GetPurchases(this.client, req);
  }

  refundPurchase(req: RefundPurchaseRequest) {
    return RefundPurchase(this.client, req);
  }

  postPurchaseCLS(req: PostPurchaseCLSRequest) {
    return PostPurchaseCLS(this.client, req);
  }

  sendPurchaseSms(req: SendPurchaseSmsRequest) {
    return SendPurchaseSms(this.client, req);
  }

  postponeApplication(req: PostponeApplicationRequest) {
    return PostponeApplication(this.client, req);
  }

  reservePurchase(req: ReservePurchaseRequest) {
    return ReservePurchase(this.client, req);
  }

  cancelPurchase(req: CancelPurchaseRequest) {
    return CancelPurchase(this.client, req);
  }

  cancelApplicaton(req: CancelApplicationRequest) {
    return CancelApplication(this.client, req);
  }

  getFuturePayments(req: GetFuturePaymentsRequest) {
    return GetFuturePayments(this.client, req);
  }

  delayedPurchase(req: DelayedPurchaseRequest) {
    return DelayedPurchase(this.client, req);
  }

  sendSelfCheckoutLink(req: SendSelfCheckoutLinkRequest) {
    return SendSelfCheckoutLink(this.client, req);
  }

  updateAdditionalInfo(req: UpdateAdditionalInfoRequest) {
    return UpdateAdditionalInfo(this.client, req);
  }
}
