import { Button, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Alert } from 'app/domain/Alert';
import { FinancingStore } from '../../FinancingStore';
import { SigningMethod } from 'app/domain/SigningMethod';
import { useTracking } from 'utils/GA';
import { TrackingAction, TrackingCategory } from 'app/resources/Tracking';

const dataLayer = useTracking(window.dataLayer);

interface IProps {
  financingStore: FinancingStore;
}

class ShredApplicationButton extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  state: { contractDeliveryMethod: SigningMethod } = {
    contractDeliveryMethod: SigningMethod.WEB,
  };

  showDeleteAlert = () => {
    dataLayer.pushInteraction(TrackingCategory.FinancingApplication, TrackingAction.ShredApplication);
    const { financingStore } = this.props;
    financingStore.loadApplication(financingStore.financing.application.applicationId);

    const showDeleteAlert: Alert = {
      title: 'Är du säker på att du vill ta bort ansökan?',
      content: <Typography>Ansökan kommer att tas bort.</Typography>,
      acceptCallback: async () => {
        dataLayer.pushInteraction(TrackingCategory.FinancingApplication, TrackingAction.DeleteApplication);
        financingStore.keepPollingForSignature = false;
        await financingStore.deleteApplication();
      },
    };
    financingStore.appStore.showAlert(showDeleteAlert);
  };

  render() {
    const { financingStore } = this.props;

    return (
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        onClick={this.showDeleteAlert}
        disabled={financingStore.isSubmitting}
      >
        Makulera ansökan
      </Button>
    );
  }
}

export default observer(ShredApplicationButton);
