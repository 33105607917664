import MaskedInput, { MaskedInputProps } from 'react-text-mask';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MaskedSsnInput = (props: Omit<MaskedInputProps, 'mask'> & { inputRef?: any }) => {
  const { inputRef, ...other } = props;
  const num = /\d/;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[num, num, num, num, num, num, num, num, '-', num, num, num, num]}
    />
  );
};
