import { ChangePasswordRequest } from 'app/api/requests';
import { AppStore } from 'app/AppStore';
import { Alert } from 'app/domain/Alert';
import { FormikHelpers as FormikActions } from 'formik';
import { makeAutoObservable } from 'mobx';
import { ChangePasswordSchema } from './ChangePasswordSchema';

class ChangePasswordStore {
  appStore: AppStore;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: { text: string; value?: any } = undefined;

  constructor(appStore: AppStore) {
    makeAutoObservable(this);

    this.appStore = appStore;
  }

  submitChangePasswordForm = async (
    changePasswordForm: ChangePasswordSchema,
    actions: FormikActions<ChangePasswordSchema>
  ) => {
    const req: ChangePasswordRequest = {
      ...changePasswordForm,
    };
    const changePasswordResponse = await this.appStore.api.changePassword(req);
    actions.setSubmitting(false);
    if (!changePasswordResponse.isSuccess) {
      this.error = { text: 'Kunde inte byta lösenord', value: changePasswordResponse };

      return;

      //if(changePasswordResponse.statusCode && changePasswordResponse.statusCode === 500) {
      //    this.error = { text: 'Kunde inte byta lösenord', value: changePasswordResponse };
      //   return;
      //} else {
      //    this.error = { text: 'Kunde inte byta lösenord', value: changePasswordResponse };
      //    return;
      //}
    }
    const showPasswordAlert: Alert = {
      title: 'Lösenordet har bytts!',
      content: '',
      hideCancel: true,
      acceptCallback: () => {
        actions.resetForm();
      },
    };
    this.appStore.showAlert(showPasswordAlert);
  };
}

export { ChangePasswordStore };
