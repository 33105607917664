import React from 'react';
import { observer } from 'mobx-react';
import { PageComponent } from 'utils/ComponentUtils';

const bannerStyle = {
  width: '100%',
  textAlign: 'center',
  padding: '10px',
  margin: '5px',
  border: '2px solid red',
  background: '#ffffe0',
  fontSize: '1.20em',
  overflowWrap: 'break-word',
} as const;

class Banner extends PageComponent {
  render() {
    const { appStore } = this.props;

    const banner = appStore.globalBanner || appStore.tenantBanner;

    if (banner) {
      return <div style={bannerStyle}>{banner.message}</div>;
    }

    return null;
  }
}

export default observer(Banner);
