import { Failure, Ok, ResultPromise } from 'utils/Result';
import Axios, { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';

export class FinancingClient {
  chainId = '';

  storeId = '';

  token?: string;

  get<T = unknown>(path: string, params?: object): ResultPromise<T> {
    return this.request({ url: path, method: 'GET', params });
  }

  put<T = unknown>(path: string, data?: object, params?: object): ResultPromise<T> {
    return this.request({ url: path, method: 'PUT', data, params });
  }

  post<T = unknown>(path: string, data?: object): ResultPromise<T> {
    return this.request({ url: path, method: 'POST', data });
  }

  delete<T = unknown>(path: string, params?: object): ResultPromise<T> {
    return this.request({ url: path, method: 'DELETE', params, data: {} });
  }

  /**
   * Performs an axios request, but maps the axios response into a ResultPromise<any> (Promise<Result<any>>).
   * The params are the query parameters (e.g. ?id=2&name=test) and data is the json body (in case of PUT, POST).
   */
  request<T = unknown>(args: AxiosRequestConfig): ResultPromise<T> {
    return new Promise((resolve) => {
      Axios.request({
        ...args,
        headers: {
          Authorization: `Bearer ${this.token}`,
          'api-version': 1,
          'Ocp-Apim-Subscription-Key': import.meta.env.VITE_API_SUBSCRIPTION_KEY,
          ...(args.headers as RawAxiosRequestHeaders),
        },
      })
        .then((result) => {
          resolve(Ok(result.data));
        })
        .catch((err) => {
          if (!err.response) {
            resolve(Failure());
          } else {
            resolve(Failure(err.response.data, err.response.status));
          }
        });
    });
  }
}
