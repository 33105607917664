import { observer } from 'mobx-react';
import React from 'react';
import { Button, Grid, Typography, Box, TextField } from '@material-ui/core';
import { PaymentMethod } from 'app/domain';
import { FinancingStore } from '../../FinancingStore';

interface IProps {
  financingStore: FinancingStore;
  futurePayment?: boolean;
  title: string;
}

class ApplicationSigned extends React.Component<IProps> {
  render() {
    const { financingStore, futurePayment = false, title } = this.props;

    const { paymentMethod } = financingStore.appStore.user;

    const isNets = paymentMethod === PaymentMethod.Nets;
    const isOcr = paymentMethod === PaymentMethod.Ocr;

    return (
      <>
        <Grid item md={12}>
          <Typography>{title}</Typography>
        </Grid>
        <Grid item>
          <Box py={2}>
            <Grid container direction="column" justifyContent="flex-start">
              <Grid item>
                <Box py={2}>
                  <Box py={2}>
                    <Typography variant="h6">Slutför betalningen</Typography>
                  </Box>
                  <Grid container item spacing={5} alignItems="center">
                    {futurePayment ? (
                      <>
                        <Grid item md={4}>
                          {isOcr && (
                            <TextField
                              value={financingStore.ocrPurchaseAmount}
                              style={{ paddingBottom: '10px' }}
                              label="Köpesumma"
                              placeholder="Köpesumma"
                              variant="filled"
                              type="number"
                              error={!financingStore.ocrAmountIsValid}
                              helperText={
                                financingStore.ocrAmountIsValid ? '' : financingStore.ocrAmountError
                              }
                              onChange={(e) => financingStore.setOcrPurchaseAmount(e.target.value)}
                              onBlur={financingStore.validateOcrPurchaseAmount}
                            />
                          )}
                          <Button
                            onClick={financingStore.delayedPurchase}
                            variant="contained"
                            style={{ backgroundColor: 'green', color: 'white' }}
                            fullWidth
                          >
                            slutför betalning
                          </Button>
                        </Grid>
                        <Grid item md={8}>
                          <Typography>
                            {isNets
                              ? 'Kunden kommer att få ett SMS med Köp-id, be kunden slutföra betalning i terminalen'
                              : 'Välj att slutföra betalningen om varan kan lämnas ut. Köpesumman motsvarar det sökta beloppet men kan justeras inom tillgänglig kredit. Den angivna köpesumman måste överensstämma med kassakvittot.'}
                          </Typography>
                        </Grid>
                        <Grid item md={4}>
                          <Button
                            onClick={financingStore.returnToPaymentList}
                            variant="contained"
                            color="primary"
                            fullWidth
                          >
                            AVBRYT
                          </Button>
                        </Grid>
                        <Grid item md={8}>
                          <Typography>Avbryt och återgå till listan.</Typography>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item md={4}>
                          {isOcr && (
                            <TextField
                              value={financingStore.ocrPurchaseAmount}
                              style={{ paddingBottom: '10px' }}
                              label="Köpesumma"
                              placeholder="Köpesumma"
                              variant="filled"
                              type="number"
                              error={!financingStore.ocrAmountIsValid}
                              helperText={
                                financingStore.ocrAmountIsValid ? '' : financingStore.ocrAmountError
                              }
                              onChange={(e) => financingStore.setOcrPurchaseAmount(e.target.value)}
                              onBlur={financingStore.validateOcrPurchaseAmount}
                            />
                          )}
                          <Button
                            disabled={financingStore.isSubmittingPurchase}
                            onClick={
                              isNets
                                ? financingStore.sendSmsWithPurchaseCode
                                : financingStore.submitPurchaseCLS
                            }
                            variant="contained"
                            className="success-button"
                            fullWidth
                          >
                            Slutför betalning
                          </Button>
                        </Grid>
                        <Grid item md={8}>
                          <Typography>
                            {isNets
                              ? 'Kunden kommer att få ett SMS med Köp-id, be kunden slutföra betalning i terminalen'
                              : 'Välj att slutföra betalningen om varan kan lämnas ut. Köpesumman motsvarar det sökta beloppet men kan justeras inom tillgänglig kredit. Den angivna köpesumman måste överensstämma med kassakvittot.'}
                          </Typography>
                        </Grid>
                        <Grid item md={4}>
                          <Typography variant="h6">Skjut upp betalning</Typography>
                          <br />
                          <Button
                            disabled={financingStore.isSubmittingPurchase}
                            onClick={financingStore.postponePurchase}
                            variant="contained"
                            color="primary"
                            fullWidth
                          >
                            Skjut upp betalning
                          </Button>
                        </Grid>
                        <Grid item md={8}>
                          <Typography>
                            {isNets
                              ? 'Välj att skjuta upp betalning om varan inte kan lämnas ut.'
                              : 'Välj att skjuta upp betalningen om varan inte kan lämnas ut, köpet kan då återupptas och genomföras vid en senare tidpunkt när varan kan lämnas ut.'}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </>
    );
  }
}

export default observer(ApplicationSigned);
