import { ApplicationBrief, Customer } from 'app/domain';
import { FinancingClient } from '../FinancingClient';

// get customer

export interface GetCustomerRequest {
  socialSecurityNumber: string;
  purchaseAmount: number;
}

export function GetCustomer(client: FinancingClient, req: GetCustomerRequest) {
  const query = {
    ...req,
    storeId: client.storeId,
    chainId: client.chainId,
  };

  return client.get<Customer>(`/customers`, query);
}

// get applications for a customer

export interface GetCustomerApplicationsRequest {
  chainId: string;
  socialSecurityNumber: string;
}

export function GetCustomerApplications(client: FinancingClient, req: GetCustomerApplicationsRequest) {
  const query = {
    chainId: req.chainId,
  };

  return client.get<ApplicationBrief[]>(`/customers/${req.socialSecurityNumber}/applications`, query);
}

export interface AuthenticateWithBankIdRequest {
  ssn: string;
}
