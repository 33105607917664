import { observer } from 'mobx-react';
import React from 'react';
import { Button, Grid, Typography, Box } from '@material-ui/core';
import { DecisionCode, PaymentMethod } from 'app/domain';
import ReasonCode from 'app/domain/ReasonCode';
import { ErrorText } from 'ui';
import ApplicationPending from './parts/ApplicationPending';
import FinancingConditions from '../FinancingConditions';
import { FinancingStore } from '../FinancingStore';
import SalesScoringType from 'app/domain/SalesScoringType';
import ApplicationDetails from './parts/ApplicationDetails';
import AccountDetails from './parts/AccountDetails';
import ApplicationSigned from './parts/ApplicationSigned';
import ApplicationApproved from '../application-approved/ApplicationApproved';
import ApplicantDetails from './parts/ApplicantDetails';
import DecisionText from './parts/DecisionText';
import { RouteComponentProps, withRouter } from 'app/components/withRouter';
import { Navigate } from 'react-router';
import PurchaseSubmitted from './parts/PurchaseSubmitted';
import LinearProgress from '@material-ui/core/LinearProgress';

interface IProps extends RouteComponentProps {
  financingStore: FinancingStore;
  futurePayment?: boolean;
}

class ApplicationView extends React.Component<IProps> {
  componentDidMount() {
    const { financingStore } = this.props;
    const applicationId = this.props.params.applicationId;

    financingStore.loadApplication(applicationId);
  }

  componentWillUnmount() {
    this.props.financingStore.resetSubmittedPurchase();
  }

  render() {
    const { financingStore, futurePayment = false } = this.props;
    const { application } = financingStore.financing;
    const { paymentMethod } = financingStore.appStore.user;

    if (!application.applicationId) {
      return <></>;
    }

    const isBej =
      application.decisionCode === DecisionCode.Approved &&
      application.reasonCode === ReasonCode.ContactBank &&
      financingStore.signApplicationFailed;

    const isApproved = application.decisionCode === DecisionCode.Approved && !isBej;
    const isDenied = application.decisionCode === DecisionCode.Denied;
    const isPending = application.decisionCode === DecisionCode.Pending;
    const isNets = paymentMethod === PaymentMethod.Nets;

    const isAuthorization = application.salesScoringType === SalesScoringType.Authorization;
    const isLimitIncrease = application.salesScoringType === SalesScoringType.LimitIncrease;

    const showKontoBox = (isLimitIncrease || isAuthorization) && isApproved;

    // Signing step is skipped if the application is a future payment
    let isSigned = futurePayment;

    if (isApproved && application.reasonCode === ReasonCode.Activated) {
      isSigned = true;
    }

    let signedTitle = 'Kundens ansökan är nu godkänd och signerad. Du ska nu slutföra betalningen.';

    if (!futurePayment) {
      if (isNets) {
        signedTitle = 'Nu är ansökan klar. Du kan nu slutföra köpet i kassan eller skjuta upp betalning.';
      } else {
        signedTitle =
          'Kundens ansökan är nu godkänd och signerad. Du ska nu välja att slutföra betalningen eller skjuta upp betalningen.';
      }
    }

    const isHighRiskCustomer =
      application.decisionCode === DecisionCode.Pending &&
      application.reasonCode === ReasonCode.AdditionalInfo;

    if (isHighRiskCustomer) {
      return <Navigate replace to={`/financing/application/${application.applicationId}/additionalinfo`} />;
    }

    return (
      <Grid container spacing={3}>
        <Grid item md={4} sm={4} xs>
          <FinancingConditions />
        </Grid>
        <Grid item md={8} sm={8} xs>
          {financingStore.error && !isBej && (
            <Grid container justifyContent="center" style={{ padding: 15 }}>
              <ErrorText>{financingStore.error.text}</ErrorText>
              <ErrorText>{financingStore.error.value}</ErrorText>
            </Grid>
          )}
          <ApplicantDetails financingStore={financingStore} />

          <Grid item md={12}>
            {showKontoBox && <AccountDetails financingStore={financingStore} />}
            <Box mt={4} />
            <ApplicationDetails isSigned={isSigned} financingStore={financingStore} />
          </Grid>

          <Grid container>
            <Grid item md={12}>
              <DecisionText financingStore={financingStore} isSigned={isSigned} />

              {isBej && (
                <Typography>
                  Tillfälligt fel, försök senare att återuppta ansökan eller kontakta banken
                </Typography>
              )}

              {isDenied && (
                <Grid item md={12}>
                  <Typography>
                    Ansökan kan inte beviljas. Vi har, utifrån informationen kunden lämnat i sin ansökan
                    tillsammans med information vi fått från Upplysningscentralen, gjort en helhetsbedömning
                    och måste meddela att vi inte kan bevilja kunden kredit.
                  </Typography>
                </Grid>
              )}

              {isApproved &&
                !financingStore.hasSubmittedPurchase &&
                (isSigned ? (
                  <ApplicationSigned
                    title={signedTitle}
                    financingStore={financingStore}
                    futurePayment={futurePayment}
                  />
                ) : (
                  <ApplicationApproved financingStore={financingStore} />
                ))}

              {financingStore.hasSubmittedPurchase && (
                <>
                  <PurchaseSubmitted title={signedTitle} financingStore={financingStore} />
                  <Grid container style={{ marginTop: '20px' }}>
                    <Grid item md={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={financingStore.cancelApplication}
                      >
                        {isNets ? 'Avsluta' : 'STÄNG'}
                      </Button>
                    </Grid>
                    {isNets && !financingStore.hasPostponedPayment && (
                      <Grid item md={3}>
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={financingStore.resendSmsWithPurchaseCode}
                          disabled={financingStore.resendPurchaseSmsOptions.isLoading}
                        >
                          Skicka nytt SMS
                        </Button>
                        {financingStore.resendPurchaseSmsOptions.isLoading && <LinearProgress />}
                        <ErrorText>{financingStore.resendPurchaseSmsOptions.error}</ErrorText>
                      </Grid>
                    )}
                  </Grid>
                </>
              )}

              {isPending && <ApplicationPending financingStore={financingStore} />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(observer(ApplicationView));
